import dayjs from 'dayjs';
import _, { update } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import utils, {  getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import useQuery from 'lib/hooks/useQuery';
import { actions as dealsActions } from 'redux/deals';
import { actions as storageActions } from 'redux/storage';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';
import CommodityField from 'common/TableComponents/commodityField';
import DeliveryDateField from 'common/TableComponents/deliveryDateField';
import PriceField from 'common/TableComponents/priceField';
import QuantityField from 'common/TableComponents/quantityField';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import FilterUtils from 'lib/filterUtils';
import AntPagination from 'common/AntTablePagination';
import ShieldIcon from 'common/Shield/shield';
import { Dropdown, Menu, Table, Tag, Tooltip, Skeleton, Modal } from 'antd';
import { Popover } from 'antd';
import OverviewHeader from 'common/OverviewHeader';
import AntTableActions from 'common/TableActions';
import DialogPopup from 'common/DialogPopUp';
import ProgressBar from 'common/SupplierProgressBar';
import { Switch } from 'antd';
import { actions as memberActions } from 'redux/members';
import { COMMODITY_TYPE, COMMODITY_FILTER } from 'constants/pageParams';

// icons
import verifiedHeaderIcon from 'assets/images/icons/verifiedHeader.svg';
import SecuredTransport from 'assets/images/icons/secureTransport.svg';
import BfcTransportationIcon from 'assets/images/icons/bfcTransportation.svg';
import BfcSeller from 'assets/images/icons/bfcSellerTransportation.svg'
import BfcBuyer from 'assets/images/icons/bfcBuyerTransportation.svg'
import BioFuelCircleLogo from 'assets/BiofuelCircleLogo.png';

import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import OverviewNav from 'common/OverviewNav';
import { Button, ButtonGroup } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PickupIcon from 'common/Icons/PickupIcon';
import PickupBuyIcon from 'common/Icons/PickupBuyIcon';
import WalkInIcon from 'common/Icons/WalkInIcon';
import DeliveryTruckIcon2 from 'common/Icons/DeliveryTruckIcon2';
import OfferIcon from 'assets/images/icons/Offer.svg'
import { ReactComponent as AllowEarlyPaymentIcon } from 'assets/images/icons/allow-early-payment.svg'
import { ReactComponent as EarlyPaymentAllowedIcon } from 'assets/images/icons/early-payment-allowed.svg'
import EarlyPaymentAvailedUsingCTIcon from 'assets/images/icons/early-payment-availed-using-ct.svg'
import OfferAvailedIcon from 'assets/images/icons/Offer-availed.svg'
import Tour from 'reactour'
import confirmationPopupImg from 'assets/early-payment.png'
import ConvertToBFCTransportImg from 'assets/conver_to_BFC_transport.png'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { ReactComponent as DeliveryTruck } from 'assets/images/icons/delivery-truck-full.svg'
import MUITextField from 'common/MUIComponents/TextField';
import MUIButton from 'common/MUIComponents/Button';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MUIModal from 'common/MUIComponents/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import notifications from 'lib/notifications';
import InfoIcon from '@material-ui/icons/Info';
import {ReactComponent as PickUpWithAscIcon} from 'assets/images/icons/PickUp-with-ASC.svg'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { handleShareQualityReport } from '../../handlers'
import { decryptionInHex, getDecryptedRouteParams } from 'lib/utils';

// scss
import './styles.scss';
import 'antd/dist/antd.css';

function DealModal({ commodityDetails, visible, onCancel, row, DealId, role }) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);
  const tableRef = useRef();
  const [resetDealDate, setResetDealDate] = useState(false)

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const modalHeight = windowHeight * 0.6;
  const modalWidth = windowWidth * 0.6;

  let {
    // page,
    deal_master_id,
    commodity_form_id,
    commodity_id,
    load_location_id,
    base_location_code,
    transporter_id,
    delivery_location_code,
    deal_status_enum,
    created_datetime,
    tick_color_enum_location,
    is_walk_in,
    consignee_id,
    consignor_id,
    is_secured,
    commodity_standard_enum,
    back,
    isStockTransfer,
    custom_deal_no,
    stock_transfer_status_enum,
    isControlTower,
    indent,
    indent_id,
    type,
    isSmartBuy,
    seller_mobile_number
  } = useQuery();

  const { entities } = useSelector((state) => state.notificationsTopbar);
  const userInfo = useSelector((state) => state.user);
  const roles = useSelector((state) => state.rolesAndRights);
  const deals = useSelector((state) => state.deals, shallowEqual);
  const storageState = useSelector((state) => state.userStorage);
  const isCTUser = _.get(userInfo, 'profile.person.is_control_tower_user') || false;
  const [filters, setFilters] = useState(Boolean(isStockTransfer) ? { deal_type_enum: "2" } : {});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dealRejectionRemark, setDealRejectionRemark] = useState('');
  const [dealRejectedByOptions, setDealRejectedByOptions] = useState([]);
  const [dealData, setDealData] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [isReloadRequired, setIsReloadRequired] = useState(false);
  const [memberSwitch, setMemberSwitch] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [earlyPayment, setEarlyPayment] = useState(false);
  const [allowEarlyPayment, setAllowEarlyPayment] = useState(false);
  const [earlyPaymentDiscount, setEarlyPaymentDiscount] = useState(0)
  const [SelectedDeal, setSelectedDeal] = useState(false)
  const [dealMasterId, setDealMasterId] = useState(0)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [isGreenIconAvailable, setIsGreenIconAvailable] = useState(false)
  const [isTransportConvertIconAvailable, setIsTransportConvertIconAvailable] = useState(false)
  const [selectedDealForTransportConversion, setSelectedDealForTransportConversion] = useState(false)
  const [isConvertOwnToBFCTransport, setIsConvertOwnToBFCTransport] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [newSelectedRowKeys, newSetSelectedRowKeys] = useState([]);
  const [newSelectedRow, newSetSelectedRow] = useState(false);
  const [dealToAttachQty, setDealToAttachQty] = useState(0);
  const [attachDealErrorData, setAttachDealErrorData] = useState(false);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);
  const [page, setPage] = useState(1);
  // const [modalRowsPerPage, setModalRowsPerPage] = useState(10);
  const [modalFilters, setModalFilters] = useState({});
  const [newfilteredParams, setNewfilteredParams] = useState({});

  let loc = getDecryptedUrl(window.location.href);
  const isFPO = _.get(userInfo, 'profile.person.is_fpo')
  const isFarmer = _.get(userInfo, 'profile.person.type_of_individual_enum') === 1;
  const groupState = useSelector((state) => state?.members);
  const groups = groupState?.groups;

  const isCtCommercialOrCtSuperUser = userInfo?.profile?.person_role.some(role => role.role_id === 20 || role.role_id === 23)

  const busy = deals.busy || storageState.busy;
  const storageTransfers = storageState?.storageTransfers;
  let tableData = Boolean(isStockTransfer) ? storageTransfers : Object.values(deals.data || {});
  const encodeBackUrl = utils.encodeBackUrl();
  const acl = utils.getACL(user);

  const rolesList = roles.entities || {};
  let MarketLinksRecord = Object.values(rolesList).find((x) => x.module_name === 'Market');
  const MarketLinks = _.get(MarketLinksRecord, `full_access`);
  tableData = tableData.map((commodityDeal) => {
    let userType = null;
    let company = null;
    let ratings = 0;
    let detailsPage = '';

    if (userInfo.person_id !== commodityDeal.seller_person_id) {
      userType = 'SELLER';
      company = commodityDeal.seller_person_code;
      ratings = commodityDeal.seller_rating;
    }

    if (userInfo.person_id !== commodityDeal.buyer_person_id) {
      userType = 'BUYER';
      company = commodityDeal.buyer_person_code;
      ratings = commodityDeal.buyer_rating;
    }

    if (loc.includes('control-tower/deals')) {
      detailsPage = `/control-tower/deals/${commodityDeal.commodity_deal_master_id}/overview?back=${encodeBackUrl}`;
    } else {
      if (Boolean(isStockTransfer)) {
        detailsPage = `/commodity/deals/${commodityDeal.commodity_deal_master_id}/overview?isStockTransfer=true&${Boolean(isControlTower) ? 'isControlTower=true&' : ''}back=${encodeBackUrl}`;
      } else {
        detailsPage = `/commodity/deals/${commodityDeal.commodity_deal_master_id}/overview?back=${encodeBackUrl}`;
      }
    }

    return {
      id: parseInt(commodityDeal['commodity_deal_master_id']),
      ...commodityDeal,
      detailsPageLink: getUrlWithEncryptedQueryParams(detailsPage),
      ratings,
      company,
      userType,
    };
  });

  useEffect(() => {
    const params = {
      social_group_invitations_status_enum: 'NA',
      offset: 0,
      page_size: 10,
    }
    dispatch(memberActions.fetchGroupsAgainstInvokingPersonId(params));
  }, [memberSwitch])

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    if (Boolean(isControlTower)) {
      params = {
        ...params,
        isControlTower: true
      }
    }
    if(Boolean(indent)) {
      params = {
        ...params,
        indent: true,
        indent_id: indent_id,
        type: type,
        back,
        isSmartBuy
      };
    }
    switch (dataIndex) {
      case 'commodity_form_code':
        delete params['commodity_form_id'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('commodity_form_id'));
        break;
      case 'commodity_code':
        delete params['commodity_id'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('commodity_id'));
        break;
      case 'buyer_company_code_deal':
        delete params['consignee_id'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('consignee_id'));
        break;
      case 'seller_company_code_deal':
        delete params['consignor_id'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('consignor_id'));
        break;
      case 'deal_loading_location':
      case 'from_address':
        delete params['base_location_code'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('base_location_code'));
        break;
      case 'deal_delivery_location':
      case 'to_address':
        delete params['delivery_location_code'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('delivery_location_code'));
        break;
      case 'deal_status_enum_code':
        delete params['deal_status_enum'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('deal_status_enum'));
        break;
      case 'transporter_against_own_deals':
        delete params['transporter_id'];
        delete params['transporter_person_company_affiliate_enum'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('transporter_id'));
        // history.push(FilterUtils.removeUrl('transporter_person_company_affiliate_enum'));
        break;
      case 'deal_date':
        setResetDealDate(true)
        delete params['created_datetime'];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl('created_datetime'));
        break;
      case 'commodity_standard_enum_code':
        delete params['commodity_standard_enum'];
        // history.push(FilterUtils.removeUrl('commodity_standard_enum'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        setNewfilteredParams(params);
        // history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm, transportPersonCompanyAffEnum) => {
    confirm();
    let params;
    if (Boolean(isStockTransfer)) {
      params = {
        ...filters,
        isStockTransfer: true,
        deal_type_enum: "2",
        back: back,
      };
      if (Boolean(isControlTower)) {
        params = {
          ...params,
          isControlTower: true
        }
      }
    } else if(Boolean(indent)) {
      params = {
        ...filters,
        indent: true,
        indent_id: indent_id,
        type: type,
        back: back,
        isSmartBuy
      };
    } else {
      params = {
        ...filters,
        back: back,
      };
    }
    switch (dataIndex) {
      case 'commodity_form_code':
        params = {
          ...params,
          commodity_form_id: e,
        };
        break;
      case 'commodity_code':
        params = {
          ...params,
          commodity_id: e,
        };
        break;
      case 'buyer_company_code_deal':
      case 'consignee_id':
        params = {
          ...params,
          consignee_id: e,
        };
        break;
      case 'seller_company_code_deal':
      case 'consignor_id':
        params = {
          ...params,
          consignor_id: e,
        };
        break;
      case 'deal_loading_location':
      case 'from_address':
        params = {
          ...params,
          base_location_code: e,
        };
        break;
      case 'deal_delivery_location':
      case 'to_address':
        params = {
          ...params,
          delivery_location_code: e,
        };
        break;
      case 'deal_status_enum_code':
        params = {
          ...params,
          deal_status_enum: e,
        };
        break;
      case 'transporter_against_own_deals':
        params = {
          ...params,
          transporter_id: e,
          transporter_person_company_affiliate_enum: transportPersonCompanyAffEnum && transportPersonCompanyAffEnum,
        };
        break;
      case 'deal_date':
        setResetDealDate(false)
        params = {
          ...params,
          created_datetime: e,
        };
        break;
      case 'commodity_standard_enum_code':
        params = {
          ...params,
          commodity_standard_enum: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    setNewfilteredParams(params);
    // history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const handleOkClick = async () => {
    try {
      const params = {
        commodity_Deal_Master_Id: newSelectedRowKeys,
        quality_Record_Master_Id: DealId,
      };
      const responseData = await handleShareQualityReport(params);
      onCancel();
    } catch (error) {
      console.error('Error:' , error);
    }
  };

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      // history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  const handleModalPageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...modalFilters,
        page: updatePage ? updatePage.current : 1,
      };

      setPage(updatePage.current);
      setRowsPerPage(updatePage.pageSize);
    }
  };

  if (page === undefined) {
    setPage(1);
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const clearFilters = () => {
    setFilters({});
    // let pathname = window.location.pathname;
    // if (Boolean(isStockTransfer)) {
    //   history.push(`/commodity/deals?page=1&isStockTransfer=true${Boolean(isControlTower) ? '&isControlTower=true' : ''}`);
    // } else if(Boolean(indent)) {
    //   history.push(`/control-tower/deals?consignee_id=${userInfo?.profile?.person?.company_id}&deal_status_enum=1&indent=true&indent_id=${indent_id}&type=${type}&back=${back}`)
    // } else {
    //   history.push(pathname + '?' + `page=1${Boolean(isControlTower) ? '&isControlTower=true' : ''}`);
    // }
  };

  const clearModalFilters = () => {
    clearFilters();
    setFilters({});
    setNewfilteredParams({});
  };

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  // const verifiedContent = <p style={{ lineHeight: '10px', color: 'black' }}>Verified</p>;
  const standardToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV upto 3400</p>
      <p className="p-0 m-0">Ash ~ 12% or more</p>
      <p className="p-0 m-0">Moist. ~ 12% or more</p>
    </div>
  );

  const premiumToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV 3800 or more</p>
      <p className="p-0 m-0">Ash ~ 8% or less </p>
      <p className="p-0 m-0">Moist. ~ 8% or less</p>
    </div>
  );

  const superiorToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV 3400 - 3800</p>
      <p className="p-0 m-0">Ash ~ 8% - 12%</p>
      <p className="p-0 m-0">Moist. ~ 8% - 12%</p>
    </div>
  );

  const warningToolTipCode = (
    <div>
      <p>Some quality specs missing or inconsistent.</p>
    </div>
  );

  const handelApplyEarlyPayment = async () => {
    const success = await dispatch(dealsActions.applyEarlyPaymentOffer(SelectedDeal?.is_early_payment_enable_on_deal ? { commodity_deal_master_id: dealMasterId, turn_off_early_payment: true } : { commodity_deal_master_id: dealMasterId }));
    success && setIsReloadRequired(!isReloadRequired)
    if (success) {
      setEarlyPayment(false)
    }
  }

  const handelAllowEarlyPayment = async () => {
    const success = await dispatch(dealsActions.allowEarlyPaymentCT({ deal_delivery_id: dealMasterId, deal_delivery_enum: 1 }));
    success && setIsReloadRequired(!isReloadRequired)
    if (success) {
      setAllowEarlyPayment(false)
    }
  }

  const handelConvertToBFCTransport = async () => {
    const params = {
      ids: [selectedDealForTransportConversion?.isowntobiofuel_deal_id],
      deal_Delivery_Enum: 1 //for deal = 1 and delivery = 2
    }
    const success = await dispatch(dealsActions.convertToBioFuelCircleTransport(params));
    success && setIsReloadRequired(!isReloadRequired)
    if (success) {
      setIsConvertOwnToBFCTransport(false)
    }
  }

  const onSelectChange = (selectedRowKeys, row) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRow(row);
    setDealToAttachQty(row?.[0]?.total_qty)
  };

  const newOnselectChange = (newSelectedRowKeys, newRow) =>{
    newSetSelectedRowKeys(newSelectedRowKeys);
    newSetSelectedRow(newRow);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'radio',
    getCheckboxProps: (record) => {
      let status = true;
      if (record.deal_status_enum_code === 'Accepted') {
        status = false;
      }
      return {
        disabled: status,
      };
    },
  };

  const findFilterValue = (key) => {
    return filters[key] !== undefined ? filters[key] : null;
  };

  const newRowSelection = {
    newSelectedRowKeys,
    onChange: newOnselectChange,
    type: 'checkbox',
  };

  const COMMODITY_DEAL_TABLE_HEADERS = [
    {
      title: Boolean(isStockTransfer) ? 'Transfer No.' : 'Deal No.',
      dataIndex: 'commodity_deal_master_id',
      width: Boolean(isStockTransfer) ? '150px' : '150px',
      align: 'center',
      fixed: true,
      render: (text, row) => (
        <div className="bfc-table-list-components d-flex justify-content-between align-items-center">
          {Boolean(isStockTransfer) && row?.stock_transfer_status_enum_code === 'Confirmed' ? <Link to={row.detailsPageLink} style={{ color: 'var(--unnamed-color-51c4c3)' }}>
            #{row.custom_deal_no}
          </Link> : Boolean(isStockTransfer) && row?.stock_transfer_status_enum_code !== 'Confirmed' ? `#${row.custom_deal_no}` : <Link to={row.detailsPageLink} style={{ color: 'var(--unnamed-color-51c4c3)' }}>
            #{row.commodity_deal_master_id}
          </Link>}
          <div className='d-flex justify-content-evenly'>
          {row?.is_created_from_existing_deal && loc.includes(`/commodity/${row?.buy_sell_initial_enum === 2 ? 'offers' : 'requirements'}/${id}/deals`) ? <Popover placement="top" trigger="hover" content={<span>Created from existing deal</span>}><InfoIcon style={{height: 24, width: 24, color: 'var(--unnamed-color-1b62ab)'}}/></Popover> : ''}
          {row?.is_walk_in && row?.is_pick_up && <Tooltip title="Pickup Deal" ><PickupBuyIcon className="ml-1" style={{ height: '24px', width: '24px' }} /></Tooltip>}
          {row?.is_walk_in && !row?.is_pick_up && <Tooltip title="Walk In Deal" ><WalkInIcon className="ml-1" style={{ height: '24px', width: '24px' }} /></Tooltip>}
          {row?.special_buy_sell_type_enum == 6 ? <Popover content={`Pickup with Aggregation Service Included (#${row?.commodity_req_offer_id})`}>
            <PickUpWithAscIcon className="ml-1" style={{width: 28, height: 28}} />
          </Popover> : ''}
          {/* {!loc.includes('control-tower') && row?.early_payment_discount && <Popover placement="top" content={<span>{row?.early_payment_metadata_id ? 'You have availed early payment on this deal' : 'Get Payment On Delivery at just'} {row?.early_payment_metadata_id ? '' : <span style={{color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold'}}>₹{row?.early_payment_discount}/MT</span>}</span>} trigger="hover">
            <img src={row?.early_payment_metadata_id ? OfferAvailedIcon : OfferIcon} className={row?.early_payment_metadata_id ? 'ml-2' : "pulse-animation ml-2"} style={{ width: '24px', height: '24px' }} />
          </Popover>} */}
          {(row?.early_payment_discount && !row?.early_payment_metadata_id && row.is_seller) ?
            <Popover
              placement="top"
              content={<span>Get Payment On Delivery at just <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span>}
              trigger="hover">
              <img
                src={OfferIcon}
                className={"pulse-animation ml-1 cursor-pointer green-offer-icon"}
                style={{ width: '24px', height: '24px' }}
                onClick={() => {
                  setEarlyPayment(true)
                  setSelectedDeal(row)
                  setEarlyPaymentDiscount(row?.early_payment_discount)
                  setDealMasterId && setDealMasterId(row?.commodity_deal_master_id)
                }}
              />
            </Popover> : ''}
          {(row?.early_payment_metadata_id && !row?.is_ep_activated_by_ct) ?
            <Popover
              placement="top"
              content={<span>{`${row?.is_seller ? 'You have' : 'Seller has'} availed early payment on this deal.`} <br /><span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'normal', fontSize: 'var(--fs-base__three)' }}>Pay On Delivery* at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span></span>}
              trigger="hover">
              <img
                src={OfferAvailedIcon}
                className={"ml-1 cursor-pointer yellow-offer-icon"}
                style={{ width: '24px', height: '24px' }}
                onClick={() => {
                  setEarlyPayment(true)
                  setSelectedDeal(row)
                  setEarlyPaymentDiscount(row?.early_payment_discount)
                  setDealMasterId && setDealMasterId(row?.commodity_deal_master_id)
                }} />
            </Popover> : ''}
          {loc.includes('control-tower/deals') && isCTUser && row?.is_biofuelcircle_buyer && !row?.early_payment_metadata_id && !row?.is_ep_activated_by_ct ?
            <Popover
              placement="top"
              // content={<span>Allow early payment to be availed for this deal.</span>}
              content={<span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'normal', fontSize: 15 }}>Enable early payment at <span style={{ color: '#32a836', fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span>}
              trigger="hover"
            >
              <AllowEarlyPaymentIcon onClick={() => { setAllowEarlyPayment(true); setDealMasterId && setDealMasterId(row?.commodity_deal_master_id) }} className='ml-1 cursor-pointer gray-offer-icon' style={{ width: '24px', height: '24px' }} />
            </Popover>
            : ''}
          {loc.includes('control-tower/deals') && isCTUser && row?.is_biofuelcircle_buyer && !row?.early_payment_metadata_id && row?.is_ep_activated_by_ct ?
            <Popover
              placement="top"
              content={<span>Enabled early payment at <span style={{ color: '#32a836', fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span>}
              trigger="hover"
            >
              <EarlyPaymentAllowedIcon className='ml-1 cursor-pointer' style={{ width: '24px', height: '24px' }} />
            </Popover>
            : ''}
          {loc.includes('control-tower/deals') && isCTUser && row?.is_biofuelcircle_buyer && row?.is_ep_activated_by_ct && row?.early_payment_metadata_id ?
            <Popover
              placement="top"
              content={<span>{`Seller has availed early payment on this deal with the help of BiofuelCircle`} <br /><span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'normal', fontSize: 'var(--fs-base__three)' }}>Pay On Delivery* at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span></span>}
              trigger="hover"
            >
              <img src={EarlyPaymentAvailedUsingCTIcon} className='ml-1 cursor-pointer' style={{ width: '24px', height: '24px' }} />
            </Popover>
            : ''}
          </div>
        </div>
      ),
      sorter: (a, b) => a.commodity_deal_master_id - b.commodity_deal_master_id,
      filterDropdown: (props) => (
        Boolean(isStockTransfer) ? <TextSearchFilter
          dataIndex={'custom_deal_no'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={custom_deal_no}
          name={'Deal'}
          isDealDealiveryMultiselectFilter={true}
        /> : <NumericSearchFilter
          dataIndex={'deal_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={filters['deal_master_id']}
          value={findFilterValue('deal_master_id')}
          name={'Deal'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => Boolean(isStockTransfer) ? getSearchIcon(filtered, 'custom_deal_no') : getSearchIcon(filtered, 'deal_master_id'),
      filteredValue: Boolean(isStockTransfer) ? deal_master_id !== undefined ? custom_deal_no : null : custom_deal_no !== undefined ? custom_deal_no : null,
    },
    {
      title: Boolean(isStockTransfer) ? 'Consignor' : 'Buyer',
      dataIndex: 'buyer_company_code_deal',
      width: '150px',
      align: 'left',
      sorter: (a, b) =>
        (a.buyer_company_code && a.buyer_company_code.localeCompare(b.buyer_company_code && b.buyer_company_code)) ||
        (a.buyer_person_code && a.buyer_person_code.localeCompare(b.buyer_person_code && b.buyer_person_code)),
        render: (text, row) => (
          <>
            <Popover content={row.buyer_company_code || row.buyer_person_code || ''} placement="topLeft">
              <div className="bfc-table-list-components text-left">
                <p className='multiline-ellipsis text-theme-dark'>{row.buyer_company_code || row.buyer_person_code || ''}</p>
              </div>
            </Popover>
          </>
        ),
      filterDropdown: Boolean(indent) && isSmartBuy === 'true' ? '' : (props) => (
        <AutoCompleteFilter
          dataIndex={Boolean(isStockTransfer) && acl.isCompany ? 'consignee_id' : 'buyer_company_code_deal'}
          isControlTower={Boolean(isControlTower)}
          codeBaisedFilter={Boolean(isStockTransfer) ? true : false}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={consignee_id}
          value={findFilterValue('consignee_id')}
          name={'Buyer'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'consignee_id'),
      filteredValue: consignee_id !== undefined ? consignee_id : null,
    },
    {
      title: Boolean(isStockTransfer) ? 'Consignee' : 'Seller',
      dataIndex: 'seller_company_code_deal',
      width: '160px',
      align: 'left',
      sorter: (a, b) =>
        (a.seller_company_code &&
          a.seller_company_code.localeCompare(b.seller_company_code && b.seller_company_code)) ||
        (a.seller_person_code && a.seller_person_code.localeCompare(b.seller_person_code && b.seller_person_code)),
        render: (text, row) => (
          <div className="bfc-table-list-components text-left">
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <Popover
                  placement="topLeft"
                  content={
                    (row.seller_company_code || row.seller_person_code || '')
                  }>
                  <div>
                    <p className='ellipsis-text text-theme-dark'>
                      {row.seller_company_code || row.seller_person_code || ''}
                    </p>
                  </div>
                  {row?.seller_mobile_number && !loc.includes('commodity/requirements') ? <div>
                    ({row?.seller_mobile_number})
                  </div> : ''}
                </Popover>
              </div>
              {row?.is_offerer_bank_verified ? <Popover content={`Bank details verified`}><CheckCircleIcon className="ml-1" style={{ color: 'var(--unnamed-color-00ae11)' }} /></Popover> : row?.is_offerer_bank_verified === false ? <Popover content={`Bank details not verified`}><CancelIcon className="ml-1" style={{ color: 'var(--unnamed-color-f44336)' }} /></Popover> : ''}
            </div>
          </div>
        ),
      filterDropdown: isSmartBuy === 'false' ? '' : (props) => (
        <AutoCompleteFilter
          dataIndex={Boolean(isStockTransfer) && acl.isCompany ? 'consignor_id' : 'seller_company_code_deal'}
          isControlTower={Boolean(isControlTower)}
          codeBaisedFilter={Boolean(isStockTransfer) ? true : false}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={consignor_id}
          value={findFilterValue('consignor_id')}
          name={'Seller'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'consignor_id'),
      filteredValue: consignor_id !== undefined ? consignor_id : null,
    },
    ...(commodityDetails?.special_buy_sell_type_enum === 6 ? [{
      title: 'Seller Contact',
      dataIndex: 'seller_mobile_number',
      width: '150px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.seller_mobile_number}
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'seller_mobile_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={seller_mobile_number}
          name={'Seller Contact'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'seller_mobile_number'),
      filteredValue: seller_mobile_number !== undefined ? seller_mobile_number : null,
    }] : []),
    // {
    //   title: 'Transporter',
    //   dataIndex: 'transporter_against_own_deals',
    //   width: '150px',
    //   align: 'center',
    //   render: (text, row) => {
    //     return (
    //       <div className="bfc-table-list-components text-center d-flex align-items-center justify-content-center">
    //         <ul style={{
    //           paddingLeft: 0,
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'unset'
    //         }}>
    //           <li style={{ width: 'calc(5vw - 0px)' }}>
    //             {!row.is_own_transport ? (
    //               <Tooltip title="BiofuelCircle">
    //                 <img src={BfcTransportationIcon} style={{ height: 45, width: 50 }} />
    //               </Tooltip>
    //             ) : row?.is_walk_in && !row?.is_pick_up ? (
    //               <Tooltip title="Seller">
    //                 <img src={BfcSeller} style={{ height: 45, width: 50 }} />
    //               </Tooltip>
    //             ) : row?.is_pick_up ? (
    //               <Tooltip title="Buyer">
    //                 <img src={BfcBuyer} style={{ height: 45, width: 50 }} />
    //               </Tooltip>
    //             ) : row?.buy_sell_initial_enum_code === 'Buy' ? (
    //               <Tooltip title="Seller">
    //                 <img src={BfcSeller} style={{ height: 45, width: 50 }} />
    //               </Tooltip>
    //             ) : (
    //               <Tooltip title="Buyer">
    //                 <img src={BfcBuyer} style={{ height: 45, width: 50 }} />
    //               </Tooltip>
    //             )}
    //           </li>
    //           <li style={{ width: 'calc(2vw - 0px)' }}>
    //             {row?.is_transporter_icon_visible && (row?.isowntobiofuel_is_deal_eligible_for_biofuelcircle_transport || row?.isowntobiofuel_is_converted_to_bfc_transport)
    //               ? <Popover
    //                 placement="top"
    //                 content={row?.isowntobiofuel_is_converted_to_bfc_transport ? <span>Converted To BiofuelCircle Transport</span> : <span>Get BiofuelCircle Transport at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.isowntobiofuel_transport_rate}/{row?.transport_uom_code}</span></span>}
    //                 trigger="hover">
    //                 <LocalOfferIcon className={`${row?.isowntobiofuel_is_converted_to_bfc_transport ? '' : 'pulse-animation'} ml-2 cursor-pointer ${row?.isowntobiofuel_is_converted_to_bfc_transport ? `yellow-transport-icon` : `green-transport-icon`}`} color='primary' style={{ color: row?.isowntobiofuel_is_converted_to_bfc_transport ? 'var(--unnamed-color-F1B043)' : 'var(--unnamed-color-90d632)' }} onClick={row?.isowntobiofuel_is_converted_to_bfc_transport ? '' : () => {
    //                   setSelectedDealForTransportConversion(row)
    //                   setIsConvertOwnToBFCTransport(true)
    //                 }} />
    //               </Popover>
    //               : ''
    //             }
    //           </li>
    //         </ul>
    //       </div>
    //     )
    //     // <div className="bfc-table-list-components text-center">
    //     //   {!row.is_own_transport ? (
    //     //     <Tooltip title="BiofuelCircle">
    //     //       <img src={BfcTransportationIcon} style={{ height: 45, width: 50 }} />
    //     //     </Tooltip>
    //     //   ) : row?.is_walk_in && !row?.is_pick_up ? (
    //     //     <Tooltip title="Seller">
    //     //       <img src={BfcSeller} style={{ height: 45, width: 50 }} />
    //     //     </Tooltip>
    //     //   ) : row?.is_pick_up ? (
    //     //     <Tooltip title="Buyer">
    //     //       <img src={BfcBuyer} style={{ height: 45, width: 50 }} />
    //     //     </Tooltip>
    //     //   ) : row?.buy_sell_initial_enum_code === 'Buy' ? (
    //     //     <Tooltip title="Seller">
    //     //       <img src={BfcSeller} style={{ height: 45, width: 50 }} />
    //     //     </Tooltip>
    //     //   ) : (
    //     //     <Tooltip title="Buyer">
    //     //       <img src={BfcBuyer} style={{ height: 45, width: 50 }} />
    //     //     </Tooltip>
    //     //   )}
    //     //   {row?.is_transporter_icon_visible && (row?.isowntobiofuel_is_deal_eligible_for_biofuelcircle_transport || row?.isowntobiofuel_is_converted_to_bfc_transport)
    //     //     ? <Popover
    //     //       placement="top"
    //     //       content={row?.isowntobiofuel_is_converted_to_bfc_transport ? <span>Converted To BiofuelCircle Transport</span> : <span>Get BiofuelCircle Transport at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.isowntobiofuel_transport_rate}/{row?.transport_uom_code}</span></span>}
    //     //       trigger="hover">
    //     //       <LocalOfferIcon className={`${row?.isowntobiofuel_is_converted_to_bfc_transport ? '' : 'pulse-animation'} ml-2 cursor-pointer ${row?.isowntobiofuel_is_converted_to_bfc_transport ? `yellow-transport-icon` : `green-transport-icon`}`} color='primary' style={{ color: row?.isowntobiofuel_is_converted_to_bfc_transport ? 'var(--unnamed-color-F1B043)' : 'var(--unnamed-color-90d632)' }} onClick={row?.isowntobiofuel_is_converted_to_bfc_transport ? '' : () => {
    //     //         setSelectedDealForTransportConversion(row)
    //     //         setIsConvertOwnToBFCTransport(true)
    //     //       }} />
    //     //     </Popover>
    //     //     : ''
    //     //   }
    //     // </div>
    //   },
    //   sorter: Boolean(isStockTransfer) ? false : (a, b) => a.transporter_name && a.transporter_name.localeCompare(b.transporter_name && b.transporter_name),
    //   filterDropdown: Boolean(isStockTransfer) ? false : (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'transporter_against_own_deals'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       // value={transporter_id}
    //       name={'Transporter'}
    //     />
    //   ),
    //   filterIcon: Boolean(isStockTransfer) ? false : (filtered) => getFilteredIcon(filtered, 'transporter_id'),
    //   filteredValue: Boolean(isStockTransfer) ? false : transporter_id !== undefined ? transporter_id : null,
    // },
    {
      title: 'Deal Date',
      key: 'deal_date',
      dataIndex: 'deal_date',
      width: '150px',
      align: 'center',
      sorter: (a, b) => Boolean(isStockTransfer) ? new Date(a.deal_date) - new Date(b.deal_date) : new Date(a.created_datetime) - new Date(b.created_datetime),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">{dayjs(Boolean(isStockTransfer) ? row?.deal_date || row?.date : row.created_datetime).format('DD-MMM-YYYY')}</div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'deal_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={created_datetime}
          value={findFilterValue('created_datetime')}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'created_datetime'),
      filteredValue: created_datetime !== undefined ? created_datetime : null,
    },
    {
      title: 'Form',
      dataIndex: 'commodity_form_code',
      width: '100px',
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.commodity_form_code}</div>,
      sorter: (a, b) => a.commodity_form_code.localeCompare(b.commodity_form_code),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'commodity_form_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={commodity_form_id}
          value={findFilterValue('commodity_form_id')}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_form_id'),
      filteredValue: commodity_form_id !== undefined ? commodity_form_id : null,
    },
    {
      title: 'Commodity',
      dataIndex: 'commodity_code',
      width: '130px',
      align: 'center',
      render: (text, row) => (
        <CommodityField commodity_residue_code={row.commodity_residue_code} commodity_code={row.commodity_code} />
        // <div className="bfc-table-list-components text-left">
        //   <span style={{ color: 'var(--unnamed-color-000)', }}>{row.commodity_code}</span>
        //   <br />
        //   <span style={{ color: 'var(--unnamed-color-707070)', }}>{row.commodity_residue_code}</span>
        // </div>
      ),
      sorter: (a, b) => a.commodity_code.localeCompare(b.commodity_code),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'commodity_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          // value={commodity_id}
          value={findFilterValue('commodity_id')}
          name={'Commodity'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_id'),
      filteredValue: commodity_id !== undefined ? commodity_id : null,
    },
    // {
    //   title: 'Grade',
    //   dataIndex: 'commodity_standard_enum_code',
    //   key: 'commodity_standard_enum_code',
    //   width: '120px',
    //   sorter: (a, b) =>
    //     a.commodity_standard_enum_code &&
    //     a.commodity_standard_enum_code.localeCompare(b.commodity_standard_enum_code && b.commodity_standard_enum_code),
    //   ellipsis: true,
    //   align: 'center',

    //   render: (text, row) => (
    //     <span>
    //       {text == 'Standard' && (
    //         <div className="d-flex align-items-center grouping-tag">
    //           <Tag color={'cyan'} key={text}>
    //             <Popover placement="top" content={standardToolTipCode} trigger="hover">
    //               <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //             </Popover>
    //           </Tag>
    //           {row.commodity_standard_enum !== 0 && row.is_show_warning_on_commoditygroup == 1 && (
    //             <div className="warning-text">
    //               <Popover placement="top" content={warningToolTipCode} trigger="hover">
    //                 <WarningFilled style={{ color: 'red' }} />
    //               </Popover>
    //             </div>
    //           )}
    //         </div>
    //       )}
    //       {text == 'Premium' && (
    //         <div className="d-flex align-items-center grouping-tag">
    //           <Tag color={'gold'} key={text}>
    //             <Popover placement="top" content={premiumToolTipCode} trigger="hover">
    //               <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //             </Popover>
    //           </Tag>
    //           {row.commodity_standard_enum !== 0 && row.is_show_warning_on_commoditygroup == 1 && (
    //             <div className="warning-text">
    //               <Popover placement="top" content={warningToolTipCode} trigger="hover">
    //                 <WarningFilled style={{ color: 'red' }} />
    //               </Popover>
    //             </div>
    //           )}
    //         </div>
    //       )}

    //       {text == 'Superior' && (
    //         <div className="d-flex align-items-center grouping-tag">
    //           <Tag color={'geekblue'} key={text}>
    //             <Popover placement="top" content={superiorToolTipCode} trigger="hover">
    //               <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //             </Popover>
    //           </Tag>
    //           {row.commodity_standard_enum !== 0 && row.is_show_warning_on_commoditygroup == 1 && (
    //             <div className="warning-text">
    //               <Popover placement="top" content={warningToolTipCode} trigger="hover">
    //                 <WarningFilled style={{ color: 'red' }} />
    //               </Popover>
    //             </div>
    //           )}
    //         </div>
    //       )}
    //       {text == 'Unclassified' && (
    //         <div className='d-flex align-items-center grouping-tag'>
    //           <Tag color={'magenta'} key={text}>
    //             <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //           </Tag>
    //         </div>
    //       )}
    //     </span>
    //   ),
    //   filterDropdown: (props) => (
    //     <MultiSelectFilter
    //       dataIndex={'commodity_standard_enum_code'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={commodity_standard_enum}
    //       name={'Grade'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_standard_enum'),
    //   filteredValue: commodity_standard_enum !== undefined ? commodity_standard_enum : null,
    // },
    // {
    //   title: 'Quantity',
    //   dataIndex: 'qty',
    //   width: '90px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <QuantityField
    //       qty={row.qty}
    //       periodicity_enum_code={row.periodicity_enum_code}
    //       qty_uom_code={row.qty_uom_code}
    //     />
    //   ),
    // },
    // {
    //   title: 'Landed Cost',
    //   dataIndex: 'qty',
    //   width: '120px',
    //   align: 'center',
    //   render: (text, row) => {
    //     // let price = row.trade_price || 0;
    //     // let transportationCost = row.transportation_cost || 0;
    //     // let platformCharges = row.platform_charges || 0;
    //     // let loading_charge_value = row.loading_charge_value || 0;
    //     // let unloading_charge_value = row.unloading_charge_value || 0;

    //     // let landedCost =
    //     //   parseFloat(price) +
    //     //   parseFloat(transportationCost) +
    //     //   parseFloat(platformCharges)
    //     //   parseFloat(loading_charge_value) +
    //     //   parseFloat(unloading_charge_value);

    //     // if (transportationCost == 0) {
    //     //   landedCost = parseFloat(price) + parseFloat(platformCharges);
    //     // }
    //     return <PriceField expected_price={row.landed_cost} qty_uom_code={row.price_uom_code} />;
    //   },
    // },
    // {
    //   title: 'Delivery Dates',
    //   dataIndex: 'from_date',
    //   width: '130px',
    //   align: 'center',
    //   render: (text, row) => <DeliveryDateField form_date={row.from_date} to_date={row.to_date} />,
    // },
    // ...(!Boolean(isStockTransfer) ? [{
    //   title: (text) => (
    //     <Popover content={verifiedContent}>
    //       <img src={verifiedHeaderIcon} alt="" height={18} />
    //     </Popover>
    //   ),
    //   dataIndex: 'tick_color_enum_location',
    //   width: '120px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <>
    //       <ShieldIcon
    //         tickvalue={
    //           row.tick_color_enum_location == null || row.tick_color_enum_location == 0 ? 4 : row.tick_color_enum_location
    //         }
    //       />
    //       <div className="supplier-rating-table">
    //         {loc.includes('control-tower/deals') ? (
    //           <ProgressBar value={row.extended_profile_location_ratings} id={row?.extended_profile_id} addressId={row?.base_location_id} tickvalue={row.tick_color_enum_location} />
    //         ) : (
    //           <ProgressBar value={row.extended_profile_location_ratings} id={row?.extended_profile_id} addressId={row?.is_seller ? row?.delivery_location_id : row?.base_location_id} tickvalue={row.tick_color_enum_location} />
    //         )}
    //       </div>
    //     </>
    //   ),
    //   // sorter: (a, b) => a.locations.localeCompare(b.locations),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'tick_color_enum_location'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={tick_color_enum_location}
    //       name={'Verified'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'tick_color_enum_location'),
    //   filteredValue: tick_color_enum_location !== undefined ? tick_color_enum_location : null,
    // }] : []),
    // ...(isCTUser && loc.includes('/control-tower')
    //   ? [
    //     {
    //       title: 'Secured',
    //       dataIndex: 'is_secured',
    //       width: '170px',
    //       align: 'center',
    //       render: (text, row) => (
    //         <div className="bfc-table-list-components text-center">
    //           <p>
    //             {row.is_secured ? (
    //               <img src={SecuredTransport} className="" style={{ width: '30px', height: '30px' }} />
    //             ) : null}
    //           </p>
    //         </div>
    //       ),
    //       filterDropdown: (props) => (
    //         <AutoCompleteFilter
    //           dataIndex={'is_secured'}
    //           data={props}
    //           onFilterChange={onFilterChange}
    //           handleReset={handleReset}
    //           value={is_secured}
    //           name={'Verified'}
    //         />
    //       ),
    //       filterIcon: (filtered) => getFilteredIcon(filtered, 'is_secured'),
    //       filteredValue: is_secured !== undefined ? is_secured : null,
    //     },
    //   ]
    //   : []),
    // ...(!Boolean(isStockTransfer) ? [{
    //   title: 'Walk-in?',
    //   dataIndex: 'is_walk_in',
    //   width: '130px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-center">
    //       <p>{row.is_walk_in ? 'Yes' : 'No'}</p>
    //     </div>
    //   ),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'is_walk_in'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={is_walk_in}
    //       name={'walk in'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'is_walk_in'),
    //   defaultFilteredValue: is_walk_in !== undefined ? is_walk_in : null,
    // }] : []),
    // {
    //   title: 'Loading',
    //   dataIndex: Boolean(isStockTransfer) ? 'from_address' : 'deal_loading_location',
    //   width: '150px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-center">
    //       <p>{row.base_location_code}</p>
    //     </div>
    //   ),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={Boolean(isStockTransfer) ? 'from_address' : 'deal_loading_location'}
    //       isFarmer={acl.isCompany ? false : true}
    //       isControlTower={Boolean(isControlTower)}
    //       codeBaisedFilter={Boolean(isStockTransfer) ? true : false}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={base_location_code}
    //       name={'Loading Location'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'base_location_code'),
    //   defaultFilteredValue: base_location_code !== undefined ? base_location_code : null,
    // },
    // {
    //   title: 'Delivery',
    //   dataIndex: Boolean(isStockTransfer) ? 'to_address' : 'deal_delivery_location',
    //   width: '150px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-center">
    //       <p>{row.delivery_location_code}</p>
    //     </div>
    //   ),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={Boolean(isStockTransfer) ? 'to_address' : 'deal_delivery_location'}
    //       isFarmer={acl.isCompany ? false : true}
    //       isControlTower={Boolean(isControlTower)}
    //       codeBaisedFilter={Boolean(isStockTransfer) ? true : false}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={delivery_location_code}
    //       name={'Delivery'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'delivery_location_code'),
    //   defaultFilteredValue: delivery_location_code !== undefined ? delivery_location_code : null,
    // },
    // {
    //   title: 'Status',
    //   dataIndex: Boolean(isStockTransfer) ? 'stock_transfer_status_enum_code' : 'deal_status_enum_code',
    //   width: '100px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <span>
    //       {(text == 'Rejected' || text == 'Deleted') && (
    //         <Tag color={'red'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Completed' && (
    //         <Tag color={'blue'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {(text == 'Accepted' || text == 'Confirmed') && (
    //         <Tag color={'green'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {(text == 'Pending' || text == 'Draft') && (
    //         <Tag color={'purple'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Finalized' && (
    //         <Tag color={'orange'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Reviewed' && (
    //         <Tag color={'cyan'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Buyer to Confirm' && (
    //         <Tag color={'magenta'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Seller to Confirm' && (
    //         <Tag color={'magenta'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Buyer and Seller to Confirm' && (
    //         <Tag color={'geekblue'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Seller to Confirm Subscription' && (
    //         <Tag color={'magenta'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Buyer to Confirm Subscription' && (
    //         <Tag color={'magenta'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Rejected*' && (
    //         <Popover placement="rightTop" content={rejectedContent}>
    //           <Tag color={'volcano'} key={text}>
    //             <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //           </Tag>
    //         </Popover>
    //       )}
    //       {text == 'Buyer and Seller to Confirm Subscription' && (
    //         <Tag color={'geekblue'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //       {text == 'Cancelled' && (
    //         <Popover placement="rightTop" content={cancelledContent}>
    //           <Tag color={'volcano'} key={text}>
    //             <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //           </Tag>
    //         </Popover>
    //       )}
    //       {text == 'Cancelled*' && (
    //         <Tag color={'volcano'} key={text}>
    //           <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
    //         </Tag>
    //       )}
    //     </span>
    //   ),
    //   filterDropdown: Boolean(indent) ? '' : (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={Boolean(isStockTransfer) ? 'stock_transfer_status_enum' : 'deal_status_enum_code'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={deal_status_enum}
    //       name={'Status'}
    //     />
    //   ),
    //   filterIcon: (filtered) => Boolean(isStockTransfer) ? getFilteredIcon(filtered, 'stock_transfer_status_enum') : getFilteredIcon(filtered, 'deal_status_enum'),
    //   filteredValue: Boolean(isStockTransfer) ? stock_transfer_status_enum !== undefined ? stock_transfer_status_enum : null : deal_status_enum !== undefined ? deal_status_enum : null,
    // },
    // ...(isCtCommercialOrCtSuperUser && loc.includes('control-tower/deals') && !Boolean(indent) ? [{
    //   title: 'Actions',
    //   width: '80px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <AntTableActions
    //       showFlag={row?.deal_status_enum_code === 'Accepted'}
    //       controls={[
    //         {
    //           name: 'Reject',
    //           onClick: () => handelRejectClick(row),
    //           controlColor: 'var(--unnamed-color-1890ff)',
    //         },
    //       ]}
    //     />
    //   ),
    // }] : []),
    // ...((Boolean(isStockTransfer) && MarketLinks && !Boolean(isControlTower)) ? [{
    //   title: 'Action',
    //   dataIndex: 'stock_transfer_status_enum_code',
    //   key: 'stock_transfer_status_enum_code',
    //   width: '130px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="d-flex justify-content-center align-items-center">
    //       <AntTableActions
    //         showFlag={true}
    //         row={row}
    //         controls={[
    //           ...(row.stock_transfer_status_enum_code === 'Draft' ? [{
    //             name: 'Edit', onClick: async () => {
    //               const params = {
    //                 filters: { stock_transfer_id: row.stock_transfer_id },
    //                 offset: 0,
    //                 page_size: 10,
    //               };
    //               const success = await dispatch(storageActions.fetchStockTransfers(params));
    //               success && history.push(`/commodity/requirements/edit/${row.stock_transfer_id}?isStockTransfer=true&back=${encodeBackUrl}`)
    //             }, controlColor: 'var(--unnamed-color-1b62ab)'
    //           }] : []),
    //           {
    //             name: 'Copy', onClick: async () => {
    //               const params = {
    //                 filters: { stock_transfer_id: row.stock_transfer_id },
    //                 offset: 0,
    //                 page_size: 10,
    //               };
    //               const success = await dispatch(storageActions.fetchStockTransfers(params));
    //               success && history.push(`/commodity/requirements/edit/${row.stock_transfer_id}?isStockTransfer=true&iscopy=true&back=${encodeBackUrl}`)
    //             }, controlColor: 'var(--unnamed-color-1b62ab)'
    //           },
    //           ...(row.stock_transfer_status_enum_code === 'Draft' ? [{
    //             name: 'Delete', onClick: async () => {
    //               setSelectedRow(row)
    //               setIsOpen(true)
    //             }, controlColor: 'var(--unnamed-color-f44336)'
    //           }] : []),
    //         ]}
    //       />
    //     </div>
    //   ),
    // }] : []),
  ];

  tableData = Boolean(isStockTransfer) ? tableData : _.orderBy(tableData, ['commodity_deal_master_id'], ['desc']);

  const resetRejectionPopupData = () => {
    setDealRejectionRemark('')
    setSelectedUser({})
    setIsOpen(false)
  }

  const handelDealReject = () => {
    const params = {
      commodity_deal_master_id: dealData?.commodity_deal_master_id,
      rejected_by_id: selectedUser?.person_id,
      rejection_reason: dealRejectionRemark,
    };
    if (selectedUser?.person_id || selectedUser?.person_id === 0) {
      const success = dispatch(dealsActions.RejectDeal_CT(params));
      if (success) {
        setIsReloadRequired(!isReloadRequired)
        resetRejectionPopupData()
      }
    } else {
      return utils.displayMessage('Please select rejected by to proceed.', 'error')
    }
  };

  const handelRejectClick = (dealData) => {
    setIsOpen(true);
    setDealData(dealData);
    const rejectedByOptions = [
      {
        company_id: dealData?.buyer_company_id,
        person_id: dealData?.buyer_person_id,
        text: dealData?.buyer_company_code || dealData?.buyer_person_code,
      },
      {
        company_id: dealData?.seller_company_id,
        person_id: dealData?.seller_person_id,
        text: dealData?.seller_company_code || dealData?.seller_person_code,
      },
      {
        company_id: 0,
        person_id: 0,
        text: 'Platform',
      },
    ];
    setDealRejectedByOptions(rejectedByOptions);
  };

  const objectValue = Object.values(entities).filter((item) => item.header_description === 'Deal Accepted');

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      deal_master_id,
      commodity_form_id,
      commodity_id,
      load_location_id,
      base_location_code,
      delivery_location_code,
      deal_status_enum,
      transporter_id,
      created_datetime,
      tick_color_enum_location,
      is_walk_in,
      consignee_id,
      consignor_id,
      is_secured,
      commodity_standard_enum,
      custom_deal_no,
      stock_transfer_status_enum,
      seller_mobile_number
    });
    if (isFPO || isFarmer) {
      filteredParams.visible_only_for_fpo_members = !memberSwitch ? '1' : '0',
        filteredParams.bf_social_group_id = !memberSwitch ? `${groups?.[0]?.bf_social_group_id}` : ''
    }
    if (Boolean(isStockTransfer)) {
      filteredParams.transfer_type_enum = '2',
        filteredParams.is_invoked_by_ct = Boolean(isControlTower) ? '1' : '0'
    }
    // setFilters(filteredParams);
    if (!Boolean(isControlTower)) {
      if (Boolean(isStockTransfer)) {
        delete filteredParams.visible_only_for_fpo_members
        delete filteredParams.bf_social_group_id
        const newParams = {
          filters: filteredParams,
          offset: (parseInt(page) - 1) * rowsPerPage,
          page_Size: rowsPerPage,
        }
        dispatch(storageActions.fetchStockTransfers(newParams));
      } else {
        if(role === "Market"){
          dispatch(dealsActions.FetchAllCommodityDealsAgainstEntityId(newfilteredParams, parseInt(page), rowsPerPage));
        } else if(role === "Network"){
          const params = {
            filters: newfilteredParams,
            offset: !_.isEmpty(newfilteredParams) && !resetDealDate ? 0 : (parseInt(page) - 1) * rowsPerPage,
            page_Size: rowsPerPage,
          };
          dispatch(dealsActions.fetchAllDealsCt(params));
        }
      }
    } else if (Boolean(isControlTower)) {
      const params = {
        filters: filteredParams,
        offset: (parseInt(page) - 1) * rowsPerPage,
        page_Size: rowsPerPage,
      };
      // let newFilters = params['filters'];
      // delete newFilters['back'];
      dispatch(dealsActions.fetchAllDealsCt(params));
    } else {
      delete filteredParams.visible_only_for_fpo_members
      delete filteredParams.bf_social_group_id
      dispatch(
        dealsActions.FetchCommodityDealsAgainstCommodityReqOfferId(id, filteredParams, parseInt(page), rowsPerPage),
      );
    }
  }, [
    page,
    rowsPerPage,
    deal_master_id,
    commodity_form_id,
    commodity_id,
    load_location_id,
    base_location_code,
    transporter_id,
    delivery_location_code,
    deal_status_enum,
    created_datetime,
    tick_color_enum_location,
    is_walk_in,
    consignee_id,
    consignor_id,
    is_secured,
    commodity_standard_enum,
    memberSwitch,
    Boolean(isStockTransfer),
    custom_deal_no,
    stock_transfer_status_enum,
    isReloadRequired,
    objectValue?.length,
    timestamp,
    seller_mobile_number,
    filters,
  ]);

  const rejectedContent = (
    <p style={{ lineHeight: '10px', color: 'black' }}>Counteparty Rejected, not willing to wait 24 hrs</p>
  );
  const verifiedContent = <p style={{ lineHeight: '10px', color: 'black' }}>Verified</p>;
  const cancelledContent = <p style={{ lineHeight: '10px', color: 'black' }}>Deal cancelled as No Subscription</p>;

  let links = [
    { to: `/commodity/deals?page=1&isStockTransfer=true&back=${back}`, title: 'Stock Transfer' },
    { to: `/market/storage/transfers?back=${encodeBackUrl}`, title: 'Quick Transfer' }
  ]

  if (Boolean(isControlTower)) {
    links = [
      { to: `/commodity/deals?page=1&isStockTransfer=true&isControlTower=true&back=${back}`, title: 'Stock Transfer' },
      { to: `/market/storage/transfers?isControlTower=true&back=${encodeBackUrl}`, title: 'Quick Transfer' }
    ]
  }

  const handelStockTransferDelete = async () => {
    const success = await dispatch(storageActions.deleteStockTransferWithTransport({ stock_transfer_id: selectedRow?.stock_transfer_id }))
    if (success) {
      setIsReloadRequired(!isReloadRequired)
      setIsOpen(false)
    }
  }

  const menu = () => (
    <Menu className="" style={{ marginLeft: '-4px', width: '200px' }}>
      <div className="menu-list">
        <Menu.Item>
          <Typography
            onClick={() => {
              // history.push(`/market/storage/create-quick-transfer?back=${encodeBackUrl}`);
            }}
            className="user-list">
            Quick Transfer
          </Typography>
        </Menu.Item>
        <Menu.Item>
          <Typography onClick={() => {
            // history.push(`/commodity/requirements/create?isStockTransfer=true&back=${encodeBackUrl}`)
          }} className="user-list">
            Stock Transfer
          </Typography>
        </Menu.Item>
        <div
          style={{
            clipPath: 'polygon(50% 50%, 0 100%, 100% 100%)',
            position: 'absolute',
            top: '-13px',
            right: 7,
            height: 15,
            width: 15,
            backgroundColor: '#fff',
          }}></div>
      </div>
    </Menu>
  );

  useEffect(() => {
    const isGreenIcon = document?.querySelector('.green-offer-icon') ? true : false;
    const isTransportConvertIcon = document?.querySelector('.green-transport-icon') ? true : false;
    setIsGreenIconAvailable(isGreenIcon)
    setIsTransportConvertIconAvailable(isTransportConvertIcon)
  })

  const steps = tableData?.length ? [
    ...(isTransportConvertIconAvailable ? [
      {
        selector: '.green-transport-icon',
        content: (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-90d632)', height: '100px', width: '100px', margin: 'auto' }} />
            <div style={{ textAlign: 'justify' }}>
              <strong>Change To BiofuelCircle Transport</strong>
              <div>Get affordable BiofuelCircle Transport rates!</div>
              <div>Move your mouse over this <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-90d632)', height: '24px', width: '24px', margin: '0 6px' }} /> to see offers available for Transport. Click <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-90d632)', height: '24px', width: '24px', margin: '0 6px' }} /> to avail the scheme.</div>
            </div>
          </div>
        ),
        style: {
          maxWidth: '280px',
        },
        position: 'right',
        action: (node) => {
          document?.querySelector('.green-transport-icon')?.scrollIntoView();
        },
      },
      {
        selector: '.green-transport-icon',
        content: (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <img
              src={ConvertToBFCTransportImg}
              style={{
                boxShadow: '1px 1px 5px #0000004a',
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 15,
                textAlign: 'justify',
              }}
            />
            <div style={{ textAlign: 'justify' }}>Click <strong >‘OK’</strong> to accept terms & conditions of the scheme. The Offer will be applied to all confirmed deliveries in your deal.</div>
          </div>
        ),
        style: {
          maxWidth: '468px',
        },
        position: 'right',
        action: (node) => {
          document?.querySelector('.green-transport-icon')?.scrollIntoView();
        },
      },
      {
        selector: '.green-transport-icon',
        content: (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-F1B043)', height: '100px', width: '100px', margin: 'auto' }} />
            <div style={{ textAlign: 'justify' }}>Once the transport is converted to the BiofuelCircle transport, it can not be reverted.</div>
          </div>
        ),
        style: {
          maxWidth: '280px',
        },
        position: 'right',
        action: (node) => {
          document?.querySelector('.green-transport-icon')?.scrollIntoView();
        },
      }
    ] : []),
    ...(!document?.querySelector('.yellow-offer-icon') && document?.querySelector('.green-offer-icon') ? [{
      selector: '.green-offer-icon',
      content: (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={OfferIcon}
            style={{ width: '150px', height: '150px' }}
          />
          <div style={{ textAlign: 'justify' }}>
            <p className="font-weight-bold">Early Payment Scheme</p>
            <p className="m-0">Get paid next business day after Delivery.</p>
            <p className="m-0">
              Only for Verified Sellers like you. Move your mouse over this{' '}
              <img src={OfferIcon} style={{ width: '20px', height: '20px' }} /> to see offers available for early
              payment. Click <img src={OfferIcon} style={{ width: '20px', height: '20px' }} /> to avail the
              scheme.
            </p>
          </div>
        </div>
      ),
      action: (node) => {
        document?.querySelector('.green-offer-icon')?.scrollIntoView();
      },
    },
    {
      selector: '.green-offer-icon',
      content: (
        <div className="d-flex flex-column justify-content-center align-items-start">
          <img
            src={confirmationPopupImg}
            style={{
              boxShadow: '1px 1px 5px #0000004a',
              borderRadius: 4,
              marginTop: 20,
              marginBottom: 15,
              textAlign: 'justify',
            }}
          />
          <div style={{ textAlign: 'justify' }}>Click <strong >‘OK’</strong> to accept terms & conditions of the scheme. The Offer will be applied to all upcoming deliveries in your deal.</div>
        </div>
      ),
      style: {
        maxWidth: '468px',
      },
      position: 'right',
      action: (node) => {
        document?.querySelector('.green-offer-icon')?.scrollIntoView();
      },
    }] : []),
    // ...(loc.includes('control-tower/deals') && document?.querySelector('.gray-offer-icon') && isCTUser ? [
    //   {
    //     selector: '.gray-offer-icon',
    //     content: (
    //       <div className="d-flex flex-column justify-content-center align-items-start">
    //         <AllowEarlyPaymentIcon style={{ height: '100px', width: '100px', margin: 'auto' }} />
    //         <div style={{ textAlign: 'justify' }}>Allow sellers to opt <strong>Early Payment Scheme</strong> explicitly irrespective of supplier profile verification.</div>
    //       </div>
    //     ),
    //     style: {
    //       maxWidth: '280px',
    //     },
    //     position: 'right',
    //     action: (node) => {
    //       document?.querySelector('.gray-offer-icon')?.scrollIntoView();
    //     },
    //   }
    // ] : [])
  ] : [];

  // useEffect(() => {
  //   !isCTUser && document?.querySelector('.green-offer-icon') && steps.length ? setIsTourOpen(true) : setIsTourOpen(false)
  // }, [document?.querySelector('.green-offer-icon'), steps.length])

  useEffect(() => {
    (!document?.querySelector('.yellow-offer-icon') || isTransportConvertIconAvailable || document?.querySelector('.gray-offer-icon')) && steps.length && tableData?.length && (!loc.includes('commodity/offers') && !loc.includes('/commodity/requirements/')) ? setIsTourOpen(true) : setIsTourOpen(false)
  }, [isGreenIconAvailable, steps.length, tableData?.length])

  return (
      <Modal
      className="custom-styling"
      title="Deals"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOkClick}
      okButtonProps={{
        disabled: newSelectedRowKeys.length === 0,
        style: { backgroundColor: newSelectedRowKeys.length === 0 ? null : '#1b62ab', border:"none" }
      }}
      height={modalHeight}
      width={modalWidth}
      style={{ top: 5}}
      okText={
        newSelectedRowKeys.length > 0
          ? `Share with ${newSelectedRowKeys.length} Selected Deals`
          : 'Share with Selected Deals'
      }
      cancelText="Cancel"
      >
        <div className="bfc-tab-content deals">
          <div className="bfc-body">
            {busy && (
              <div className="commodity-wrapper">
                <div className="panel__refresh">

                </div>
              </div>
            )}
            {Boolean(isStockTransfer) && <OverviewNav links={links} rightPanContent={
              !Boolean(isControlTower) && MarketLinks && <ButtonGroup dir="ltr" className="dropdown-button-group mr-2 d-flex justify-flex-end">
                <Button
                  // onClick={() => history.push(`/commodity/requirements/create?isStockTransfer=true&back=${encodeBackUrl}`)}
                  className="d-flex justify-content-between align-items-center pl-3 pr-2"
                  color="primary"
                  style={{ padding: '5px 6px 5px 16px', minWidth: '20px', fontWeight: 'normal' }}>
                  <span>Create</span>
                </Button>
                <Dropdown
                  placement="bottomRight"
                  overlayClassName="user-select-dropdown"
                  overlay={menu}
                  trigger={['click']}
                >
                  <Button color="primary" style={{ padding: "0 !important", position: "relative", width: "37px" }} size="xs" className="icon icon--right right-toggle">
                    <ChevronDownIcon
                      style={{ height: 24, width: 24, position: 'absolute', top: 4, right: 9, borderLeft: '1px solid' }}
                      className="icon ml-3 pl-1"
                    />
                  </Button>
                </Dropdown>
              </ButtonGroup>
            } />}
            {/* {(!Boolean(isStockTransfer) && !Boolean(indent) && (loc.includes('/commodity/deals') || loc.includes('/control-tower/deals'))) && (
              <OverviewHeader heading={Boolean(isStockTransfer) ? "Stock Transfers" : "Deal"} setShowShareBtn={setShowShareBtn} showShareBtn={showShareBtn} />
            )} */}
            {Boolean(indent) && !loc.includes(`/commodity/requirements/${id}/deals`) && !loc.includes(`/commodity/offers/${id}/deals`) ?
              <OverviewHeader
                heading={`Link ${type === 'requirements' ? 'Buy' : 'Sell'} Deal`}
                backLink={back}
                rightPanContent={
                  selectedRow?.length ? <div className='d-flex align-items-center'>
                    <div className="mr-2"  style={{width: '230px'}}>
                      <MUITextField fieldType='qty' label='Deal Quantity' uomType='text' EndIcon={selectedRow?.[0]?.qty_uom_code} value={dealToAttachQty} onChange={(value) => setDealToAttachQty(value)} />
                    </div>
                    <MUIButton
                      className='mr-2'
                      type='icon'
                      iconBtnSize='small'
                      Icon={CheckCircleOutlineIcon}
                      onClick={async () => {
                        const params = {
                          indent_buy_id: parseInt(indent_id),
                          deal_id: selectedRow?.[0]?.commodity_deal_master_id,
                          qty: dealToAttachQty
                        }
                        const success = await dispatch(dealsActions.checkDealLinkingApplicability(params))
                        success && setAttachDealErrorData(success)
                      }} />
                    <MUIButton type='icon' iconBtnSize='small' Icon={CancelOutlinedIcon} onClick={() => { setSelectedRow(false); setSelectedRowKeys([]) }} />
                    <MUIModal
                      open={typeof attachDealErrorData === 'boolean' ? attachDealErrorData : Object.keys(attachDealErrorData)?.length || Object.keys(attachDealErrorData)?.length === 0}
                      setOpen={setAttachDealErrorData}
                      title='Attach Deal'
                      type='confirmation'
                      confirmationType='info'
                      confirmationMessage={Object.keys(attachDealErrorData)?.length === 0 ? <span>Deal is exact match of the Smart <span style={{ textTransform: 'capitalize' }}>{type === 'requirements' ? 'Buy' : 'Sell'}</span></span> : <span>One or more of the following deal term do not match the terms of Smart <span style={{ textTransform: 'capitalize' }}>{type === 'requirements' ? 'Buy' : 'Sell'}</span></span>}
                      actionButtons={[
                        {name: 'Cancel', onClick: () => {
                          setAttachDealErrorData(false);
                          setSelectedRow(false)
                          setSelectedRowKeys([]);
                        }},
                        {name: 'Confirm', onClick: async() => {
                          const params = {
                            indent_buy_id: parseInt(indent_id),
                            deal_id: selectedRow?.[0]?.commodity_deal_master_id,
                            qty: dealToAttachQty
                          }
                          const success = await dispatch(dealsActions.attachDealToIndentBuy(params))
                          if (success) {
                            setAttachDealErrorData(false)
                            setSelectedRow(false)
                            setSelectedRowKeys([]);
                            notifications.show({type: 'success', message: 'Deal attached successfully'})
                          }
                        }}]
                      }
                    >
                      {Object.keys(attachDealErrorData)?.length ? Object.keys(attachDealErrorData)?.map(item =>
                        <div className='d-flex align-items-center'>
                          <CancelIcon style={{ height: 18, width: 18, color: 'var(--unnamed-color-d11010)' }} />
                          <span className='ml-2' style={{ textTransform: 'capitalize' }}>{item?.split('_')?.join(' ')}</span>
                        </div>
                      ) : ''}
                      <div className='mt-2'>Please confirm if you wish to proceed.</div>
                    </MUIModal>
                  </div> : ''
                } /> : ''}
            {/* <div className="deals-table"> */}
              <div className="data-grid-box">
                <div className="ant-table-body ant-table">
                  {/* <div style={{ maxWidth: '500px', maxHeight: '300px' }}> */}
                    <Table
                      ref={tableRef}
                      dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                      columns={busy ? COMMODITY_DEAL_TABLE_HEADERS.map((column) => {
                          return {
                            ...column,
                            render: function renderPlaceholder() {
                              return (
                                <Skeleton
                                  active="true"
                                  key={column.dataIndex}
                                  title={true}
                                  paragraph={false}
                                />
                              );
                            },
                          };
                      }) : COMMODITY_DEAL_TABLE_HEADERS}
                      pagination={false}
                      onChange={handleModalPageChange}
                      scroll={{ y: 350 }}
                      style={{minHeight:390}}
                      className="ant-table"
                      size="small"
                      rowKey={(record) => record.id}
                      rowSelection={newRowSelection}
                    />
                    {/* <AntPagination
                      total={parseInt(totalRecords)}
                      handlePageChange={handlePageChange}
                      page={page}
                      clearFilters={clearFilters}
                      rowsPerPage={rowsPerPage}
                      setScrollToFirstRow={setScrollToFirstRow}
                    /> */}
                    <AntPagination
                      total={parseInt(totalRecords)}
                      handlePageChange={handleModalPageChange}
                      page={page}
                      clearFilters={clearModalFilters}
                      rowsPerPage={rowsPerPage}
                      setScrollToFirstRow={setScrollToFirstRow}
                    />
                  {/* </div> */}

                  { false &&
                  <DialogPopup
                    type={SelectedDeal?.is_early_payment_enable_on_deal ? 'warning' : 'info'}
                    setIsOpen={setEarlyPayment}
                    isOpen={earlyPayment}
                    heading={'Payment on Delivery* Scheme'}
                    message={
                      SelectedDeal?.is_early_payment_enable_on_deal ?
                        <p>Are you sure you want to disable Payment on Delivery*, for all upcoming deliveries under this deal?</p>
                        : <div>
                          <div style={{ marginBottom: 10 }}>
                            <p>Get paid next business day after completion of Delivery, when you select this scheme (subject to Buyer accepting goods delivered) <span style={{ display: 'block' }}>Early Payment deduction of <span style={{ fontWeight: 'bold' }}>{earlyPaymentDiscount} </span>₹/{SelectedDeal?.price_uom_code}** will apply​​.</span></p>
                            <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__four)' }}>(** subject to change from time to time)</p>
                          </div>
                          <div>
                            <p>By agreeing to proceed, you accept all applicable <a href=' https://my.biofuelcircle.com/terms-and-conditions.html' target={'_blank'}>Terms & Conditions.</a></p>
                            <p>To know more, contact <a href="mailto:support@biofuelcircle.com?subject=Early Payment Scheme!&body=I wish to know more about the Early Payment Scheme!">BiofuelCircle Support</a>/ +91 (020) 48522522.​</p>
                          </div>
                        </div>
                    }
                    onClick={handelApplyEarlyPayment}
                  >
                  </DialogPopup> }
                  { false &&
                  <DialogPopup
                    type={'info'}
                    setIsOpen={setIsConvertOwnToBFCTransport}
                    isOpen={isConvertOwnToBFCTransport}
                    heading={`Change To BiofuelCircle Transport : Deal #${selectedDealForTransportConversion?.isowntobiofuel_deal_id}`}
                    message={
                      <div>
                        <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 2fr', marginBottom: 8, fontSize: 'var(--fs-base__three)' }}>
                          <div>{selectedDealForTransportConversion?.base_location_code}</div>
                          <div style={{ margin: '0 8px' }}>
                            <DeliveryTruck style={{ height: 40, width: 50, marginLeft: 20 }} />
                            <div style={{ backgroundColor: 'var(--unnamed-color-1b62ab)', height: 10, clipPath: 'polygon(0 25%, 75% 25%, 75% 0, 88% 50%, 75% 100%, 75% 75%, 0 75%)' }}></div>
                            <span style={{ fontSize: 'var(--fs-base__four)', fontWeight: 'bold', marginLeft: 13 }}>{selectedDealForTransportConversion?.isowntobiofuel_number_of_deliveries} * {selectedDealForTransportConversion?.isowntobiofuel_vehicle_size}</span>
                          </div>
                          <div>{selectedDealForTransportConversion?.delivery_location_code}</div>
                        </div>
                        <p>Get BiofuelCircle Transport at <strong style={{ color: 'var(--unnamed-color-00ae11)' }}>₹{utils.formatNumberPrice(selectedDealForTransportConversion?.isowntobiofuel_transport_rate)}/{selectedDealForTransportConversion?.transport_uom_code}</strong></p>
                        <p>All eligible* confirmed deliveries under deal #{selectedDealForTransportConversion?.isowntobiofuel_deal_id} will be changed <span style={{ fontSize: 'var(--fs-base__three)' }}>(*excludes deliveries with loading dates within next 3 days)</span></p>
                        <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__one)' }}>This change cannot be undone.</p>
                        <div>
                          <p>By agreeing to proceed, you accept all applicable <a href=' https://my.biofuelcircle.com/terms-and-conditions.html' target={'_blank'}>Terms & Conditions.</a></p>
                          <span>To know more, contact <a href="mailto:support@biofuelcircle.com?subject=Early Payment Scheme!&body=I wish to know more about the Early Payment Scheme!">BiofuelCircle Support</a>/ +91 (020) 48522522.​</span>
                        </div>
                      </div>
                    }
                    onClick={handelConvertToBFCTransport}
                  >
                  </DialogPopup> }
                  { false &&
                  <DialogPopup
                    type={'info'}
                    setIsOpen={setAllowEarlyPayment}
                    isOpen={allowEarlyPayment}
                    heading={'Allow Payment on Delivery* Scheme'}
                    message={
                      <div>
                        <div style={{ marginBottom: 10 }}>
                          <p>Allow Early payment scheme for this deal/all future deliveries of this deal. Are you sure you want to proceed?</p>
                          <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__four)' }}>(** Seller's supplier profile is KYC Verified)</p>
                        </div>
                      </div>
                    }
                    onClick={handelAllowEarlyPayment}
                  >
                  </DialogPopup> }
                  { false &&
                  <Tour
                    steps={steps}
                    isOpen={steps?.length && isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    className="helper"
                    rounded={5}
                  /> }
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </Modal>
  );
}

export default DealModal;

