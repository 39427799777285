import _ from 'lodash';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { actions as kycUserBankIdentityActions } from 'redux/kycUserBankIdentity';
import utils from 'lib/utils';
import LogoLoader from 'common/LogoLoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PopupForm from 'common/Form/PopupForm';
import BankPopup from './BankPopup';
import UpiPopup from './UpiPopup';
import BankVerificationForm from './BankVerificationForm';
import * as handlers from '../handlers';
import * as businessHandlers from 'containers/Account/Profile/KYCBusiness/handlers';
import ToggleSwitch from './toggleSwitch';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { Table, Switch, Radio, Space } from 'antd';
import AntTableActions from 'common/AntTableActions';
import AntTableKYCActions from 'common/AntTableKYCActions';
import MUIButton from 'common/MUIComponents/Button';
import { AddCircle } from '@material-ui/icons';
import { actions as farmerActions } from 'redux/farmerOnboarding';
import notifications from 'lib/notifications';
import useQuery from 'lib/hooks/useQuery';
import MUITypography from 'common/MUIComponents/Typography';

const getInitialValues = (bankDetails) => {
  let params = {
    bank_account_details_request_model: bankDetails || [],
  };
  return params;
};
const BankForm = (props) => {
  const dispatch = useDispatch();
  const [showPopUp, setPopUp] = useState(false);
  const [showVerificationForm, setVerificationForm] = useState(false);
  const [personBankAccountId, setPersonBankAccountId] = useState(0);
  const [showCancelledCheque, setShowCancelledCheque] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [bankRow, setBankRow] = useState(-1);
  const [isBankSelected, setIsBankSelected] = useState(true);
  const [showUpiPopupForm, setShowUpiPopupForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [showVerify, setShowVerify] = useState(true);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [verificationFailureReason, setVerificationFailureReason] = useState(false);
  const [bankVerificationFailureReason, setBankVerificationFailureReason] = useState('');
  const { id, kycStatusKey, isAdmin, isFacilitator, bankDetails, setBankDetails, user_type_enum, name, farmerPersonId, facilitatorPersonData, setFullNameAsPerBank } = props;
  const kycBankIndividual = useSelector((state) => state.kycUserBankIdentity);
  const busyBank = _.get(kycBankIndividual, 'busy') || false;
  const bankIds = _.get(kycBankIndividual, 'ids') || {};
  const bankDataWithVirtualAccount = _.get(kycBankIndividual, 'bankDetails') || {};
  const user = useSelector((state) => state.user);
  const userProfile = useSelector((state) => state.user?.profile);
  const bankVerificationDetails = useSelector((state) => state.farmerOnboarding.bankVerificationDetails);
  let bankCount = 0;
  let bankArray = isFacilitator ? bankDetails : bankDataWithVirtualAccount;
  let { isCtKyc } = useQuery();
  isCtKyc = Boolean(isCtKyc);
  // let bankArray = [];
  // for (bankCount = 0; bankCount < bankIds.length; bankCount++) {
  //   let id = bankIds[bankCount];
  //   const bankDetails = _.get(kycBankIndividual, `entities[${id}]`) || {};
  //   let newBankDetails = {
  //     ...bankDetails,
  //     operation_enum: 3,
  //   };
  //   bankArray.push(newBankDetails);
  // }

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDeleteBank(id);
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const removeBankDetails = async (index) => {
    if (isFacilitator) {
      const newBank = bankDetails?.filter((item) => item.time_stamp !== index?.time_stamp)
      setBankDetails(newBank)
      if(index?.person_bank_account_id) {
        let params = {
          entity_id: farmerPersonId,
          user_type_enum: 2
        };
        const success = await handlers.handleDeleteBank(params);
        if (success) {
          setTimestamp(Date.now());
        }
      }
    } else {
      let params = {
        entity_id: id,
      };
      const success = await handlers.handleDeleteBank(params);
      if (success) {
        setTimestamp(Date.now());
      }
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  useEffect(() => {
    return () => {
      dispatch(farmerActions.setBankVerificationDetails([]));
    }
  },[]);

  const columns = [
    {
      title: 'Beneficiary Name',
      dataIndex: 'account_name',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.va_id ? `${props.selectedUser ? props.selectedUser : bankArray[0]?.account_name} c/o ${text}` : text}
        </div>
      ),
    },
    {
      title: 'Account Number/UPI',
      dataIndex: 'account_number',
      render: (text, row) => {
        let accountNumber = row.account_number || row?.vpa_id;
        if (!isAdmin) {
          let trailingCharsIntactCount = 4;
          if (row?.account_number?.length > 4 && !row.va_id) {
            accountNumber =
              new Array(row.account_number.length - trailingCharsIntactCount + 1).join('x') +
              row.account_number.slice(-trailingCharsIntactCount);
          }
        }
        return <div className="bfc-table-list-components text-center">{accountNumber}</div>;
      },
    },
    {
      title: 'IFSC Code',
      dataIndex: 'ifsc_code',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    },
    {
      title: 'Default',
      dataIndex: 'default',
      align: 'center',
      render: (text, row) => (
        <FormControlLabel control={<ToggleSwitch checked={row.is_default} disabled={row.va_id} />} />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      align: 'center',
      render: (text, row) => (
        <FormControlLabel control={<ToggleSwitch checked={row.is_active} disabled={row.va_id} />} />
      ),
    },
    {
      title: 'Verification Status',
      dataIndex: 'verification_status',
      align: 'center',
      render: (text, row) => {
        let canEdit =
          row.bank_verification_enum == 1 || row.bank_verification_enum == 4 || row.bank_verification_enum == 6;
        let isUpiID = row.is_vpa ? row?.account_number : ''
        let verificationText = 'Verify Bank';
        let bankVerificationCode = bankVerificationDetails?.log?.status || row.bank_verification_enum_code;
        if (bankVerificationCode == 'VerificationFailed' || bankVerificationCode == 'ZoopFailed') {
          setShowCancelledCheque(true);
        } else if (bankVerificationCode == 'VerificationSuccessful' || bankVerificationCode == 'VERIFIED'){
          setShowCancelledCheque(false);
        }
        let pennyDropped = row.bank_verification_enum == 3;
        if (pennyDropped) {
          verificationText = 'Complete Verification';
        }
        return (
          <div className="bfc-table-list-components text-center">
            {((row?.VPA_Id || row?.is_vpa) && !row.bank_verification_enum_code) ? 'Verified' : bankVerificationDetails?.error_messages?.verification_failed ? <span className='required'>Failed</span> : bankVerificationDetails?.log?.status && bankVerificationDetails?.log?.additional_comment == 'Matched' ? bankVerificationDetails?.log?.status : row.bank_verification_enum_code ? row.bank_verification_enum_code : showVerify ? 'Unverified' : 'Verified'}
            <br />
            {(canEdit || pennyDropped || showVerify) && (row.bank_verification_enum_code !== 'VerificationSuccessful' && !isCtKyc) && (
              <span
                style={{
                  color: 'var(--unnamed-color-1b62ab)',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  if(isFacilitator && !row.VPA_Id) {
                    const params = {
                      account_number: row?.account_number,
                      ifsc_code: row?.ifsc_code,
                      beneficiary_name: row?.account_name,
                    };
                    notifications.show({type: 'info', message: 'Verifying Bank Details...'})
                    const success = await dispatch(farmerActions.verifyBankAccount(params));
                    if(!_.isEmpty(success)) {
                      let message;
                      if (success?.bank_verification_failure_reason) {
                        setBankVerificationFailureReason(success?.error_messages?.bad_request || success?.bank_verification_failure_reason)
                      }
                      if(success?.is_any_error) {
                        message = success?.error_messages?.verification_failed || success?.error_messages?.bad_request || 'Bank Verification Failed'
                        if (success?.error_messages?.bad_request) {
                          setShowCancelledCheque(true)
                        }
                      } else {
                        setVerificationFailureReason(success?.bank_verification_failure_reason || false)
                        setShowVerify((success?.log?.additional_comment && success?.log?.additional_comment === 'Matched') ? false : true)
                        message = (success?.log?.additional_comment && success?.log?.additional_comment === 'Matched') ? 'Bank Verification Successful' : success?.log?.additional_comment && success?.log?.additional_comment
                        success?.log?.additional_comment === 'Matched' && setFullNameAsPerBank && setFullNameAsPerBank(success?.log?.beneficiary_name)
                      }
                      notifications.show({type: (success?.is_any_error || success?.log?.additional_comment !== 'Matched') ? 'error' : 'success', message: message})
                    }
                  } else if ((row.is_vpa || (!isAdmin && !kycStatusKey) || canEdit)) {
                    if (pennyDropped) {
                      setPersonBankAccountId(row.person_bank_account_id);
                      setVerificationForm(true);
                    } else if (!isUpiID) {
                      try {
                        handlers.handleBankVerification(
                          {
                            bank_entity_id: row.bank_account_id,
                            company_affiliate_enum: 1,
                            user_type_enum: user_type_enum || 2
                          },

                          setTimestamp,
                        );
                        setIsReRenderRequired(!isReRenderRequired)
                      } catch (e) {
                        setShowCancelledCheque(true);
                      }
                    } else if (row.is_vpa || row?.vpa_id || row?.VPA_Id) {
                      const success = await handlers.verifyVPAId(row?.bank_account_id ? { Bank_Entity_Id: row?.bank_account_id || row?.person_bank_account_id} : { Vpa_Id:  row?.VPA_Id} , setTimestamp)
                      if (!_.isEmpty(success)) {
                        setIsReRenderRequired(!isReRenderRequired)
                        setShowVerify(false)
                      }
                    }
                  }
                }}>
                {(row?.VPA_Id || row?.is_vpa) ? '' :bankVerificationDetails?.log?.additional_comment == 'Matched' && bankVerificationDetails?.log?.status === 'VERIFIED' ? '' : bankVerificationDetails?.error_messages?.verification_failed || (bankVerificationDetails?.log?.additional_comment && bankVerificationDetails?.log?.additional_comment !== 'Matched') ? verificationText : row.bank_verification_enum_code && (row.bank_verification_enum_code !== 'ZoopFailed' && row.bank_verification_enum_code !== 'Unverified' && row.bank_verification_enum_code !== 'VerificationFailed') ? row.bank_verification_enum_code : verificationText}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      width: '300px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {verificationFailureReason ? verificationFailureReason : row.is_bank_manually_verified && row.bank_verification_enum === 5
            ? 'Verified By Control Tower'
            : row.va_id
              ? 'Your Virtual Account for Online Payments'
              : row.bank_verification_enum === 4 || row.bank_verification_enum === 6
                ? row.bank_verification_failure_reason || bankVerificationFailureReason || 'N/A'
                :  bankVerificationFailureReason || 'N/A'
          }
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'address_text_',
      width: '50px',
      align: 'center',
      render: (value, row, index) => {
        let canEdit =
          row.bank_verification_enum == 1 || row.bank_verification_enum == 4 || row.bank_verification_enum == 6 || (row.is_VPA || row?.is_vpa);
        let params = {
          entity_id: id,
        };
        const obj = {
          children: (
            <AntTableKYCActions
              row={row}
              handleDelete={handleDelete}
              id={row.bank_account_id}
              name={row.account_name}
              cancel={cancel}
              // showFlag={!(isAdmin || kycStatusKey)}
              showFlag={!isFacilitator ? !kycStatusKey : (bankVerificationDetails?.log?.additional_comment == 'Matched' && bankVerificationDetails?.log?.status === 'VERIFIED') || row.bank_verification_enum_code && row.bank_verification_enum_code === 'VerificationSuccessful' ? false : canEdit || isFacilitator}
              setBankRow={setBankRow}
              setShowUpiPopupForm={setShowUpiPopupForm}
              params={params}
              removeBankDetails={removeBankDetails}
              setSelectedRow={setSelectedRow}
              isFacilitator={isFacilitator}
            />
          ),
          props: {},
        };
        return obj;
      },
    },
  ];

  const upiColumns = [
    {
      title: 'Beneficiary Name',
      dataIndex: 'account_name',
      width: "50%",
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {/* {row.va_id ? `${props.selectedUser ? props.selectedUser : bankArray[0]?.account_name} c/o ${text}` : text} */}
          name here
        </div>
      ),
    },
    {
      title: 'UPI ID',
      dataIndex: 'account_name', // change this
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {/* {row.va_id ? `${props.selectedUser ? props.selectedUser : bankArray[0]?.account_name} c/o ${text}` : text} */}
          UPI ID here
        </div>
      ),
    }

  ]

  useEffect(() => {
    const paramsFetch = {
      person_id: id,
      isSerialized: false,
    };
    if (!isFacilitator) {
      dispatch(kycUserBankIdentityActions.fetchBankDetailsWithVirtualAccount(paramsFetch));

    }
  }, [id, timestamp, isReRenderRequired]);

  if (busyBank) {
    return (
      <div
        className=""
        style={{
          position: 'relative',
          minHeight: '150px',
        }}>
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Formik initialValues={getInitialValues(bankArray)} onSubmit={async (values, formikBag) => { }}>
        {(formikBag) => {
          const { values } = formikBag;
          const bankRowCount = _.get(values, 'bank_account_details_request_model.length') || 0;
          return (
            <Form className="formik-form">
              {bankRow >= 0 && (
                <PopupForm
                title={<MUITypography variant='h6'>{_.isEmpty(values.bank_account_details_request_model[bankRow]) ? 'Add Bank' : 'Update Bank'}</MUITypography> }
                  // title={_.isEmpty(values.bank_account_details_request_model[bankRow]) ? 'Add Bank' : 'Update Bank'}
                  onClose={() => setBankRow(-1)}>
                  <BankPopup
                    name={name}
                    setFullNameAsPerBank={setFullNameAsPerBank}
                    bankData={isFacilitator ? bankDetails : values.bank_account_details_request_model[bankRow] || {}}
                    setTimestamp={setTimestamp}
                    bankDetails={bankArray}
                    newBankDetails={bankDetails}
                    setBankDetails={setBankDetails}
                    isFacilitator={isFacilitator}
                    setBankRow={setBankRow}
                    onSave={(qpValue) => {
                      let bank_account_details_request_model = [...values.bank_account_details_request_model];

                      if (bank_account_details_request_model[bankRow]) {
                        bank_account_details_request_model[bankRow] = qpValue;
                      } else {
                        bank_account_details_request_model.push(qpValue);
                      }

                      formikBag.setFieldValue('bank_account_details_request_model', bank_account_details_request_model);
                      setBankRow(-1);
                    }}
                    close={() => setBankRow(-1)}
                  />
                </PopupForm>
              )}
              {showUpiPopupForm && (
                <PopupForm
                  // title={'Add UPI'}
                  title={<MUITypography variant='h6'>Add UPI</MUITypography> }
                  onClose={() => setShowUpiPopupForm(false)}>
                  <UpiPopup
                    name={name}
                    setFullNameAsPerBank={setFullNameAsPerBank}
                    newBankDetails={bankDetails}
                    setBankDetails={setBankDetails}
                    bankData={values.bank_account_details_request_model[bankRow] || selectedRow || {}}
                    setTimestamp={setTimestamp}
                    bankDetails={bankArray}
                    close={() => setShowUpiPopupForm(false)}
                    setShowUpiPopupForm={setShowUpiPopupForm}
                    isFacilitator={isFacilitator}
                    facilitatorPersonData={facilitatorPersonData}
                  />
                </PopupForm>
              )}
              <div className="row form__form-group">
                <div className="bfc-table-box w-100">
                  {((isFacilitator && !bankDetails?.length) || !bankArray?.length) && (
                    <div className="add-new-btn-box mt-20">
                      <div className="businesstax-block d-flex justify-content-between align-items-center mx-3">
                        {/* {(user.socialProfile?.type_of_individual_enum == 1 || isFacilitator) ? // radio btn visible only for farmer
                          <div className="radiobox-wrapper">
                            <label className="radio-color-btn">
                              <input
                                type="radio"
                                name="radiocust3"
                                defaultChecked={true}
                                // disabled={isAdmin || kycStatusKey}
                                onClick={() => { setIsBankSelected(true); }}
                              />{' '}
                              Bank
                              <div className="control-indicator"></div>
                            </label>
                            <label className="radio-color-btn">
                              <input
                                type="radio"
                                name="radiocust3"
                                // defaultChecked={values.have_gst_flag ? true : false}
                                // disabled={isAdmin || kycStatusKey}
                                onClick={() => { setIsBankSelected(false); }}
                              />{' '}
                              UPI
                              <div className="control-indicator"></div>
                            </label>
                          </div> : ''
                        } */}
                         <div className="radiobox-wrapper"></div>
                        <MUIButton
                          onClick={() => { isBankSelected ? setBankRow(bankRowCount + 1) : setShowUpiPopupForm(true) }}
                          // style={{marginLeft: "auto"}}
                          // type="button"
                          // className="add-new-btn"
                          disabled={(!userProfile.is_kyc_done_without_bank_details) && (isAdmin || kycStatusKey)} // added condition for BFC-4371, enabled the button if user is farmer and his KYC is approved without banking details
                          // size="sm"
                          startIcon={<AddCircle />}
                        >
                          {/* <span className="icon">+</span> */}
                          <span className="text">{isBankSelected ? "Add Bank" : "Add UPI"}</span>
                        </MUIButton>
                      </div>
                    </div>
                  )}
                  <Table
                    dataSource={isFacilitator ? bankDetails : values.bank_account_details_request_model}
                    columns={columns}
                    pagination={false}
                    size="small"
                    bordered
                    className="ant-table"
                  style={{ whiteSpace: 'pre' }}

                  />
                  {showVerificationForm && (
                    <BankVerificationForm
                      close={() => setVerificationForm(false)}
                      id={personBankAccountId}
                      backLink={`/account/${id}/kyc`}
                      type={'person_bank_account_id'}
                      setBankDetails={setBankDetails}
                      bankDetails={bankDetails}
                    />
                  )}
                  {showCancelledCheque && (
                    <div>
                      <p style={{ marginTop: '10px', color: 'red' }}>
                        Your bank verification has failed. Upload a pic of crossed cancelled cheque for manual bank
                        verification in the upload tab
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default BankForm;
