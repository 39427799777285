import React from 'react';
import { actions as debugActions } from 'redux/debug';
import { useDispatch } from 'react-redux';
import Icon from 'mdi-react/AlertDecagramIcon';
import _ from 'lodash';
import config from 'config';

const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key, value) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			if (typeof value === 'function') {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};

const DebugBtn = (props) => {
	if (config.isProd) {
		return null;
	}

	const dispatch = useDispatch();

	const handleClick = (e) => {
		// try {
		// 	if (typeof data === 'string') {
		// 		data = { dataText: data };
		// 	} else {
		// 		data = JSON.parse(JSON.stringify(props.data, getCircularReplacer));
		// 	}
		// } catch (err) {
		// 	console.log('\n\n==============debug--btn---err', err);
		// 	utils.displayErrors(err);
		// }

		let data = _.cloneDeepWith(props.data, (value, index, object, stack) => {
			if (typeof value === 'function') {
				return 'function -> Not cloned';
			}

			if (typeof value === 'symbol') {
				return 'Symbol -> Not cloned';
			}

			if (React.isValidElement(value)) {
				// debugger;
				return 'react node -> Not cloned';
			}
			// console.log('\n\n---------------');
			// console.log('cloneDeep->value', value);
			// console.log('cloneDeep->index', index);
			// console.log('cloneDeep->object', object);
			// console.log('cloneDeep->stack', stack);
			// console.log('typeof cloneDeep->value', typeof value);
			// console.log('\n\n---------------');
			// debugger;
		});

		// console.log('data--after--skipping---vals-->', data);

		dispatch(
			debugActions.update({
				data: data,
				open: true,
			}),
		);
	};

	return (
		<div className="data-debug-btn">
			<button className="customizer__close-btn" type="button" onClick={handleClick}>
				<Icon color="red" />
			</button>
		</div>
	);
};

export default DebugBtn;
