import React, { useState, useEffect } from 'react';
import { Field, Formik, ErrorMessage } from 'formik';
import _, { isInteger } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import MUITextField from 'common/MUIComponents/TextField';
import OverviewHeader from 'common/OverviewHeader';
import searchLocation from 'common/Form/searchLocation';
import MUIButton from 'common/MUIComponents/Button';
import { verifyMissedCallMobileNumber, SaveUpdatePersonalDetails } from 'containers/Account/Signup/handlers';
import { locationElocService } from 'services';
import { authService } from 'services';
import notifications from 'lib/notifications';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUITypography from 'common/MUIComponents/Typography';
import MUIToggleControl from 'common/MUIComponents/ToggleControl';
import { Input, Button } from 'antd';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/PhoneInTalk';
import Farms from 'containers/Account/Profile/Farms';
import BankForm from 'containers/Account/Profile/KYCIndividual/components/BankForm';
import { actions as farmerOnboardingActions } from 'redux/farmerOnboarding';
import LogoLoader from 'common/LogoLoader';
import Dropzone from 'react-dropzone';
import { Row } from 'reactstrap';
import uploadIcon from 'assets/images/icons/upload.svg';
import PDFIcon from 'assets/images/pdf-icon.png';
import IMGIcon from 'assets/images/img.png';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import * as handlers from 'containers/Account/Profile/UploadIndividual/handlers';
import * as commodityHandlers from 'pages/commodity/handlers';
import { Link } from 'react-router-dom';
import { actions as uploadDocumentActions } from 'redux/uploadDoc';
import LocationModal from 'containers/Account/Profile/Organisations/components/locationModal';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { getDecryptedUrl } from 'lib/utils';
import NumpadInput from 'common/NumpadInput';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: '20px !important',
      fontWeight: 'bold',
      letterSpacing: '1px !important',
    },
  }),
);

const getInitialValues = (personalDetails, BankDetails, farmDetails) => {
  return {
    mobile_number: personalDetails.mobile_number || '',
    whatsapp_number: personalDetails.whatsapp_number || '',
    first_name: personalDetails.first_name || '',
    middle_name: personalDetails.middle_name || '',
    last_name: personalDetails.last_name || '',
    person_id: personalDetails.person_id || 0,
    location_code: personalDetails.location_code || '',
    isVerified: true,
    location_details: {
      location_code: personalDetails.location_code || '',
      address_lattitude: personalDetails.address_lattitude || '',
      address_longitude: personalDetails.address_longitude || '',
      place_id: personalDetails.place_id || '',
      village_code: personalDetails.village_code || '',
      district_code: personalDetails.district_code || '',
      city_code: personalDetails.city_code || '',
      state_code: personalDetails.state_code || '',
      country_code: personalDetails.country_code || '',
      pin_code: personalDetails.pin_code || '',
      address_line1: personalDetails.address_line1 || '',
      address_line2: personalDetails.address_line2 || '',
      address_line3: personalDetails.address_line3 || '',
      locality: personalDetails.locality || '',
      address_text: '',
      taluka_code: personalDetails.taluka_code || '',
    },
  };
};

const FarmerOnboarding = () => {
  const loc = getDecryptedUrl(window.location.href);
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const tokenState = useSelector((state) => state.locationEloc);
  const farmerOnboarding = useSelector((state) => state.farmerOnboarding);
  let uploadDocuments = useSelector((state) => state.uploadDoc?.entities);
  uploadDocuments = !_.isEmpty(uploadDocuments) ? Object.values(uploadDocuments) : [];
  const [indentDocuments, setIndentDocuments] = useState([]);
  const bankVerificationDetails = useSelector((state) => state.farmerOnboarding.bankVerificationDetails);

  const { Search } = Input;

  const [loader, showLoader] = useState(false);
  const facilitatorData = _.get(farmerOnboarding, 'farmerDetailsForFacilitator') || {};
  const address = _.get(farmerOnboarding, 'farmerDetailsForFacilitator.address') || {};
  const person = _.get(farmerOnboarding, 'farmerDetailsForFacilitator.person') || {};
  const bank = _.get(farmerOnboarding, 'farmerDetailsForFacilitator.bank') || {};
  const crops = _.get(farmerOnboarding, 'farmerDetailsForFacilitator.crops') || [];
  const farms = _.get(farmerOnboarding, 'farmerDetailsForFacilitator.farms') || [];
  const canEdit = address?.can_edit
  const [entityPrimaryId, setEntityPrimaryId] = useState('')

  useEffect(() => {
    if (!_.isEmpty(farms)) {
      let newFarmArr = [];
      let newcrop = _.groupBy(crops, 'person_address_id') || {};
      newcrop = Object.entries(newcrop);
      farms.filter((farm) => {
        return newcrop && newcrop.filter((crop) => {
          if (parseInt(crop[0]) == farm.person_address_id) {
            let newArr = [];
            newArr.push(crop[1]);
            let newFarm = {
              ...farm,
              crops: newArr[0],
            };
            newFarmArr.push(newFarm);
          }
          // else{
          //   let newFarm = {
          //     ...farm,
          //     crops: []
          //   }
          //   newFarmArr.push(newFarm)
          // }
        });
      });
      setFarmDetails(newFarmArr?.length ? newFarmArr : farms);
    }
  }, [farms, crops]);

  const personalDetails = {
    ...address,
    ...person,
  };

  const token = _.get(tokenState, 'entities.undefined');

  const [expanded, setExpanded] = useState(false);
  const [isSameAsPhoneNumber, setIsSameAsPhoneNumber] = useState(false);
  const [farmDetails, setFarmDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [farmerPersonId, setFarmerPersonId] = useState(0);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [indentFileSttachmentId, setIndentFileAttachmentId] = useState([])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const getFarmerDetails = async (id) => {
    await dispatch(farmerOnboardingActions.FetchPersonalDetailsForFacilitator(id));
  };

  useEffect(() => {
    if (farmerPersonId && _.isInteger(farmerPersonId)) {
      getFarmerDetails(farmerPersonId);
    }
  }, [farmerPersonId, isReRenderRequired]);

  useEffect(() => {
    if (!_.isEmpty(bank)) {
      let newArr = [];
      newArr.push(bank);
      setBankDetails(newArr);
    }
  }, [bank]);

  const registerUser = async (values, formik) => {
    showLoader(true);
    let arr = [];
    for (let i = 0; i < farmDetails.length; i++) {
      let obj = {
        farm_Address: {
          ...farmDetails[i],
          taluka_code: farmDetails[i].taluka_code || '',
          landmark: farmDetails[i].landmark || '',
          address_text: farmDetails[i].address_text || '',
          person_address_id: farmDetails[i]?.person_address_id ? farmDetails[i].person_address_id : (farmDetails[i].id || 0),
        },
        crop_details: farmDetails[i].crops,
      };
      delete obj.farm_Address['crops'];

      arr.push(obj);
    }
    const params = {
      ...values,
      bank_Details: !_.isEmpty(bankDetails)
        ? {
          ...bankDetails[0],
          bank_account_verification_log_id: bankVerificationDetails?.log?.bank_account_verification_log_id,
        }
        : {},
      farm_Details: arr,
    };
    params.location_details.pin_code = values.person_id
      ? params.location_details.pin_code
      : params.location_details.pincode;
    params.bank_Details.mobile_number = params.bank_Details.mobile_number ? `${params.bank_Details.mobile_number}` : '';
    let resData = await SaveUpdatePersonalDetails(params);
    if (!_.isEmpty(resData)) {
      if (values.person_id) {
        notifications.show({ type: 'success', message: 'User Details updated successfully' });
      } else {
        notifications.show({ type: 'success', message: 'User registered successfully' });
      }
      if (!values.person_id) {
        getFarmerDetails(resData?.person?.person_id);
        setFarmerPersonId(resData?.person?.person_id);
      } else {
        setFarmerPersonId(0);
      }
      setBankDetails([]);
      setFarmDetails([]);
      setIndentDocuments([]);
      dispatch(farmerOnboardingActions.setFarmerDetailsForFacilitator([]));
      showLoader(false);
      formik.setFieldValue('location_code', '');
      formik.setFieldValue('mobile_number', '');
      formik.resetForm();
    } else {
      showLoader(false);
    }
  };

  const onDrop = async (onDropFiles) => {
    const val = onDropFiles.map((fl) =>
      Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      }),
    );

    const valFilter = indentDocuments?.filter((item) => item.name === val[0]?.name);

    if (val[0].size > 10485760 || !_.isEmpty(valFilter)) {
      try {
        if (val[0].size > 10485760) {
          notifications.show({
            type: 'error',
            message: 'The file is exceeding size limits 10MB',
            key: 'req-form',
          });
        }
        if (!_.isEmpty(valFilter)) {
          notifications.show({
            type: 'error',
            message: 'File already exist',
            key: 'req-form',
          });
        }
        return false;
      } catch (err) {
        console.log('error', err);
      }
    } else {
      setIndentDocuments((result) => [...result, val[0]]);
      const file1 = await handlers.handleUpload(val[0], 19, 1, farmerPersonId, 0, 0);
      // setIndentFileAttachmentId(file1)
      setIndentFileAttachmentId(prevState => [...prevState, file1]);
    }
  };

  let reqId;
  const handleFileDelete = async (file) => {
    if (file?.attachment_id) {
      // if (reqId && file.attachment_id) {
      const success = await commodityHandlers.handleDelete(file.attachment_id);
      // debugger
      if (success) {
        // setTimestamp(Date.now());
        const doc = await dispatch(
          uploadDocumentActions.fetchList({
            entity_primary_id: entityPrimaryId,
            entity_type_enum: 19,
            document_module_enum: 1,
            front_back_enum: 1,
            company_affiliate_enum: 1,
            isFacilitator: true,
          }),
        );
        let docArray = indentDocuments.filter((item, index) => item.name != file.attachment_file_name);
        setIndentDocuments(docArray);
        const indentFileArray = indentFileSttachmentId.filter((item, index) => item?.attachment_file_name != file?.attachment_file_name)
        setIndentFileAttachmentId(indentFileArray)
      }
    } else {
      let docArray = indentDocuments.filter((item, index) => item.name != file.attachment_file_name);
      setIndentDocuments(docArray);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(farmerOnboardingActions.setFarmerDetailsForFacilitator([]));
    };
  }, []);

  const followToggle = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  return (
    <Formik enableReinitialize={true} initialValues={getInitialValues(personalDetails)}>
      {(formik) => {
        let { values, setFieldValue, resetForm } = formik;
        const handelVerifyClick = async (mobileNumber) => {
          const success = await verifyMissedCallMobileNumber(mobileNumber || values.mobile_number);
          setEntityPrimaryId(success?.person_id)
          if (!_.isEmpty(success) && !success?.person_id) {
            setFarmerPersonId(0);
            setBankDetails([]);
            setFarmDetails([]);
            dispatch(farmerOnboardingActions.setFarmerDetailsForFacilitator([]));
            setFieldValue('first_name', '');
            setFieldValue('last_name', '');
            setFieldValue('location_code', '');
            setFieldValue('isVerified', false);
          } else if (success?.person_id) {
            setBankDetails([]);
            setFarmDetails([]);
            setFarmerPersonId(success?.person_id);
            setFieldValue('isVerified', true);
            const doc = await dispatch(
              uploadDocumentActions.fetchList({
                entity_primary_id: success?.person_id,
                entity_type_enum: 19,
                document_module_enum: 1,
                front_back_enum: 1,
                company_affiliate_enum: 1,
                isFacilitator: true,
              }),
            );
          }
        };
        useEffect(() => {
          if (loc) {
            try {
              const url = new URL(loc);
              const urlParams = new URLSearchParams(url.search);
              const mobileNumber = urlParams.get('mobile_number');

              if (mobileNumber) {
                setFieldValue('mobile_number', mobileNumber);
                handelVerifyClick(mobileNumber);
              }
            } catch (error) {
              console.error('Invalid URL:', error);
            }
          }
        }, [loc, setFieldValue]);
        const setFullNameAsPerBank = (name) => {
          let splitName = name?.replace(/(Mr|mr|Miss|miss|Mrs|mrs|Ms|ms|Mx|mx|Dr|dr|Er|er|Sir|sir|Lord|lord|Lady|lady|Shri|shri|Shrimati|shrimati|Shriman|shriman)/, '')
          splitName = splitName?.includes('.') ? splitName?.replace(/./, '') : splitName;
          const nameArray = splitName.trim().split(' ');
          const firstName = (nameArray.length === 1 || nameArray.length === 2) ? nameArray[0] : nameArray.slice(0, -2).join(' ');
          const middleName = nameArray.length >= 3 ? nameArray[nameArray.length - 2] : '';
          const lastName = nameArray.length >= 2 ? nameArray[nameArray.length - 1] : '';
          setFieldValue('first_name', firstName)
          setFieldValue('middle_name', middleName)
          setFieldValue('last_name', lastName)
        };

        return (_.isEmpty(facilitatorData) && _.isInteger(farmerPersonId) && farmerPersonId) || loader ? (
          <LogoLoader />
        ) : (
          <div className="row1">
            <OverviewHeader heading="Farmer Onboarding" />
            <div className="pt-3">
              <Accordion defaultExpanded={true} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <MUITypography variant="h6">Farmer Personal Details</MUITypography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Search
                            placeholder="Mobile Number"
                            enterButton={
                              <Button
                                disabled={!values?.mobile_number}
                                style={{ backgroundColor: '#1b62ab', color: 'white' }}>
                                VERIFY
                              </Button>
                            }
                            size="large"
                            addonBefore={<PhoneIcon />}
                            onSearch={handelVerifyClick}
                            onChange={(e) => setFieldValue('mobile_number', e.target.value)}
                            value={values?.mobile_number}
                          />
                          <NumpadInput defaultValue={values?.mobile_number} setFieldValue={setFieldValue} inputFieldName={'mobile_number'} placeholderValue={'Mobile Number'}  />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                        <Input
                          placeholder="WhatsApp Number"
                          size="large"
                          addonBefore={<WhatsAppIcon />}
                          onChange={(e) => setFieldValue('whatsapp_number', e.target.value)}
                          value={values?.whatsapp_number}
                          disabled={values?.mobile_number == values?.whatsapp_number}
                        />
                        {/* <MUITextField
                          fieldType="mobile"
                          label="WhatsApp Number"
                          value={values?.whatsapp_number}
                          onChange={(newValue) => setFieldValue('whatsapp_number', newValue)}
                          disabled={values?.mobile_number == values?.whatsapp_number}
                          StartIcon={WhatsAppIcon}
                        /> */}
                        <MUIToggleControl
                          size="medium"
                          disabled={values?.mobile_number == values?.whatsapp_number}
                          control="checkbox"
                          label="Same As Phone Number"
                          value={values?.mobile_number == values?.whatsapp_number}
                          onChange={(e, checked) => {
                            setIsSameAsPhoneNumber(checked);
                            setFieldValue('whatsapp_number', values?.mobile_number);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <MUITextField
                          fieldType="alphabetic"
                          label="First Name"
                          disabled={bankDetails?.[0]?.bank_verification_enum_code === 'VerificationSuccessful'}
                          value={values?.first_name}
                          onChange={(newValue) => setFieldValue('first_name', newValue)}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <MUITextField
                          label="Middle Name"
                          fieldType="alphabetic"
                          disabled={bankDetails?.[0]?.bank_verification_enum_code === 'VerificationSuccessful'}
                          value={values?.middle_name}
                          onChange={(newValue) => setFieldValue('middle_name', newValue)}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <MUITextField
                          label="Last Name"
                          fieldType="alphabetic"
                          disabled={bankDetails?.[0]?.bank_verification_enum_code === 'VerificationSuccessful'}
                          value={values?.last_name}
                          onChange={(newValue) => setFieldValue('last_name', newValue)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <Field
                          className="material-form__field"
                          name="location_code"
                          variant="outlined"
                          fullWidth={true}
                          component={searchLocation}
                          disabled={(!canEdit && values?.location_code && values?.person_id) ? true : false}
                          isFarmerUser={true}
                          size="small"
                          placeholder="Select Location"
                          label="Location"
                          // disabled={!values?.isVerified}
                          cb={async (locationValues) => {
                            let locationDetails = locationValues[0] || {};
                            let params = {
                              place_id: locationDetails?.eLoc,
                              bearer_token: token,
                            };
                            const resData = await locationElocService.getLocationDetails(params);
                            let addressLine1 =
                              (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                : '') +
                              (_.get(locationDetails, 'addressTokens.houseName', '')
                                ? _.get(locationDetails, 'addressTokens.houseName', '')
                                : '');

                            let addressLine2 =
                              (_.get(locationDetails, 'addressTokens.street', '')
                                ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                : '') +
                              (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                : '');

                            let addressLine3 =
                              (_.get(locationDetails, 'addressTokens.subLocality', '')
                                ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                : '') +
                              (_.get(locationDetails, 'addressTokens.locality', '')
                                ? _.get(locationDetails, 'addressTokens.locality', '')
                                : '');
                            setFieldValue(
                              'location_details.pincode',
                              _.get(locationDetails.addressTokens, 'pincode') || '',
                            );
                            setFieldValue('location_details.address_line1', addressLine1);
                            setFieldValue('location_details.address_line2', addressLine2);
                            setFieldValue('location_details.address_line3', addressLine3);
                            setFieldValue(
                              'location_details.city_code',
                              _.get(locationDetails.addressTokens, 'city') || '',
                            );

                            setFieldValue(
                              'location_details.district_code',
                              _.get(locationDetails.addressTokens, 'district') || '',
                            );
                            setFieldValue(
                              'location_details.village_code',
                              _.get(locationDetails.addressTokens, 'village') ||
                              _.get(locationDetails.addressTokens, 'city') ||
                              _.get(locationDetails.addressTokens, 'subDistrict') ||
                              '',
                            );
                            setFieldValue(
                              'location_details.state_code',
                              _.get(locationDetails.addressTokens, 'state') || '',
                            );
                            setFieldValue('location_details.place_id', _.get(locationDetails, 'eLoc') || '');
                            setFieldValue('location_details.address_lattitude', `${resData?.latitude}`);
                            setFieldValue('location_details.address_longitude', `${resData?.longitude}`);
                            setFieldValue(
                              'location_details.location_code',
                              (_.get(locationDetails, 'placeName', '')
                                ? _.get(locationDetails, 'placeName', '') + ', '
                                : '') + _.get(locationDetails, 'placeAddress', ''),
                            );
                            setFieldValue(
                              'location_code',
                              (_.get(locationDetails, 'placeName', '')
                                ? _.get(locationDetails, 'placeName', '') + ', '
                                : '') + _.get(locationDetails, 'placeAddress', ''),
                            );
                            // if (locationValues) {
                            //   setLocDetailsEloc(locationValues[0]?.eLoc);
                            // }
                          }}
                        />
                        <div className="mt-2">
                          <div className="form-group-field">
                            {
                              !values?.person_id && (
                                <div>
                                  <MUIButton
                                    variant="text"
                                    type="icon"
                                    Icon={LocationOnIcon}
                                    color="red"
                                    onClick={() => setIsEditorOpen(true)}></MUIButton>
                                  <MUITypography
                                    variant="button"
                                    className="cursor-pointer"
                                    onClick={() => setIsEditorOpen(true)}>
                                    Click to select location
                                  </MUITypography>
                                </div>
                              )
                            }
                            {isEditorOpen && (
                              <Field
                                name="latitude"
                                component={LocationModal}
                                entity="RoadTypeEnum"
                                isOpen={isEditorOpen}
                                isSubscribed={(!canEdit && values?.location_code && values?.person_id) ? true : false}
                                // isSubscribed = {values?.location_code}
                                toggle={followToggle}
                                cb={async (locationValues) => {
                                  let locationDetails = locationValues || {};
                                  let params = {
                                    Latitude: locationDetails?.lat,
                                    Longitude: locationDetails?.lng,
                                  };
                                  const resData = await locationElocService.getReverseLocationDetails(params);
                                  setFieldValue(
                                    'location_details.pincode',
                                    _.get(resData?.results?.[0], 'pincode') || '',
                                  );
                                  setFieldValue(
                                    'location_details.city_code',
                                    _.get(resData?.results?.[0], 'city') || '',
                                  );
                                  let addressLine1 =
                                    (_.get(resData?.results?.[0], 'houseNumber', '')
                                      ? _.get(resData?.results?.[0], 'houseNumber', '') + ', '
                                      : '') +
                                    (_.get(resData?.results?.[0], 'houseName', '')
                                      ? _.get(resData?.results?.[0], 'houseName', '')
                                      : '');

                                  let addressLine2 =
                                    (_.get(resData?.results?.[0], 'street', '')
                                      ? _.get(resData?.results?.[0], 'street', '') + ', '
                                      : '') +
                                    (_.get(resData?.results?.[0], 'sub_sub_locality', '')
                                      ? _.get(resData?.results?.[0], 'sub_sub_locality', '')
                                      : '');

                                  let addressLine3 =
                                    (_.get(resData?.results?.[0], 'sub_locality', '')
                                      ? _.get(resData?.results?.[0], 'sub_locality', '') + ', '
                                      : '') +
                                    (_.get(resData?.results?.[0], 'locality', '')
                                      ? _.get(resData?.results?.[0], 'locality', '')
                                      : '');

                                  setFieldValue('location_details.address_line1', addressLine1);
                                  setFieldValue('location_details.address_line2', addressLine2);
                                  setFieldValue('location_details.address_line3', addressLine3);
                                  setFieldValue(
                                    'location_details.district_code',
                                    _.get(resData?.results?.[0], 'district') || '',
                                  );
                                  setFieldValue(
                                    'village_code',
                                    _.get(resData?.results?.[0], 'village') ||
                                    _.get(resData?.results?.[0], 'city') ||
                                    _.get(resData?.results?.[0], 'subDistrict') ||
                                    '',
                                  );
                                  setFieldValue(
                                    'location_details.state_code',
                                    _.get(resData?.results?.[0], 'state') || '',
                                  );
                                  setFieldValue('location_details.place_id', _.get(locationDetails, 'eLoc') || '');
                                  setFieldValue('location_details.address_lattitude', `${locationDetails?.lat}`);
                                  setFieldValue('location_details.address_longitude', `${locationDetails?.lng}`);
                                  setFieldValue(
                                    'location_details.location_code',
                                    _.get(locationDetails, 'formatted_address', ''),
                                  );
                                  setFieldValue(
                                    'location_details.location_code',
                                    _.get(locationDetails, 'formatted_address', ''),
                                  );
                                }}
                              />
                            )}
                            <div
                            // onClick={()=>setIsEditorOpen(true)}
                            >
                              <MUITypography onClick={() => setIsEditorOpen(true)} variant="body2">{values.location_code}</MUITypography>
                            </div>
                          </div>
                          <ErrorMessage name="latitude" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {farmerPersonId ? (
                <>
                  <Accordion defaultExpanded={true} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <MUITypography variant="h6">Farm Details</MUITypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="w-100">
                        <Farms
                          farmerPersonId={farmerPersonId}
                          isFacilitator={true}
                          farmDetails={farmDetails}
                          setFarmDetails={setFarmDetails}
                          isVerified={values?.isVerified}
                          id={user.id}
                          setIsReRenderRequired={setIsReRenderRequired}
                          isReRenderRequired={isReRenderRequired}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <MUITypography variant="h6">Bank Details</MUITypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bfc-body bfc-form mb-0 w-100">
                        <div className="form-container">
                          <BankForm
                            name={`${values?.first_name} ${values?.middle_name} ${values?.last_name}`}
                            farmerPersonId={farmerPersonId}
                            isFacilitator={true}
                            bankDetails={bankDetails}
                            setBankDetails={setBankDetails}
                            setIsReRenderRequired={setIsReRenderRequired}
                            isReRenderRequired={isReRenderRequired}
                            facilitatorPersonData={person}
                            setFullNameAsPerBank={setFullNameAsPerBank}
                          />
                          {uploadDocuments?.[0]?.attachment_file_name ? (
                            <MUITypography variant="h6">Bank Passbook</MUITypography>
                          ) : (
                            ''
                          )}
                          {uploadDocuments?.[0]?.attachment_file_name && indentDocuments.length < 1 ? (
                            <>
                              {
                                uploadDocuments.map((doc) => (
                                  <div style={{ display: "flex" }}>
                                    <a target="_blank" href={doc?.attachment_path}>
                                      {doc?.attachment_file_name}
                                    </a>
                                    <div class="ml-10">
                                      <TrashIcon
                                        className="cursor-pointer"
                                        style={{
                                          color: 'var(--unnamed-color-f44336)',
                                          fontSize: 'var(--fs-base__four)',
                                        }}
                                        onClick={() => handleFileDelete(doc)}
                                      />
                                    </div>
                                  </div>
                                ))
                              }
                            </>
                          ) : (
                            <>
                              <Dropzone
                                className="dropzone__input col-md-6"
                                accept={'.jpeg,.png, .jpg, application/pdf'}
                                name={name}
                                onDrop={(filesToUpload) => {
                                  onDrop(filesToUpload);
                                }}>
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="drag-block">
                                      <Row className="align-items-center">
                                        <div className="icon-block cursor-pointer">{/* <EditIcon2 /> */}</div>
                                        <img src={uploadIcon} alt="" height="20" />
                                        <span className="form-group-label ml-10" style={{ cursor: 'pointer' }}>
                                          Upload Bank Passbook
                                        </span>
                                      </Row>
                                      <div></div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {indentDocuments.map((file, index) => (
                                <div className="row form__form-group" style={{ marginTop: '10px' }}>
                                  <div className="col-12 col-md-12 col-lg-6">
                                    <div className="bfc-body p-10">
                                      <div class="d-flex justify-content-between">
                                        <div className="d-flex">
                                          <img src={file?.name?.includes('.pdf') ? PDFIcon : IMGIcon} alt="" height="30" />
                                          <span class="ml-10">
                                            <a
                                              style={{
                                                wordBreak: 'break-all',
                                                width: '180px',
                                                overflow: 'hidden',
                                                display: 'inline-block',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                              }}
                                              href={`${file.preview}`}
                                              target="_blank">
                                              {file.name}
                                            </a>
                                          </span>
                                        </div>
                                        <div class="ml-5">
                                          <TrashIcon
                                            className="cursor-pointer"
                                            style={{
                                              color: 'var(--unnamed-color-f44336)',
                                              fontSize: 'var(--fs-base__four)',
                                            }}
                                            onClick={() => handleFileDelete(indentFileSttachmentId?.[index])}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                ''
              )}
              <div className="d-flex justify-content-center">
                <div className="col-md-4 mt-4 pb-4">
                  <MUIButton
                    disabled={!values?.location_code || !values?.first_name}
                    className="w-100"
                    onClick={() => registerUser(values, formik)}>
                    {values.person_id ? 'Update' : 'Register'}
                  </MUIButton>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default FarmerOnboarding;
