import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
// import moment from 'moment';

const FormikDatePickerField = (props) => {
  // const { fieldName, placeholder, formikBag } = props;
  // const { touched, errors } = formikBag;

  const { field, form, fieldName, defaultValue, placeholder, minDate } = props;
  const { touched, errors } = form;

  const name = field.name || fieldName;

  const [date, setDate] = useState(props.defaultValue ? new Date(props.defaultValue) : null);

  // const handleChange = (updatedDate) => {
  //   console.log("OmkarMoholDate", updatedDate)
  //   setDate(updatedDate);
  //   form.setFieldValue(name, updatedDate);
  // };
  const handleChange = (updatedDate) => {
    // Extract the local date components (year, month, day, hours, minutes, seconds)
    const year = updatedDate.getFullYear();
    const month = String(updatedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(updatedDate.getDate()).padStart(2, '0');
    const hours = String(updatedDate.getHours()).padStart(2, '0');
    const minutes = String(updatedDate.getMinutes()).padStart(2, '0');
    const seconds = String(updatedDate.getSeconds()).padStart(2, '0');

    // Create a date string in the desired format (YYYY-MM-DDTHH:mm:ss)
    const dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    // Update the state and form field value with the formatted date string
    setDate(updatedDate);
    form.setFieldValue(name, dateString);
  };

  const hasError = touched[name] && errors[name];

  return (
    <div className="date-picker date-picker--single">
      <div className={`${hasError ? 'has-error' : ''} bfc-date-from-wrapper`}>
        <DatePicker
          selected={date}
          selectsStart
          // startDate={startDate}
          // endDate={endDate}
          // onSelect={handleChange}
          onChange={handleChange}
          dateFormat="MMM d, yyyy"
          placeholderText={placeholder || 'select date'}
          dropDownMode="select"
          withPortal={isMobileOnly}
          minDate={minDate || new Date()}
        />
        {touched[name] && errors[name] && <span className="form__form-group-error">{errors[name]}</span>}
      </div>
    </div>
  );
};

export default FormikDatePickerField;
