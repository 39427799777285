import React, { useEffect, useState, useCallback, useRef  } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Button } from 'antd';
import mondaySdk from 'monday-sdk-js';
import DealService from '../../services/deal';

const FeedbackModal = ({
  feedbackId,
  isOpenFeedbackModal,
  setIsOpenFeedbackModal,
  isFeedbackFormSubmitted,
  setIsFeedbackFormSubmitted
}) => {
  // const accessToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjIyNjMwNDYyNiwiYWFpIjoxMSwidWlkIjozNzQ3NzA0NSwiaWFkIjoiMjAyMy0wMS0yMFQwNjowMzowNS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE0MTA1MTIsInJnbiI6InVzZTEifQ.I2UDZUht4ZKNXhvQmdFRDOaO2bIFuX-jWnq_1M1OmIU';
  const [accessToken, setAccessToken] = useState("")
  const [mondayBoardId, setMondayBoardId] = useState("")
  const [mondaySourceUrl, setMondaySourceUrl] = useState("")
  const monday = mondaySdk();
  const [itemsCount, setItemsCount] = useState(null);
  const [feedbackTitle, setFeedbackTitle] = useState("");

  useEffect(() => {
    console.log('mondayBoardId:', mondayBoardId);
    const fetchMondayDataCount = async () => {
      if (!accessToken || !mondayBoardId) return;
      try {
        monday.setToken(accessToken);
        const response = await monday.api(`
          query {
            boards(ids: [${mondayBoardId.toString()}]) {
              items_count
            }
          }
        `);
        // const response = await monday.api(`
        //   query {
        //     boards(ids: [7085028779]) {
        //       items_count
        //     }
        //   }
        // `);
        setItemsCount(response?.data?.boards?.[0]?.items_count || 0);
      } catch (error) {
        console.error('Error fetching data from Monday.com:', error);
      }
    };
    fetchMondayDataCount();
  }, [accessToken, mondayBoardId]);


  useEffect(() => {
    if (itemsCount === null || !isOpenFeedbackModal) return;

    const getLatestMondayBoardData = async () => {
      try {
        monday.setToken(accessToken);
        const response = await monday.api(`
          query {
             boards(ids: [${mondayBoardId.toString()}]) {
              items_count
            }
          }
        `);
        // const response = await monday.api(`
        //   query {
        //     boards(ids: [7085028779]) {
        //       items_count
        //     }
        //   }
        // `);
        const newItemsCount = response?.data?.boards?.[0]?.items_count || 0;
        if (newItemsCount !== itemsCount) {
          // setItemsCount(newItemsCount);
          if (newItemsCount > itemsCount) {
            feedbackFormSubmit();
            setItemsCount(newItemsCount);
          }
        }
      } catch (error) {
        console.error('Error fetching data from Monday.com:', error);
      }
    };

    const intervalId = setInterval(getLatestMondayBoardData, 3000);
    return () => clearInterval(intervalId);
  }, [itemsCount]);

  useEffect(() => {
    const fetchFeedbackForUser = async () => {
      try {
        const feedbackData = await DealService.FetchFeedbackForUser();
        const feedbackEntry = feedbackData.data.find(entry => entry.feedback_id === feedbackId);
        if (feedbackEntry) {
          setFeedbackTitle(feedbackEntry.feedback_code);
          setIsOpenFeedbackModal(!feedbackEntry.is_feedback_submitted);
          // setAccessToken("eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjM4ODM5Mjg1NCwiYWFpIjoxMSwidWlkIjoyNDI5NzY0NiwiaWFkIjoiMjAyNC0wNy0yNFQxMjo1MDo0OC4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6Nzc0NDc3NCwicmduIjoidXNlMSJ9.z3Vlvw0OVbUBEoYURjSqmmwWQNyKbAXpoCP3AtT-sjM")
          setAccessToken(feedbackEntry?.monday_access_token)
          setMondayBoardId(feedbackEntry?.monday_board_id)
          setMondaySourceUrl(feedbackEntry?.monday_source_url)
        }
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };
    fetchFeedbackForUser();
  }, [feedbackId, setIsOpenFeedbackModal]);

  const feedbackFormSubmit = useCallback(async () => {
    setIsFeedbackFormSubmitted(true);
    try {
      await DealService.SubmitFeedback(feedbackId, true);
      setIsOpenFeedbackModal(false);
    } catch (error) {
      console.error('Error while submitting feedback:', error);
    }
  }, [feedbackId, setIsFeedbackFormSubmitted, setIsOpenFeedbackModal]);

  const toggleModal = useCallback(() => {
    setIsOpenFeedbackModal(!isOpenFeedbackModal);
    if (!isFeedbackFormSubmitted) {
      DealService.SubmitFeedback(feedbackId, false).catch(error => {
        console.error("Error while submitting feedback:", error);
      });
    }
  }, [isOpenFeedbackModal, isFeedbackFormSubmitted, feedbackId, setIsOpenFeedbackModal]);

  return (
    <Modal className='modal-content-main' size="lg" isOpen={isOpenFeedbackModal} toggle={toggleModal}>
      {/* <ModalHeader toggle={toggleModal}><h4>{feedbackTitle}</h4></ModalHeader> */}
      <ModalBody>
        <div>
          {/* <iframe src="https://forms.monday.com/forms/embed/f7ec39819a94a9d0ce86bd68a5870c75?r=use1" width="750" height="550" style={{border:"none"}}></iframe> */}
          <iframe src={mondaySourceUrl} width="750" height="550" style={{border:"none"}}></iframe>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          {isFeedbackFormSubmitted ? 'Close' : 'Remind me later'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FeedbackModal;
// import React, { useEffect, useState, useCallback } from 'react';
// import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
// import { Button } from 'antd';
// import mondaySdk from 'monday-sdk-js';
// import DealService from '../../services/deal';

// const FeedbackModal = ({
//   feedbackId,
//   isOpenFeedbackModal,
//   setIsOpenFeedbackModal,
//   isFeedbackFormSubmitted,
//   setIsFeedbackFormSubmitted
// }) => {
//   const accessToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjIyNjMwNDYyNiwiYWFpIjoxMSwidWlkIjozNzQ3NzA0NSwiaWFkIjoiMjAyMy0wMS0yMFQwNjowMzowNS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE0MTA1MTIsInJnbiI6InVzZTEifQ.I2UDZUht4ZKNXhvQmdFRDOaO2bIFuX-jWnq_1M1OmIU';
//   const monday = mondaySdk();
//   const [itemsCount, setItemsCount] = useState(null);
//   const [isPolling, setIsPolling] = useState(true);
//   const [typeFormId, setTypeFormId] = useState("");
//   const [feedbackTitle, setFeedbackTitle] = useState("");

//   const fetchMondayData = useCallback(async () => {
//     try {
//       monday.setToken(accessToken);
//       const response = await monday.api(`
//         query {
//           boards(ids: [7066680998]) {
//             items_count
//           }
//         }
//       `);
//       setItemsCount(response?.data?.boards?.[0]?.items_count || 0);
//     } catch (error) {
//       console.error('Error fetching data from Monday.com:', error);
//     }
//   }, [accessToken]);

//   useEffect(() => {
//     fetchMondayData();
//     const intervalId = setInterval(fetchMondayData, 5000); // Reduced frequency
//     return () => clearInterval(intervalId);
//   }, [fetchMondayData]);

//   useEffect(() => {
//     const fetchFeedbackForUser = async () => {
//       try {
//         const feedbackData = await DealService.FetchFeedbackForUser();
//         const feedbackEntry = feedbackData.data.find(entry => entry.feedback_id === feedbackId);
//         if (feedbackEntry) {
//           setFeedbackTitle(feedbackEntry.feedback_code);
//           setTypeFormId(feedbackEntry.typeform_id);
//           setIsOpenFeedbackModal(!feedbackEntry.is_feedback_submitted);
//         }
//       } catch (error) {
//         console.error("Error fetching feedback:", error);
//       }
//     };
//     fetchFeedbackForUser();
//   }, [feedbackId, setIsOpenFeedbackModal]);

//   const feedbackFormSubmit = useCallback(async () => {
//     setIsFeedbackFormSubmitted(true);
//     setIsPolling(false);
//     try {
//       await DealService.SubmitFeedback(feedbackId, true);
//       setIsOpenFeedbackModal(false);
//     } catch (error) {
//       console.error('Error while submitting feedback:', error);
//     }
//   }, [feedbackId, setIsFeedbackFormSubmitted, setIsOpenFeedbackModal]);

//   useEffect(() => {
//     if (!isPolling || itemsCount === null) return;

//     const getLatestMondayBoardData = async () => {
//       try {
//         monday.setToken(accessToken);
//         const response = await monday.api(`
//           query {
//             boards(ids: [7066680998]) {
//               items_count
//             }
//           }
//         `);
//         const newItemsCount = response?.data?.boards?.[0]?.items_count || 0;
//         if (newItemsCount !== itemsCount) {
//           setItemsCount(newItemsCount);
//           if (newItemsCount > itemsCount) { // Example condition
//             feedbackFormSubmit();
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching data from Monday.com:', error);
//       }
//     };

//     const intervalId = setInterval(getLatestMondayBoardData, 3000); // Reduced frequency
//     return () => clearInterval(intervalId);
//   }, [isPolling, itemsCount, feedbackFormSubmit]);

//   const toggleModal = useCallback(() => {
//     setIsOpenFeedbackModal(!isOpenFeedbackModal);
//     if (!isFeedbackFormSubmitted) {
//       DealService.SubmitFeedback(feedbackId, false).catch(error => {
//         console.error("Error while submitting feedback:", error);
//       });
//     }
//   }, [isOpenFeedbackModal, isFeedbackFormSubmitted, feedbackId, setIsOpenFeedbackModal]);

//   return (
//     <Modal className='modal-content-main' size="lg" isOpen={isOpenFeedbackModal} toggle={toggleModal}>
//       <ModalHeader toggle={toggleModal}><h4>{feedbackTitle}</h4></ModalHeader>
//       <ModalBody>
//         <div>
//           <iframe src="https://forms.monday.com/forms/embed/826ab1d6b8adb94f938b97e531eb7132?r=use1" width="600" height="500" style={{border:"none"}}></iframe>
//         </div>
//       </ModalBody>
//       <ModalFooter>
//         <Button color="secondary" onClick={toggleModal}>
//           {isFeedbackFormSubmitted ? 'Close' : 'Remind me later'}
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

// export default FeedbackModal;


// import React, { useEffect, useState } from 'react';
// import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
// import { Button } from 'antd';
// import mondaySdk from 'monday-sdk-js';
// import DealService from '../../services/deal';

// const FeedbackModal = ({
//   feedbackId,
//   isOpenFeedbackModal,
//   setIsOpenFeedbackModal,
//   isFeedbackFormSubmitted,
//   setIsFeedbackFormSubmitted
// }) => {
//   const accessToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjIyNjMwNDYyNiwiYWFpIjoxMSwidWlkIjozNzQ3NzA0NSwiaWFkIjoiMjAyMy0wMS0yMFQwNjowMzowNS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE0MTA1MTIsInJnbiI6InVzZTEifQ.I2UDZUht4ZKNXhvQmdFRDOaO2bIFuX-jWnq_1M1OmIU';
//   const monday = mondaySdk();
// console.log("OmkarIsOpneFeedback", isOpenFeedbackModal)
//   const [itemsCount, setItemsCount] = useState(null);
//   const [isPolling, setIsPolling] = useState(true);
//   const [typeFormId, setTypeFormId] = useState("");
//   const [feedbackTitle, setFeedbackTitle] = useState("");

//   useEffect(() => {
//     monday.setToken(accessToken);

//     const fetchData = async () => {
//       try {
//         const response = await monday.api(`
//           query {
//             boards(ids: [7066680998]) {
//               items_count
//             }
//           }
//         `);
//         console.log('API Response:', response);
//         setItemsCount(response);
//       } catch (error) {
//         console.error('Error fetching data from Monday.com:', error);
//       }
//     };

//     fetchData();
//     const intervalId = setInterval(fetchData, 5000);
//     return () => clearInterval(intervalId);
//   }, [accessToken]);

//   useEffect(() => {
//     const feedbackForUser = async () => {
//       try {
//         const feedbackData = await DealService.FetchFeedbackForUser();
//         const feedbackEntry = feedbackData.data.find(entry => entry.feedback_id === feedbackId);
//         console.log("OmkarFeedbackEntry: " + JSON.stringify(feedbackEntry));
//         if (feedbackEntry) {
//           setFeedbackTitle(feedbackEntry.feedback_code);
//           setTypeFormId(feedbackEntry.typeform_id);
//           setIsOpenFeedbackModal(feedbackEntry.is_feedback_submitted ? false : true);
//         }
//       } catch (error) {
//         console.error("Error fetching feedback:", error);
//       }
//     };

//     feedbackForUser();
//   }, [feedbackId, setIsOpenFeedbackModal]);

//   const feedbackFormSubmit = async () => {
//     setIsFeedbackFormSubmitted(true);
//     setIsPolling(false);

//     try {
//       await DealService.SubmitFeedback(feedbackId, true);
//       setIsOpenFeedbackModal(false);
//     } catch (error) {
//       console.error('Error while submitting feedback:', error);
//     }
//   };

//   useEffect(() => {
//     if (!isPolling) return;
//     if(itemsCount){
//       monday.setToken(accessToken);
//     const getLatestMondayBoardData = async () => {
//       try {
//         const response = await monday.api(`
//           query {
//             boards(ids: [7066680998]) {
//               items_count
//             }
//           }
//         `);
//         console.log("Response from API:", response);

//         // Compare responses directly
//         console.log("IsSame", JSON.stringify(response) === JSON.stringify(itemsCount), response, itemsCount)
//         if (JSON.stringify(response) === JSON.stringify(itemsCount)) {
//           console.log("Items count has not changed");
//         } else {
//           setItemsCount(response);
//           feedbackFormSubmit();
//         }
//       } catch (error) {
//         console.error('Error fetching data from Monday.com:', error);
//       }
//     };

//     getLatestMondayBoardData();
//     const intervalId = setInterval(getLatestMondayBoardData, 5000);
//     return () => clearInterval(intervalId);
//     }
//   }, [isPolling, itemsCount]);

//   const toggleModal = () => {
//     setIsOpenFeedbackModal(!isOpenFeedbackModal);
//     if (!isFeedbackFormSubmitted) {
//       const submitFeedback = async () => {
//         try {
//           await DealService.SubmitFeedback(feedbackId, false);
//         } catch (error) {
//           console.error("Error while submitting feedback:", error);
//         }
//       };
//       submitFeedback();
//     }
//   };

//   return (
//     <Modal className='modal-content-main' size="lg" isOpen={isOpenFeedbackModal} toggle={toggleModal}>
//       <ModalHeader toggle={toggleModal}><h4>{feedbackTitle}</h4></ModalHeader>
//       <ModalBody>
//         <div>
//           <iframe src="https://forms.monday.com/forms/embed/826ab1d6b8adb94f938b97e531eb7132?r=use1" width="600" height="500"></iframe>
//         </div>
//       </ModalBody>
//       <ModalFooter>
//         <Button color="secondary" onClick={toggleModal}>
//           {isFeedbackFormSubmitted ? `Close` : `Remind me later`}
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

// export default FeedbackModal;


// import React,{useEffect, useState} from 'react';
// import { Widget } from '@typeform/embed-react';
// import DealService from '../../services/deal';
// import { Modal, ModalBody, ModalFooter } from 'reactstrap';
// import { Button } from 'antd';

// const FeedbackModal = ({
//   feedbackId,
//   isOpenFeedbackModal,
//   setIsOpenFeedbackModal,
//   isFeedbackFormSubmitted,
//   setIsFeedbackFormSubmitted
// }) => {

//   const [giveFeedback, setGiveFeedback] = useState(false)
//   const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
//   const [openExtraModal, setOpenExtraModal] = useState(false)

//   useEffect(()=>{
//     if(giveFeedback){
//         setOpenFeedbackModal(true)
//     }
//   },[giveFeedback])

//   useEffect(()=>{
//     if(!isOpenFeedbackModal){
//       setOpenFeedbackModal(true)
//     }else{
//       setOpenExtraModal(true)
//     }
//   },[])

//   useEffect(()=>{
//     const feedbackForUser = async () => {
//       try {
//         const feedbackData = await DealService.FetchFeedbackForUser();
//         const feedbackEntry = feedbackData.data.find(entry => entry.feedback_id === feedbackId);
//         if (feedbackEntry && feedbackEntry.is_feedback_submitted) {
//           // setIsOpenFeedbackModal(false);
//           if(isOpenFeedbackModal){
//             setOpenExtraModal(false)
//           }else{
//             setOpenFeedbackModal(false)
//           }
//         } else {
//           null
//         }
//       } catch (error) {
//         console.error("Error fetching feedback:", error);
//       }
//     };
//     feedbackForUser();
//   },[])

//   const feedbackFormSubmit=()=>{
//     console.log("FeedbackFormSubmitted")
//     setIsFeedbackFormSubmitted(true)
//     setOpenFeedbackModal(false)
//     const submitFeedback=async()=>{
//       try {
//         const response = await DealService.SubmitFeedback(feedbackId,true)
//         setIsOpenFeedbackModal(!isOpenFeedbackModal);
//       } catch (error) {
//         console.log("Error while submitting feedback")
//       }
//     }
//     submitFeedback()
//   }
//   const toggleModalExtra = () => {
//     setIsOpenFeedbackModal(!isOpenFeedbackModal);
//     if(!isFeedbackFormSubmitted){
//       console.log("Closed Without Submitting Form")
//       const submitFeedback=async()=>{
//         try {
//           const response = await DealService.SubmitFeedback(feedbackId,false)
//         } catch (error) {
//           console.log("Error while submitting feedback")
//         }
//       }
//       submitFeedback()
//     }
//     console.log("Form closed")
//   };

//   const toggleModal = () => {
//     setOpenFeedbackModal(false)
//     if(!isFeedbackFormSubmitted){
//       console.log("Closed Without Submitting Form")
//       const submitFeedback=async()=>{
//         try {
//           const response = await DealService.SubmitFeedback(feedbackId,false)
//         } catch (error) {
//           console.log("Error while submitting feedback")
//         }
//       }
//       submitFeedback()
//     }
//     console.log("Form closed")
//   };

//   const submitServey=()=>{
//     setIsOpenFeedbackModal(!isOpenFeedbackModal)
//     setGiveFeedback(true)
//   }
//   return (
//          <>
//             {
//               isOpenFeedbackModal ? (
//                 <>
//                 <Modal
//                   style={{width:"600px", height:"600px"}}
//                   size='lg'
//                   isOpen={openFeedbackModal}
//                   toggle={toggleModalExtra}
//                 >
//                   <Widget id={"wE5TVQUL"}
//                   style={{width:"600px", height:"600px"}}
//                   className="my-form"
//                   onSubmit={()=>feedbackFormSubmit()}
//                   />
//                 </Modal>
//                 {
//                 !isFeedbackFormSubmitted && (
//                 <Modal
//                 isOpen={openExtraModal}
//                 toggle={toggleModalExtra}
//                 >
//                 <ModalBody>
//                 <h4
//                   >Hey do you want to submit quick survey?</h4>
//                 </ModalBody>
//                 <ModalFooter>
//                 <div style={{
//                     display:"flex",
//                     gap:"20px"
//                   }}>
//                   <Button onClick={toggleModal} type='secondary'>
//                     No
//                   </Button>
//                   <Button onClick={()=>submitServey()} type='primary'>
//                     Yes
//                   </Button>
//                   </div>
//                 </ModalFooter>
//                 </Modal>
//                   )
//                 }
//               </>
//               ) : (
//                 <>
//                 <Modal
//                 style={{width:"600px", height:"600px"}}
//                 size='lg'
//                 isOpen={openFeedbackModal}
//                 toggle={toggleModal}
//                 >
//                 <Widget id={"wE5TVQUL"}
//                 style={{width:"600px", height:"600px"}}
//                 className="my-form"
//                 onSubmit={()=>feedbackFormSubmit()}
//                 />
//               </Modal>
//               </>
//               )
//             }
//          </>
//   );
// };

// export default FeedbackModal;