import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';

const FormikMaterialTextFieldAcct = (props) => {
  // console.log('MaterialFormikTextField--->props', props)

  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    placeholder,
    type,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-formik-text-field ${hasError ? 'has-error' : ''}`}>
      <input
        style={{
          '-webkit-text-security': 'disc',
          'text-security': 'disc',
          width: '100%',
          height: '38px',
          padding: '10.5px 14px 10.5px 14px',
          color: 'var(--unnamed-color-707070)',
        }}
        //  className="{`mui-formik-text-field ${hasError ? 'has-error' : ''}`}"
        fullWidth
        variant={variant || 'outlined'}
        size="small"
        disabled={disabled}
        error={hasError}
        helperText={errText}
        placeholder={placeholder}
        type={type}
        {...field}
      />
    </div>
  );
};

export default FormikMaterialTextFieldAcct;
