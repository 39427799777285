import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import { Button, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonGroup } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
// import LogoLoader from 'common/LogoLoader';
import utils, { decryptionInHex,  getDecryptedRouteParams, getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import useQuery from 'lib/hooks/useQuery';
import { Table, Tag, Tooltip, Popover, Skeleton } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import { actions as deliveryAllotmentsActions } from 'redux/deliveryAllotments';
import { actions as deliveryReportRegularActions } from 'redux/generateDeliveryReportRegular';
import { actions as generateDeliveryContractReportCTActions } from 'redux/generateUpcomingDeliveryReport';
import { actions as generateDeliveryContractReportCTActions2 } from 'redux/generateReportCommon';
import { actions as deliveriesCTActions } from 'redux/deliveriesCT';
import { actions as deliveryContractActions } from 'redux/deliveryContract';
import DeliveryOverviewNav from 'common/DeliveryOverviewNav';
import OverviewHeader from 'common/OverviewHeader';
import DeliveryField from 'common/TableComponents/deliveryField';
import DueDateField from 'common/TableComponents/dueDateField';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import DateSearchFilter from 'common/Form/AntFilters/DateSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import FilterUtils from 'lib/filterUtils';
import AntPagination from 'common/AntTablePagination';
import ConfirmModal from 'pages/delivery/components/ConfirmModal';
import * as handlers from 'pages/delivery/handlers';
import PopupForm from 'common/Form/PopupForm';
import DeliveryForm from 'pages/network/delivery/components/DeliveryForm';
import DeliveryFormNotConfirmed from 'pages/network/delivery/components/DeliveryFormNotConfirmed';
import AntTableEditFunction from 'common/AntTableEdit/antTableEditFunction';
import QuantityField from 'common/TableComponents/quantityField';
import SecuredIcon from 'assets/images/icons/securedIcon.png';
import SecuredTransport from 'assets/images/icons/secureTransport.svg';
import DialogPopup from 'common/DialogPopUp';
import { actions as filterOptionsActions } from 'redux/filterOptions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import AntTableActions from 'common/TableActions';
import { WarningFilled } from '@ant-design/icons';
import EwayBill from 'assets/images/icons/receipt-icon.svg'
import OfferIcon from 'assets/images/icons/Offer.svg'
import OfferAvailedIcon from 'assets/images/icons/Offer-availed.svg'
import DeliveryTruckIcon2 from 'common/Icons/DeliveryTruckIcon2';
import BioFuelCircleLogo from 'assets/BiofuelCircleLogo.png';
import BioFuelCircleTransport from 'assets/images/icons/bfcTransportation.svg';
import BFCBuyerTransport from 'assets/images/icons/bfcBuyerTransportation.svg';
import BFCSellerTransport from 'assets/images/icons/bfcSellerTransportation.svg';
import { actions as dealsActions } from 'redux/deals';
import { actions as deliveryActions } from 'redux/deliveries';
import Tour from 'reactour'
import confirmationPopupImg from 'assets/early-payment.png'
import confirmationPopupAvailed from 'assets/early-payment-availed.png'
import confirmationPopupDeActivated from 'assets/early-payment-deactive.png'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { ReactComponent as DeliveryTruck } from 'assets/images/icons/delivery-truck-full.svg'
import ConvertToBFCTransportImg from 'assets/conver_to_BFC_transport.png'
import { ReactComponent as AllowEarlyPaymentIcon } from 'assets/images/icons/allow-early-payment.svg'
import { ReactComponent as EarlyPaymentAllowedIcon } from 'assets/images/icons/early-payment-allowed.svg'
import EarlyPaymentAvailedUsingCTIcon from 'assets/images/icons/early-payment-availed-using-ct.svg'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import 'antd/dist/antd.css';
import './styles.scss';
import MUITypography from 'common/MUIComponents/Typography';
import { actions as upcomingActions } from 'redux/upcomingDeliveries';
import { actions as ongoingActions } from 'redux/ongoingDeliveries';
import { actions as completedActions } from 'redux/completedDeliveries';
import { actions as missedActions } from 'redux/missedDeliveries'
import { actions as notConfirmedActions } from 'redux/notConfirmedDeliveries'
import { actions as notConfirmedDeliveriesCsvDataActions } from 'redux/notConfirmDeliveriesCsvData'
import notifications from 'lib/notifications';
// import notConfirmedDeliveries from 'redux/notConfirmedDeliveries';
import BulkActionsDropdown from './BulkActionsDropdown'
import { Reason_Type_Enum, Delivery_Schedule_Enum, Operation_Enum } from '../../../constants/enums'

const getCloseInitialValues = () => {
  let fieldValues = {
    unuseddeliveryenum: 1,
    is_market: true,
  };
  return fieldValues;
};

const getAcceptInitialValues = () => {
  let fieldValues = {
    unuseddeliveryenum: 2,
    is_market: true,
  };
  return fieldValues;
};
const getCTAcceptInitialValues = () => {
  let fieldValues = {
    unuseddeliveryenum: 2,
    is_market: false,
  };
  return fieldValues;
};
function NotConfirmedDeliveries(props) {
  const { isWalkIn, setSelectedDeliveries } = props;
  let loc = getDecryptedUrl(window.location.href);
  let { id, type, place } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const deals = useSelector((state) => state.deals);
  const isControlTowerUser = _.get(userInfo, 'profile.person.is_control_tower_user') || false;

  let buyer_person_id;
  if (id && deals) {
    buyer_person_id = deals?.data?.[id]?.buyer_person_id;
  }

  let delivery_split_enum = '';

  if (loc.includes('upcoming')) {
    delivery_split_enum = '1';
  } else if (loc.includes('ongoing')) {
    delivery_split_enum = '3';
  } else if (loc.includes('completed')) {
    delivery_split_enum = '2';
  } else if (loc.includes('missed')) {
    delivery_split_enum = '4';
  } else if (loc.includes('not-confirmed')) {
    delivery_split_enum = '5';
  }


  let {
    page,
    commodity_deal_master_id,
    transport_deal_master_id,
    consignor_id,
    consignee_id,
    vehicle_id,
    driver_id,
    base_location_code,
    delivery_location_code,
    pickup_time,
    delivery_allotment_status_enum,
    transport_delivery_schedule_alloted_id,
    goodsloadedacknowledgement_timestamp,
    goodsdeliveredacknowledgement_timestamp,
    is_own_transport,
    back,
    is_secured,
    transporter_id,
    transport_delivery_schedule_id
  } = useQuery();

  // const deliveries = useSelector((state) => state.deliveryAllotments);
  const roles = useSelector((state) => state.rolesAndRights);

  let notConfirmedData;

  notConfirmedData = useSelector((state) => state.notConfirmedDeliveries)

  let deliveries;

  if (delivery_split_enum) {
    //for market->deliveries,CT->deliveries,network->deliveries
    if (delivery_split_enum == 1) {
      deliveries = useSelector((state) => state.upcomingDeliveries)
    } else if (delivery_split_enum == 2) {
      deliveries = useSelector((state) => state.completedDeliveries)
    } else if (delivery_split_enum == 3) {
      deliveries = useSelector((state) => state.ongoingDeliveries)
    } else if (delivery_split_enum == 4) {
      deliveries = useSelector((state) => state.missedDeliveries)
    }
    else if (delivery_split_enum == 5) {
      deliveries = useSelector((state) => state.notConfirmedDeliveries)
    }
  } else {
    deliveries = useSelector((state) => state.deliveryAllotments);
    //for deal overview -> deliveries
  }



  // const busyCT = _.get(deliveriesCT, 'busy') || false;
  // const deliveriesCTList = _.get(deliveriesCT, 'entities') || {};

  const busy = _.get(notConfirmedData, 'busy') || false;
  const deliveriesList = _.get(notConfirmedData, 'entities') || {};
  const deliveriesList1 = Object.values(deliveriesList)
  const totalNotConfirmedDeliveryCount = deliveriesList1.reduce((sum, delivery) => sum + (delivery?.final_delivery_qty || 0), 0);

  const isWalkInRevert = Object.values(deliveriesList).find((x) => x);
  const isWalkInRevertButtonVisible = _.get(isWalkInRevert, `is_walk_in`);

  //   const GenerateReportAllData = useSelector((state) => state.generateUpcomingDeliveryReport);
  //   const GenerateReportAllDataRegular = useSelector((state) => state.generateDeliveryReportRegular);

  const GenerateReportAllData = useSelector((state) => state.notConfirmedDeliveriesCsvData);
  const GenerateReportAllDataRegular = useSelector((state) => state.notConfirmedDeliveriesCsvData);

  const deliveryRejectionReasonData = useSelector((state) => state?.filterOptions?.data?.RejectionReasonTypeEnum);

  const deliveriesContract = useSelector((state) => state.deliveryContract);
  const busyDC = _.get(deliveriesContract, 'busy') || false;
  const deliveriesContractList = _.get(deliveriesContract, 'entities') || {};

  const context = useSelector((state) => state.context);
  const isMarketUpcomingDeliveries = context.ctx === '2';

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const csvLink = useRef();
  const csvLinkUser = useRef();
  const tableRef = useRef();

  const [showModal, setModal] = useState(false);
  const [handler, setHandler] = useState(() => { });
  const [modalValue, setModalValue] = useState(null);
  const [modalFormikBag, setModalFormikBag] = useState(null);
  const [modalTitle, setModalTitle] = useState('');

  const [timeStamp, setTimeStamp] = useState(Date.now());
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [showCTCloseButton, setShowCTCloseButton] = useState(false);
  const [showCTRejectCloseButton, setShowCTRejectCloseButton] = useState(false);

  const [unscheduleArray, setUnscheduleArray] = useState([]);
  const [closeArray, setCloseArray] = useState([]);
  const [checkBoxArray, setCheckBox] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [editDeliveryId, setEditDeliveryId] = useState(false);
  const [reverseToStatus, setReverseToStatus] = useState('');
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);
  const [deliveryRevertReason, setDeliveryRevertReason] = useState('');
  const [moveDeliveriesReasonEnum, setMoveDeliveriesReasonEnum] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const [deliveryRejectionRemark, setDeliveryRejectionRemark] = useState('');
  const [selectedDeliveryRejectReason, setSelectedDeliveryRejectReason] = useState({});
  const [isReloadRequired, setIsReloadRequired] = useState(false);
  const [dealRejectedByOptions, setDealRejectedByOptions] = useState([]);
  const [earlyPayment, setEarlyPayment] = useState(false);
  const [earlyPaymentDiscount, setEarlyPaymentDiscount] = useState(0)
  const [selectedDeal, setSelectedDeal] = useState(false);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [isGreenIconAvailable, setIsGreenIconAvailable] = useState(false)
  const [isOrangeIconAvailable, setIsOrangeIconAvailable] = useState(false)
  const [isTransportConvertIconAvailable, setIsTransportConvertIconAvailable] = useState(false)
  const [selectedDealForTransportConversion, setSelectedDealForTransportConversion] = useState(false)
  const [isConvertOwnToBFCTransport, setIsConvertOwnToBFCTransport] = useState(false)
  const [isConvertMultipleOwnToBFCTransport, setIsConvertMultipleOwnToBFCTransport] = useState(false)
  const [allowEarlyPayment, setAllowEarlyPayment] = useState(false);
  const [isGrayIconAvailable, setIsGrayIconAvailable] = useState(false)
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);
  const [moveDeliveriesDateByDays, setMoveDeliveriesDateByDays] = useState('1');
  const currentDate = moment();
  const [pickUpTime, setPickUpTime] = useState(currentDate.add(1, 'days'));

  const rolesList = roles.entities || {};
  let NetworkLinksRecord = Object.values(rolesList).find((x) => x.module_name === 'Network');
  const NetworkAccess = _.get(NetworkLinksRecord, `full_access`);

  let MarketLinksRecord = Object.values(rolesList).find((x) => x.module_name === 'Market');
  const MarketAccess = _.get(MarketLinksRecord, `full_access`);

  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  const handleEditNotConfirmDelivery = (row) => {
    row.setEditDeliveryId(row.transport_delivery_schedule_id)
  }

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'hbs_pickup_location':
        delete params['base_location_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('base_location_code'));
        break;
      case 'hbs_delivery_location':
        delete params['delivery_location_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('delivery_location_code'));
        break;
      case 'delivery_consignee':
      case 'consignee':
        delete params['consignee_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('consignee_id'));
        break;
      case 'delivery_consignor':
      case 'consignor':
        delete params['consignor_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('consignor_id'));
        break;
      case 'vehicle':
        delete params['vehicle_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('vehicle_id'));
        break;
      case 'driver':
        delete params['driver_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('driver_id'));
        break;
      case 'pickup_datetime':
        delete params['pickup_time'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('pickup_time'));
        break;
      case 'delivery_allotment_status_enum_code':
        delete params['delivery_allotment_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('delivery_allotment_status_enum'));
        break;
      case 'loading_acknowledgement_datetime':
        delete params['goodsloadedacknowledgement_timestamp'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('goodsloadedacknowledgement_timestamp'));
        break;
      case 'delivery_acknowledgement_datetime':
        delete params['goodsdeliveredacknowledgement_timestamp'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('goodsdeliveredacknowledgement_timestamp'));
        break;
      case 'transporter_against_own_deals':
        delete params['transporter_id'];
        delete params['transporter_person_company_affiliate_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('transporter_id'));
        history.push(FilterUtils.removeUrl('transporter_person_company_affiliate_enum'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params;
    if (loc.includes('market/deliveries/upcoming') ||
      loc.includes('market/deliveries/ongoing') ||
      loc.includes('market/deliveries/completed') ||
      loc.includes('market/deliveries/missed') ||
      loc.includes('market/deliveries/not-confirmed') ||
      loc.includes('network/deliveries/upcoming') ||
      loc.includes('network/deliveries/ongoing') ||
      loc.includes('network/deliveries/completed') ||
      loc.includes('network/deliveries/missed') ||
      loc.includes('network/deliveries/not-confirmed') ||
      loc.includes('controltower/deliveries/upcoming') ||
      loc.includes('controltower/deliveries/ongoing') ||
      loc.includes('controltower/deliveries/completed') ||
      loc.includes('controltower/deliveries/not-confirmed') ||
      loc.includes('controltower/deliveries/missed')) {
      params = {
        ...filters,
      };
    } else {
      params = {
        ...filters,
        back: back,
      };
    }
    switch (dataIndex) {
      case 'hbs_pickup_location':
        params = {
          ...params,
          base_location_code: e,
        };
        break;
      case 'hbs_delivery_location':
        params = {
          ...params,
          delivery_location_code: e,
        };
        break;
      case 'consignee':
      case 'delivery_consignee':
        params = {
          ...params,
          consignee_id: e,
        };
        break;
      case 'consignor':
      case 'delivery_consignor':
        params = {
          ...params,
          consignor_id: e,
        };
        break;
      case 'vehicle':
        params = {
          ...params,
          vehicle_id: e,
        };
        break;
      case 'driver':
        params = {
          ...params,
          driver_id: e,
        };
        break;
      case 'pickup_datetime':
        params = {
          ...params,
          pickup_time: e,
        };
        break;
      case 'delivery_allotment_status_enum_code':
        params = {
          ...params,
          delivery_allotment_status_enum: e,
        };
        break;
      case 'loading_acknowledgement_datetime':
        params = {
          ...params,
          goodsloadedacknowledgement_timestamp: e,
        };
        break;
      case 'delivery_acknowledgement_datetime':
        params = {
          ...params,
          goodsdeliveredacknowledgement_timestamp: e,
        };
        break;
      case 'transporter_against_own_deals':
        params = {
          ...params,
          transporter_id: e,
          // transporter_person_company_affiliate_enum: transportPersonCompanyAffEnum && transportPersonCompanyAffEnum,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };
  const cancel = (e) => {
  };

  const handelApplyEarlyPayment = async () => {
    const success = await dispatch(dealsActions.applyEarlyPaymentOffer({ commodity_deal_master_id: selectedDeal?.commodity_deal_master_id }));
    if (success) {
      setEarlyPayment(false)
      setIsReRenderRequired(!isReRenderRequired)
    }
  }

  const handelAllowEarlyPayment = async () => {
    const success = await dispatch(dealsActions.allowEarlyPaymentCT({ deal_delivery_id: selectedDeal?.transport_delivery_schedule_alloted_id, deal_delivery_enum: 2 }));
    success && setIsReloadRequired(!isReloadRequired)
    if (success) {
      setAllowEarlyPayment(false)
    }
  }

  const enableDisableEarlyPaymentOnDelivery = async () => {
    const params = {
      transport_delivery_schedule_alloted_id: selectedDeal?.transport_delivery_schedule_alloted_id,
      is_early_payment_delivery: selectedDeal?.early_payment_metadata_id ? false : true
    }
    const success = await dispatch(deliveryActions.enableDisableEarlyPaymentOnDelivery(params));
    if (success) {
      setEarlyPayment(false)
      setIsReRenderRequired(!isReRenderRequired)
    }
  }

  const handelConvertToBFCTransport = async () => {
    const params = {
      ids: isConvertMultipleOwnToBFCTransport ? selectedRowKeys : [selectedDealForTransportConversion?.isowntobiofuel_delivery_id],
      deal_Delivery_Enum: 2 //for deal = 1 and delivery = 2
    }
    const success = await dispatch(dealsActions.convertToBioFuelCircleTransport(params));
    success && setIsReloadRequired(!isReloadRequired)
    if (success) {
      setIsConvertOwnToBFCTransport(false)
      setIsConvertMultipleOwnToBFCTransport(false)
    }
  }

  const DELIVERIES_TABLE_HEADERS = [
    {
      title: loc.includes('not-confirmed') ? "Schedule Id" : 'Delivery No',
      dataIndex: 'transport_delivery_schedule_id',
      key: 'transport_delivery_schedule_id',
      width: '130px',
      align: 'center',
      fixed: true,
      render: (text, row) => (
        <div className="d-flex justify-content-between align-items-center">
          <DeliveryField
            delivery_number={loc.includes('not-confirmed') ? row?.transport_delivery_schedule_id : row.transport_delivery_schedule_alloted_id}
            detailsPageLink={row.detailsPageLink}
            urlFilters={row.urlFilters}
            pageNo={row.pageNo}
          />
          <div>
            {row.is_eway_bill_mandate ? <Tooltip title="E-Way Bill is required"><img src={EwayBill} className="ml-1" style={{ width: '18px', height: '18px' }} /></Tooltip> : ''}
            {/* {!loc.includes('controltower') && row?.early_payment_discount && <Popover placement="top" content={<span>{row?.early_payment_metadata_id ? 'You have availed early payment on this deal' : 'Get Payment On Delivery at just'} {row?.early_payment_metadata_id ? '' :<span style={{color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold'}}>₹{row?.early_payment_discount}/MT</span>}</span>} trigger="hover">
              <img src={row?.early_payment_metadata_id ? OfferAvailedIcon : OfferIcon} className={row?.early_payment_metadata_id ? 'ml-2' : "pulse-animation ml-2"} style={{ width: '24px', height: '24px' }} />
            </Popover>} */}
            {(row?.is_own_transport && loc.includes('/network/deliveries') && row?.early_payment_discount && !row?.early_payment_metadata_id && row.is_seller) ?
              <Popover
                placement="top"
                content={<span>Get Payment On Delivery at just <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.early_payment_discount}/{row?.price_uom_code}</span></span>}
                trigger="hover">
                <img
                  src={OfferIcon}
                  className={"pulse-animation ml-1 cursor-pointer green-offer-icon"}
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => row?.is_early_payment_availed_by_deal ? {} : (setEarlyPayment(true), setSelectedDeal(row), setEarlyPaymentDiscount(row?.early_payment_discount))}
                />
              </Popover> : ''}
            {(!loc.includes('/network/deliveries') && row?.early_payment_discount && !row?.early_payment_metadata_id && row.is_seller) ?
              <Popover
                placement="top"
                content={<span>Get Payment On Delivery at just <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.early_payment_discount}/{row?.price_uom_code}</span></span>}
                trigger="hover">
                <img
                  src={OfferIcon}
                  className={"pulse-animation ml-1 cursor-pointer green-offer-icon"}
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => row?.is_early_payment_availed_by_deal ? {} : (setEarlyPayment(true), setSelectedDeal(row), setEarlyPaymentDiscount(row?.early_payment_discount))}
                />
              </Popover> : ''}
            {(row?.is_own_transport && loc.includes('/network/deliveries') && row?.early_payment_metadata_id && row?.is_early_payment_applicable && !row?.is_ep_activated_by_ct) ?
              <Popover
                placement="top"
                content={<span>{`${row?.is_seller ? 'You have' : 'Seller has'} availed early payment on this deal.`} <br /><span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'normal', fontSize: 'var(--fs-base__three)' }}>Pay On Delivery* at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.price_uom_code}</span></span></span>}
                trigger="hover">
                <img
                  src={OfferAvailedIcon}
                  className={"ml-1 cursor-pointer orange-offer-icon"}
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => {
                    setSelectedDeal(row)
                    const isEarlyToggleEnable = row?.delivery_allotment_status_enum_code === 'Confirmed' || row?.delivery_allotment_status_enum_code === 'Scheduled' || row?.delivery_phase_enum_code === 'Trip Started' || row?.delivery_phase_enum_code === 'In Transit For Load' || row?.delivery_phase_enum_code === 'At Loading Location'
                    if (isEarlyToggleEnable && row.is_seller) {
                      setEarlyPayment(true)
                    }
                  }}
                />
              </Popover> : ''}
            {(!loc.includes('/network/deliveries') && row?.early_payment_metadata_id && row?.is_early_payment_applicable && (!row?.is_ep_activated_by_ct || loc.includes(`commodity/deals/${row?.commodity_deal_master_id}/deliveries`))) ?
              <Popover
                placement="top"
                content={<span>{`${row?.is_seller ? 'You have' : 'Seller has'} availed early payment on this deal.`}<span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'normal', display: 'block', }}>Pay On Delivery* at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.price_uom_code}</span></span></span>}
                trigger="hover">
                <img
                  src={OfferAvailedIcon}
                  className={"ml-1 cursor-pointer orange-offer-icon"}
                  style={{ width: '24px', height: '24px' }}
                  onClick={() => {
                    setSelectedDeal(row)
                    const isEarlyToggleEnable = row?.delivery_allotment_status_enum_code === 'Confirmed' || row?.delivery_allotment_status_enum_code === 'Scheduled' || row?.delivery_phase_enum_code === 'Trip Started' || row?.delivery_phase_enum_code === 'In Transit For Load' || row?.delivery_phase_enum_code === 'At Loading Location'
                    if (isEarlyToggleEnable && row.is_seller) {
                      setEarlyPayment(true)
                    }
                  }}
                />
              </Popover> : ''}
            {(loc.includes('controltower/deliveries') || loc.includes(`control-tower/deals/${row?.commodity_deal_master_id}/deliveries`)) && isControlTowerUser && row?.is_biofuelcircle_buyer && !row?.early_payment_metadata_id && !row?.is_ep_activated_by_ct ?
              <Popover
                placement="top"
                content={<span>Get Payment On Delivery at just <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.early_payment_discount}/{row?.price_uom_code}</span></span>}
                trigger="hover"
              >
                <AllowEarlyPaymentIcon onClick={() => { setAllowEarlyPayment(true); setSelectedDeal(row) }} className='ml-1 cursor-pointer gray-offer-icon' style={{ width: '24px', height: '24px' }} />
              </Popover>
              : ''}
            {(loc.includes('controltower/deliveries') || loc.includes(`control-tower/deals/${row?.commodity_deal_master_id}/deliveries`)) && isControlTowerUser && row?.is_biofuelcircle_buyer && !row?.early_payment_metadata_id && row?.is_ep_activated_by_ct ?
              <Popover
                placement="top"
                // content={<span>Early payment explicitly activated for this delivery.</span>}
                content={<span>Enabled early payment at <span style={{ color: '#32a836', fontSize: '16px', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span>}
                trigger="hover"
              >
                <EarlyPaymentAllowedIcon className='ml-1 cursor-pointer' style={{ width: '24px', height: '24px' }} />
              </Popover>
              : ''}
            {(loc.includes('controltower/deliveries') || loc.includes(`control-tower/deals/${row?.commodity_deal_master_id}/deliveries`)) && isControlTowerUser && row?.is_biofuelcircle_buyer && row?.is_ep_activated_by_ct && row?.early_payment_metadata_id ?
              <Popover
                placement="top"
                content={<span>{`Seller has availed early payment on this delivery with the help of BiofuelCircle`} <br /><span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'normal', fontSize: 'var(--fs-base__three)' }}>Pay On Delivery* at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold', letterSpacing: 0.5 }}>₹{row?.early_payment_discount}/{row?.transport_uom_code}</span></span></span>}
                trigger="hover"
              >
                <img src={EarlyPaymentAvailedUsingCTIcon} className='ml-1 cursor-pointer' style={{ width: '24px', height: '24px' }} />
              </Popover>
              : ''}
          </div>
        </div>
      ),
      sorter: (a, b) => a.transport_delivery_schedule_id - b.transport_delivery_schedule_id,
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'transport_delivery_schedule_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={transport_delivery_schedule_id}
          name={'Schedule Id'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'transport_delivery_schedule_id'),
      filteredValue:
        transport_delivery_schedule_id !== undefined ? transport_delivery_schedule_id : null,
    },
    {
      title: 'Deal No',
      dataIndex: 'commodity_deal_master_id',
      key: 'commodity_deal_master_id',
      width: '110px',
      sorter: (a, b) => a.commodity_deal_master_id - b.commodity_deal_master_id,
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row?.deal_type_enum === 2 ? row?.custom_deal_no : row?.commodity_deal_master_id}
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'commodity_deal_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_deal_master_id}
          name={'Deal No'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'commodity_deal_master_id'),
      filteredValue: commodity_deal_master_id !== undefined ? commodity_deal_master_id : null,
    },
    ...(isControlTowerUser && loc.includes('/controltower') && !loc.includes('not-confirmed')
      ? [
        {
          title: 'Secured',
          dataIndex: 'is_secured',
          width: '80px',
          align: 'center',
          render: (text, row) => (
            <div className="bfc-table-list-components text-center">
              <p>
                {row.is_secured ? (
                  <img src={SecuredTransport} className="" style={{ width: '30px', height: '30px' }} />
                ) : null}
              </p>
            </div>
          ),
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'is_secured'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={is_secured}
              name={'Secured'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'is_secured'),
          filteredValue: is_secured !== undefined ? is_secured : null,
        },
      ]
      : []),
    ...(loc.includes('/market/deliveries') ||
      loc.includes('/controltower/deliveries') ||
      loc.includes('/controltower/delivery-contract') ||
      loc.includes('/commodity/deals') ||
      loc.includes('/control-tower/deals') ||
      loc.includes('/network/deliveries')
      ? [
        {
          title: 'Transporter',
          dataIndex: 'is_own_transport',
          key: 'is_own_transport',
          width: '130px',
          align: 'left',
          // sorter: (a, b) => a.is_own_transport - b.is_own_transport,
          render: (text, row) => (
            <div className="bfc-table-list-components text-left">
              {/* {row.is_own_transport ? 'Yes' : 'No'} */}
              {row?.is_own_transport ? (row?.consignee_id === (row?.transporter_id || row?.transport_company_id) ? <img src={BFCBuyerTransport} style={{ height: 45, width: 45 }} /> : row?.consignor_id === (row?.transporter_id || row?.transport_company_id) ? <img src={BFCSellerTransport} style={{ height: 45, width: 45 }} /> : '') : ''}
              {row?.is_own_transport ? ((row?.delivery_allotment_status_enum_code == "Buyer to Confirm") ? <img src={BFCBuyerTransport} style={{ height: 45, width: 45 }} /> : <img src={BFCSellerTransport} style={{ height: 45, width: 45 }} />) : <img src={BioFuelCircleTransport} style={{ height: 45, width: 45 }} />}
              {(row?.is_transporter_icon_visible || loc.includes('/control-tower/deals')) && (row?.isowntobiofuel_is_delivery_eligible_for_biofuelcircle_transport || row?.isowntobiofuel_is_converted_to_bfc_transport)
                ? <Popover
                  placement="top"
                  content={row?.isowntobiofuel_is_converted_to_bfc_transport ? <span>Converted To BiofuelCircle Transport</span> : <span>Get BiofuelCircle Transport at <span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>₹{row?.isowntobiofuel_transport_rate}/{row?.transport_uom_code}</span></span>}
                  trigger="hover">
                  <LocalOfferIcon className={`${row?.isowntobiofuel_is_converted_to_bfc_transport ? '' : 'pulse-animation'} ml-2 cursor-pointer ${row?.isowntobiofuel_is_converted_to_bfc_transport ? `yellow-transport-icon` : `green-transport-icon`}`} color='primary' style={{ color: row?.isowntobiofuel_is_converted_to_bfc_transport ? 'var(--unnamed-color-F1B043)' : 'var(--unnamed-color-90d632)' }} onClick={row?.isowntobiofuel_is_converted_to_bfc_transport ? '' : () => {
                    setSelectedDealForTransportConversion(row)
                    setIsConvertOwnToBFCTransport(true)
                  }} />
                </Popover>
                : ''
              }
            </div>
          ),
          // filterDropdown: (props) => (
          //   <AutoCompleteFilter
          //     dataIndex={'is_own_transport'}
          //     data={props}
          //     onFilterChange={onFilterChange}
          //     handleReset={handleReset}
          //     value={is_own_transport}
          //     name={'Pick up Location'}
          //   />
          // ),
          // filterIcon: (filtered) => getFilteredIcon(filtered, 'is_own_transport'),
          // defaultFilteredValue: is_own_transport !== undefined ? is_own_transport : null,
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'transporter_against_own_deals'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={transporter_id}
              name={'Transporter'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'transporter_id'),
          filteredValue: transporter_id !== undefined ? transporter_id : null,
        },
      ]
      : []),
    ...!loc.includes('not-confirmed') ? [
      {
        title: 'Transport No',
        dataIndex: 'transport_deal_master_id',
        key: 'transport_deal_master_id',
        width: '120px',
        align: 'center',
        sorter: (a, b) => a.transport_deal_master_id - b.transport_deal_master_id,
        // filterDropdown: (props) => (
        //   <NumericSearchFilter
        //     dataIndex={'transport_delivery_schedule_alloted_id'}
        //     data={props}
        //     onFilterChange={onFilterChange}
        //     handleReset={handleReset}
        //     value={transport_delivery_schedule_alloted_id}
        //     name={'Delivery No'}
        //   />
        // ),
        // filterIcon: (filtered) => getSearchIcon(filtered, 'transport_delivery_schedule_alloted_id'),
        // filteredValue:
        //   transport_delivery_schedule_alloted_id !== undefined ? transport_delivery_schedule_alloted_id : null,
      },
    ] : [],
    // {
    //   title: 'Transport No',
    //   dataIndex: 'transport_deal_master_id',
    //   key: 'transport_deal_master_id',
    //   width: '120px',
    //   align: 'center',
    //   sorter: (a, b) => a.transport_deal_master_id - b.transport_deal_master_id,
    //   // filterDropdown: (props) => (
    //   //   <NumericSearchFilter
    //   //     dataIndex={'transport_delivery_schedule_alloted_id'}
    //   //     data={props}
    //   //     onFilterChange={onFilterChange}
    //   //     handleReset={handleReset}
    //   //     value={transport_delivery_schedule_alloted_id}
    //   //     name={'Delivery No'}
    //   //   />
    //   // ),
    //   // filterIcon: (filtered) => getSearchIcon(filtered, 'transport_delivery_schedule_alloted_id'),
    //   // filteredValue:
    //   //   transport_delivery_schedule_alloted_id !== undefined ? transport_delivery_schedule_alloted_id : null,
    // },
    {
      title: 'Pick up Date',
      dataIndex: 'pickup_datetime',
      key: 'pickup_datetime',
      width: '130px',
      align: 'center',
      sorter: (a, b) => new Date(a.pickup_datetime) - new Date(b.pickup_datetime),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">{dayjs(row.pickup_datetime).format('DD-MMM-YYYY')}</div>
      ),
      // <DueDateField closing_on={row.pickup_datetime} />,
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'pickup_datetime'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={pickup_time}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'pickup_time'),
      defaultFilteredValue: pickup_time !== undefined ? pickup_time : null,
    },

    {
      title: 'Pick up Location',
      dataIndex: 'pickup_location',
      key: 'pickup_location',
      width: '200px',
      align: 'left',
      render: (text, row) => (
        <>
          <Popover
            content={row.pickup_location}
            placement="topLeft"
          >
            <div className="bfc-table-list-components text-left">
              <p className='multiline-ellipsis text-theme-dark'>
                {row.pickup_location}
              </p>
            </div>
          </Popover>
        </>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'hbs_pickup_location'}
          delivery_split_enum={4}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={base_location_code}
          name={'Pick up Location'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'base_location_code'),
      defaultFilteredValue: base_location_code !== undefined ? base_location_code : null,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      key: 'consignor',
      width: '150px',
      align: 'left',
      render: (text, row) => (
        <>
          <div className="bfc-table-list-components text-left">
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <Popover
                  content={row.consignor}
                  placement='topLeft'
                >
                  <div>
                    <p className='ellipsis-text text-theme-dark'>
                      {row.consignor}
                    </p>
                  </div>
                </Popover>
                {row?.seller_mobile_number ? <div>
                  ({row?.seller_mobile_number})
                </div> : ''}
              </div>
              {row?.is_offerer_bank_verified ? <Popover content={`Bank details verified`}><CheckCircleIcon className="ml-1" style={{ color: 'var(--unnamed-color-00ae11)' }} /></Popover> : row?.is_offerer_bank_verified === false ? <Popover content={`Bank details not verified`}><CancelIcon className="ml-1" style={{ color: 'var(--unnamed-color-f44336)' }} /></Popover> : ''}
            </div>
          </div>
        </>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'delivery_consignor'}
          delivery_split_enum={4}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={consignor_id}
          name={'Consignor'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'consignor_id'),
      defaultFilteredValue: consignor_id !== undefined ? consignor_id : null,
    },
    {
      title: 'Delivery',
      dataIndex: 'delivery_location',
      key: 'delivery_location',
      width: '150px',
      align: 'left',
      render: (text, row) => (
        <>
          <Popover
            content={row.delivery_location}
            placement='topLeft'
          >
            <div className="bfc-table-list-components text-left">
              <p className='multiline-ellipsis text-theme-dark'>
                {row.delivery_location}
              </p>
            </div>
          </Popover>
        </>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'hbs_delivery_location'}
          delivery_split_enum={4}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={delivery_location_code}
          name={'Delivery'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'delivery_location_code'),
      defaultFilteredValue: delivery_location_code !== undefined ? delivery_location_code : null,
    },
    {
      title: 'Consignee',
      dataIndex: 'consignee',
      key: 'consignee',
      width: '150px',
      align: 'left',
      render: (text, row) => (
        <>
          <Popover
            content={row.consignee}
            placement='topLeft'
          >
            <div className="bfc-table-list-components text-left">
              <p className='multiline-ellipsis text-theme-dark'>
                {row.consignee}
              </p>
            </div>
          </Popover>
        </>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'delivery_consignee'}
          delivery_split_enum={4}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={consignee_id}
          name={'Consignee'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'consignee_id'),
      defaultFilteredValue: consignee_id !== undefined ? consignee_id : null,
    },
    ...((loc.includes('upcoming') || loc.includes('commodity/deals')) ? [{
      title: 'Quantity',
      dataIndex: 'estimated_km',
      key: 'estimated_km',
      width: '120px',
      align: 'right',
      render: (text, row) => (
        <div className="bfc-table-list-components text-right">
          {row?.scheduled_qty ? `${row?.scheduled_qty} ${row?.qty_uom_code}` : ''}
        </div>
      ),
    }] : []),
    {
      title: 'KM & time',
      dataIndex: 'estimated_km',
      key: 'estimated_km',
      width: '120px',
      align: 'right',
      render: (text, row) => (
        <div className="bfc-table-list-components text-right">
          {row.estimated_km} <br /> {row.estimated_time}
        </div>
      ),
    },
    ...!loc.includes('not-confirmed') ? [
      {
        title: 'Vehicle',
        dataIndex: 'vehicle',
        key: 'vehicle',
        width: '100px',
        align: 'right',
        render: (text, row) => (
          <div className="bfc-table-list-components text-right">{row.is_walk_in ? '-' : row.vehicle}</div>
        ),
        filterDropdown: (props) => (
          <AutoCompleteFilter
            dataIndex={'vehicle'}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={vehicle_id}
            name={'Vehicle'}
          />
        ),
        filterIcon: (filtered) => getFilteredIcon(filtered, 'vehicle_id'),
        defaultFilteredValue: vehicle_id !== undefined ? vehicle_id : null,
      },
      {
        title: 'Vehicle Size',
        dataIndex: 'vehicle_type_id_code',
        key: 'vehicle_type_id_code',
        width: '90px',
        align: 'right',
        render: (text, row) => {
          let qty = loc.includes('/deals') ? row.vehicle_type_id_code : row.vehicle_type_code
          return row.is_walk_in ? (
            '-'
          ) : (
            <div className="bfc-table-list-components d-flex justify-content-end">
              <span style={{ color: 'var(--unnamed-color-707070)' }}>{qty}</span>
            </div>
            // <QuantityField qty={loc.includes('/deals') ? row.vehicle_type_id_code : row.vehicle_type_code} periodicity_enum_code={'Once'} qty_uom_code={row.vehicle_type_code?.includes('KL') ? 'KL' : 'MT'} />
          );
        },
      },
      {
        title: 'Driver',
        dataIndex: 'driver',
        key: 'driver',
        width: '90px',
        align: 'left',
        render: (text, row) => (
          <div className="bfc-table-list-components text-left">{row.is_walk_in ? '-' : row.driver}</div>
        ),
        filterDropdown: (props) => (
          <AutoCompleteFilter
            dataIndex={'driver'}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={driver_id}
            name={'Driver'}
          />
        ),
        filterIcon: (filtered) => getFilteredIcon(filtered, 'driver_id'),
        defaultFilteredValue: driver_id !== undefined ? driver_id : null,
      },
    ] : [],
    // {
    //   title: 'Vehicle',
    //   dataIndex: 'vehicle',
    //   key: 'vehicle',
    //   width: '100px',
    //   align: 'right',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-right">{row.is_walk_in ? '-' : row.vehicle}</div>
    //   ),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'vehicle'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={vehicle_id}
    //       name={'Vehicle'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'vehicle_id'),
    //   defaultFilteredValue: vehicle_id !== undefined ? vehicle_id : null,
    // },
    // {
    //   title: 'Vehicle Size',
    //   dataIndex: 'vehicle_type_id_code',
    //   key: 'vehicle_type_id_code',
    //   width: '90px',
    //   align: 'right',
    //   render: (text, row) => {
    //     let qty = loc.includes('/deals') ? row.vehicle_type_id_code : row.vehicle_type_code
    //     return row.is_walk_in ? (
    //       '-'
    //     ) : (
    //       <div className="bfc-table-list-components d-flex justify-content-end">
    //         <span style={{ color: 'var(--unnamed-color-707070)' }}>{qty}</span>
    //       </div>
    //       // <QuantityField qty={loc.includes('/deals') ? row.vehicle_type_id_code : row.vehicle_type_code} periodicity_enum_code={'Once'} qty_uom_code={row.vehicle_type_code?.includes('KL') ? 'KL' : 'MT'} />
    //     );
    //   },
    // },
    // {
    //   title: 'Driver',
    //   dataIndex: 'driver',
    //   key: 'driver',
    //   width: '90px',
    //   align: 'left',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-left">{row.is_walk_in ? '-' : row.driver}</div>
    //   ),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'driver'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={driver_id}
    //       name={'Driver'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'driver_id'),
    //   defaultFilteredValue: driver_id !== undefined ? driver_id : null,
    // },
  ];
  if (loc.includes('not-confirmed')) {
    DELIVERIES_TABLE_HEADERS.push(
      {
        title: 'Qty',
        dataIndex: 'qty',
        width: '150px',
        align: 'right',
        render: (text, row) => {
          return row.final_delivery_qty ? (
            <QuantityField
              qty={row.final_delivery_qty}
              periodicity_enum_code={'Once'}
              qty_uom_code={row.final_delivery_qty ? row.qty_uom_code || 'MT' : ''}
            />
          ) : (
            <div className="bfc-table-list-components text-center">{'-'}</div>
          );
        },
      },
    );
  }
  if (loc.includes('missed')) {
    DELIVERIES_TABLE_HEADERS.push(
      {
        title: 'Scheduled Qty',
        dataIndex: 'scheduled_qty',
        width: '150px',
        align: 'right',
        render: (text, row) => {
          return row.scheduled_qty ? (
            <QuantityField
              qty={row.scheduled_qty}
              periodicity_enum_code={'Once'}
              qty_uom_code={row.scheduled_qty ? row.qty_uom_code || 'MT' : ''}
            />
          ) : (
            <div className="bfc-table-list-components text-center">{'-'}</div>
          );
        },
      },
      {
        title: 'Missed Qty',
        dataIndex: 'missed_qty',
        width: '150px',
        align: 'right',
        render: (text, row) => {
          return row.missed_qty ? (
            <QuantityField
              qty={row.missed_qty}
              periodicity_enum_code={'Once'}
              qty_uom_code={row.missed_qty ? row.qty_uom_code || 'MT' : ''}
            />
          ) : (
            <div className="bfc-table-list-components text-center">{'-'}</div>
          );
        },
      },
    );
  }

  if (loc.includes('ongoing') || loc.includes('completed')) {
    DELIVERIES_TABLE_HEADERS.push(
      {
        title: 'Loading Ack Date',
        dataIndex: 'loading_acknowledgement_datetime',
        key: 'loading_acknowledgement_datetime',
        width: '160px',
        align: 'center',
        sorter: (a, b) => new Date(a.loading_acknowledgement_datetime) - new Date(b.loading_acknowledgement_datetime),
        render: (text, row) => <DueDateField closing_on={row.loading_acknowledgement_datetime} />,
        filterDropdown: (props) => (
          <RangeDateSearchFilter
            dataIndex={'loading_acknowledgement_datetime'}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={goodsloadedacknowledgement_timestamp}
          />
        ),
        filterIcon: (filtered) => getDateIcon(filtered, 'goodsloadedacknowledgement_timestamp'),
        defaultFilteredValue:
          goodsloadedacknowledgement_timestamp !== undefined ? goodsloadedacknowledgement_timestamp : null,
      },
      {
        title: 'Delivery Ack Date',
        dataIndex: 'delivery_acknowledgement_datetime',
        key: 'delivery_acknowledgement_datetime',
        width: '170px',
        align: 'center',
        sorter: (a, b) => new Date(a.delivery_acknowledgement_datetime) - new Date(b.delivery_acknowledgement_datetime),
        render: (text, row) => <DueDateField closing_on={row.delivery_acknowledgement_datetime} />,
        filterDropdown: (props) => (
          <RangeDateSearchFilter
            dataIndex={'delivery_acknowledgement_datetime'}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={goodsdeliveredacknowledgement_timestamp}
          />
        ),
        filterIcon: (filtered) => getDateIcon(filtered, 'goodsdeliveredacknowledgement_timestamp'),
        defaultFilteredValue:
          goodsdeliveredacknowledgement_timestamp !== undefined ? goodsdeliveredacknowledgement_timestamp : null,
      },
      {
        title: 'Net Qty at Loading',
        dataIndex: 'net_quantity_at_loading',
        width: '150px',
        align: 'right',
        render: (text, row) => {
          return row.net_quantity_at_loading ? (
            <QuantityField
              qty={row.net_quantity_at_loading}
              periodicity_enum_code={'Once'}
              qty_uom_code={row.net_quantity_at_loading ? row.qty_uom_code || 'MT' : ''}
            />
          ) : (
            <div className="bfc-table-list-components text-center">{'-'}</div>
          );
        },
      },
      {
        title: 'Net Qty at Delivery',
        dataIndex: 'net_quantity_at_delivery',
        width: '150px',
        align: 'right',
        render: (text, row) => {
          return row.net_quantity_at_delivery ? (
            <QuantityField
              qty={row.net_quantity_at_delivery}
              periodicity_enum_code={'Once'}
              qty_uom_code={row.net_quantity_at_delivery ? row.qty_uom_code || 'MT' : ''}
            />
          ) : (
            <div className="bfc-table-list-components text-right">{'-'}</div>
          );
        },
      },
      ...(loc.includes('completed')
        ? [
          {
            title: 'Rejection Reason',
            dataIndex: 'rejection_reason_type_enum_code',
            key: 'rejection_reason_type_enum_code',
            width: '150px',
            align: 'center',
            render: (text, row) => (
              <div className="bfc-table-list-components text-center">{row?.rejection_reason_type_enum_code}</div>
            ),
          },
        ]
        : []),
      {
        title: 'Status',
        dataIndex: 'delivery_allotment_status_enum_code',
        key: 'delivery_allotment_status_enum_code',
        width: '170px',
        align: 'center',
        render: (text, row) => (
          <span>
            {text == 'Buyer to Confirm' && (
              <Tag color={'geekblue'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Rejected' && (
              <Tag color={'red'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'CT To Confirm' && (
              <Tag color={'geekblue'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {(text == 'Confirmed' || text == 'CloseOut') && (
              <Tag color={'blue'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {(text === 'Closed' || text === 'Close Out') && (
              <Tag color={'volcano'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Scheduled' && (
              <Tag color={'magenta'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Delivered' && (
              <Tag color={'green'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {(text == 'Trip Started' ||
              text == 'In Transit For Load' ||
              text == 'At Loading Location' ||
              text == 'Goods Loaded' ||
              text == 'In Transit For Delivery' ||
              text == 'At Delivery Location' ||
              text == 'Goods Unloaded' ||
              text == 'Trip Ended') && (
                <Tag color={'purple'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
          </span>
        ),
        filterDropdown: (props) => (
          <MultiSelectFilter
            dataIndex={'delivery_allotment_status_enum_code'}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={delivery_allotment_status_enum}
            name={'Status'}
          />
        ),
        filterIcon: (filtered) => getFilteredIcon(filtered, 'delivery_allotment_status_enum'),
        defaultFilteredValue: delivery_allotment_status_enum !== undefined ? delivery_allotment_status_enum : null,
      },
      {
        title: '',
        dataIndex: 'address_text_',
        width: '90px',
        align: 'center',
        render: (value, row, index) => {
          const showFlag =
            loc.includes('network/deliveries/upcoming') ||
            loc.includes('controltower/deliveries/upcoming') ||
            loc.includes('network/deliveries/ongoing') ||
            loc.includes('controltower/deliveries/ongoing') ||
            loc.includes('network/deliveries/missed') ||
            loc.includes('controltower/deliveries/missed');
          const obj = {
            children: <AntTableEditFunction row={row} id={row.id} cancel={cancel} showFlag={showFlag} />,
            props: {},
          };
          if ((CT_parent && (loc.includes('controltower/deliveries/upcoming') || loc.includes('controltower/deliveries/ongoing') || loc.includes('controltower/deliveries/missed'))) ||
            (NetworkAccess && (loc.includes('network/deliveries/upcoming') || loc.includes('network/deliveries/ongoing') || loc.includes('network/deliveries/missed')))
          ) {
            return obj
          } else {
            return null
          }
        },
      },
      ...(loc.includes('completed') && loc.includes('controltower/deliveries/completed')
        ? [
          {
            title: 'Action',
            width: '80px',
            align: 'center',
            render: (text, row) => (
              <>
                {CT_parent && (
                  <AntTableActions
                    showFlag={row?.delivery_allotment_status_enum_code !== 'Rejected'}
                    controls={[
                      {
                        name: 'Reject',
                        onClick: () => handelRejectClick(row),
                        controlColor: 'var(--unnamed-color-1890ff)',
                      },
                    ]}
                  />
                )}
              </>

            ),
          },
        ]
        : []),
    );
  } else {
    DELIVERIES_TABLE_HEADERS.push(
      {
        title: 'Status',
        dataIndex: 'delivery_allotment_status_enum_code',
        key: 'delivery_allotment_status_enum_code',
        width: '150px',
        align: 'center',
        render: (text, row) => (
          <span>
            {(text == 'Buyer to Confirm' || text == 'Seller to Confirm' || text == 'Contract') && (
              <Tag color={'geekblue'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Rejected' && (
              <Tag color={'red'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'CT To Confirm' && (
              <Tag color={'geekblue'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {(text == 'Confirmed' || text == 'CloseOut') && (
              <Tag color={'blue'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {(text === 'Closed' || text === 'Close Out') && (
              <Tag color={'volcano'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Scheduled' && (
              <Tag color={'magenta'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Delivered' && (
              <Tag color={'green'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {(text == 'Trip Started' ||
              text == 'In Transit For Load' ||
              text == 'At Loading Location' ||
              text == 'Goods Loaded' ||
              text == 'In Transit For Delivery' ||
              text == 'At Delivery Location' ||
              text == 'Goods Unloaded' ||
              text == 'Trip Ended') && (
                <Tag color={'purple'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
          </span>
        ),
        filterDropdown: (props) => (
          <AutoCompleteFilter
            dataIndex={'delivery_allotment_status_enum_code'}
            delivery_split_enum={4}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={delivery_allotment_status_enum}
            name={'Status'}
          />
          //   <MultiSelectFilter
          //     dataIndex={'delivery_allotment_status_enum_code'}
          //     data={props}
          //     onFilterChange={onFilterChange}
          //     handleReset={handleReset}
          //     value={delivery_allotment_status_enum}
          //     name={'Status'}
          //   />
        ),
        filterIcon: (filtered) => getFilteredIcon(filtered, 'delivery_allotment_status_enum'),
        defaultFilteredValue: delivery_allotment_status_enum !== undefined ? delivery_allotment_status_enum : null,
      },
      ...(loc.includes('not-confirmed') && loc.includes('controltower/deliveries/not-confirmed')
        ? [
          {
            title: '',
            width: '80px',
            align: 'center',
            // render: (text, row) => (
            //   <>
            //     {CT_parent && (
            //       // <AntTableActions
            //       //   showFlag={row?.delivery_allotment_status_enum_code !== 'Rejected'}
            //       //   controls={[
            //       //     {
            //       //       name: 'Reject',
            //       //       onClick: () => handelRejectClick(row),
            //       //       controlColor: 'var(--unnamed-color-1890ff)',
            //       //     },
            //       //   ]}
            //       <>
            //         <span style={{ color: 'var(--unnamed-color-1890ff)', cursor: 'pointer' }}
            //           onClick={() => handleEditNotConfirmDelivery(row)}
            //         >Edit</span>
            //         &nbsp;
            //         <span style={{ color: 'var(--unnamed-color-1890ff)', cursor: 'pointer' }}
            //           onClick={() => {
            //             setHandler(() => handleDeleteDeliveries);
            //             setModalValue(row?.id);
            //             setModalFormikBag(null);
            //             setModalTitle('Delete Schedule(s)');
            //             setModal(true);
            //           }}
            //         >Delete</span>
            //       </>
            //     )}
            //   </>

            // ),
            render: (value, row, index) => {
              const obj = {
                children: (
                  CT_parent && (<AntTableActions
                    row={row}
                    showFlag={true}
                    controls={[
                      {
                        name: 'Edit',
                        onClick: () => {
                          handleEditNotConfirmDelivery(row)
                        },
                        controlColor: 'var(--unnamed-color-1b62ab)',
                      },
                      // {
                      //   name: 'Delete',
                      //   onClick: () => {
                      //     setHandler(() => handleDeleteDeliveries);
                      //     setModalValue(row?.id);
                      //     setModalFormikBag(null);
                      //     setModalTitle('Delete Schedule(s)');
                      //     setModal(true);
                      //   },
                      //   controlColor: 'var(--unnamed-color-f44336)',
                      // },
                    ]}
                  />
                  ))
              }
              return obj
            }
          },
        ]
        : []),
      // {
      //   title: '',
      //   dataIndex: 'address_text_',
      //   width: '70px',
      //   align: 'center',
      //   render: (value, row, index) => {
      //     const showFlag =
      //       loc.includes('network/deliveries/upcoming') ||
      //       loc.includes('controltower/deliveries/upcoming') ||
      //       loc.includes('network/deliveries/ongoing') ||
      //       loc.includes('controltower/deliveries/ongoing') ||
      //       loc.includes('network/deliveries/missed') ||
      //       loc.includes('controltower/deliveries/missed');
      //     const obj = {
      //       children: <AntTableEditFunction row={row} id={row.id} cancel={cancel} showFlag={showFlag} />,
      //       props: {},
      //     };
      //     if (NetworkAccess) {
      //       return obj;
      //     } else {
      //       return null;
      //     }
      //   },
      // },
    );
  }

  const handelRejectClick = (deliveryData) => {
    setIsOpen(true);
    setDeliveryData(deliveryData);
    const rejectedByOptions = [
      {
        company_id: deliveryData?.buyer_company_id,
        person_id: deliveryData?.buyer_person_id,
        text: deliveryData?.buyer_person_code,
      },
      {
        company_id: deliveryData?.seller_company_id,
        person_id: deliveryData?.seller_person_id,
        text: deliveryData?.seller_person_code,
      },
    ];
    setDealRejectedByOptions(rejectedByOptions);
  };

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  let tableData = busy ? [] : Object.values(deliveriesList || {});
  let tableData1 = busy ? [] : Object.values(deliveriesList || {});
  // if (
  //   loc.includes('/controltower/deliveries') && isControlTowerUser
  // ) {
  //   tableData = busyCT ? [] : Object.values(deliveriesCTList || {});
  // } else
  if (loc.includes('/controltower/delivery-contract') || loc.includes('network/transport-deal')) {
    tableData = busyDC ? [] : Object.values(deliveriesContractList || {});
  }
  let reportData = busy ? [] : Object.values(GenerateReportAllData.entities || {}).map(item => ({ ...item, 'Linked Delivery Id': item?.['Linked Delivery Id'] ? item?.['Linked Delivery Id']?.split(',')?.join('|') : '', 'Linked Deal Id': item?.['Linked Deal Id'] ? item?.['Linked Deal Id']?.split(',')?.join('|') : '' }));
  if (reportData.length > 0) {
    const updatedData = reportData?.map((item) => {
      let data = { ...item };
      delete data['pickup_village_id'];
      delete data['pickup_taluka_id'];
      delete data['pickup_district_id'];
      delete data['pickup_state_id'];
      delete data['pickup_country_id'];
      delete data['delivery_village_id'];
      delete data['Early Payment Discount'];
      delete data['Early Payment Status'];
      delete data['Linked Deal Id'];
      delete data['Linked Delivery Id'];
      return {
        ...data,
      };
    });
    reportData = updatedData;
  }
  let reportDataRegular = busy ? [] : Object.values(GenerateReportAllDataRegular.entities || {}).map(item => ({ ...item, 'Linked Delivery Id': item?.['Linked Delivery Id'] ? item?.['Linked Delivery Id']?.split(',')?.join('|') : '', 'Linked Deal Id': item?.['Linked Deal Id'] ? item?.['Linked Deal Id']?.split(',')?.join('|') : '' }));
  if (reportDataRegular.length > 0) {
    const updatedData = reportDataRegular?.map((item) => {
      let data = { ...item };
      delete data['pickup_village_id'];
      delete data['pickup_taluka_id'];
      delete data['pickup_district_id'];
      delete data['pickup_state_id'];
      delete data['pickup_country_id'];
      delete data['delivery_village_id'];
      delete data['Early Payment Discount'];
      delete data['Early Payment Status'];
      delete data['Linked Deal Id'];
      delete data['Linked Delivery Id'];
      return {
        ...data,
      };
    });
    reportDataRegular = updatedData;
  }
  const encodeBackUrl = utils.encodeBackUrl();
  //   tableData = tableData.map((d) => {
  //     return {
  //       ...d,
  //       delivery_date_sorted: dayjs(d['pickup_datetime']).unix(),
  //       id: parseInt(d['transport_delivery_schedule_allotment_id']),
  //       key: parseInt(d['transport_delivery_schedule_alloted_id']),
  //       pickup_datetime: d.pickup_datetime ? utils.formatDateTime(d.pickup_datetime) : '-',
  //       pickup_date_time: dayjs(d['pickup_datetime']).unix(),
  //       detailsPageLink: `/deliveries/${d.transport_delivery_schedule_alloted_id}/overview?${loc.includes('/market/deliveries') || loc.includes('/commodity/deals') ? 'is_market=true' : loc.includes('/control-tower/deals') ? 'isControlTowerDealDelivery=true' : loc.includes('/network/deliveries') ? 'is_network=true' : ''}&${d?.deal_type_enum === 2 ? 'isStockTransfer=true&' : ''}back=${encodeBackUrl}${loc.includes('controltower/deliveries') ? '&isCtDelivery=true' : ''}`,
  //       setEditDeliveryId: setEditDeliveryId,
  //       handelRejectClick: handelRejectClick,
  //     };
  //   });
  tableData1 = tableData1.map((d) => {
    return {
      ...d,
      delivery_date_sorted: dayjs(d['pickup_datetime']).unix(),
      id: parseInt(d['transport_delivery_schedule_id']),
      key: parseInt(d['transport_delivery_schedule_id']),
      pickup_datetime: d.pickup_datetime ? utils.formatDateTime(d.pickup_datetime) : '-',
      pickup_date_time: dayjs(d['pickup_datetime']).unix(),
      detailsPageLink: getUrlWithEncryptedQueryParams(`/deliveries/${d.transport_delivery_schedule_id}/overview?${loc.includes('/market/deliveries') || loc.includes('/commodity/deals') ? 'is_market=true' : loc.includes('/control-tower/deals') ? 'isControlTowerDealDelivery=true' : loc.includes('/network/deliveries') ? 'is_network=true' : ''}&${d?.deal_type_enum === 2 ? 'isStockTransfer=true&' : ''}back=${encodeBackUrl}${loc.includes('controltower/deliveries') ? '&isCtDelivery=true' : ''}&notConfirmedDiliveries=true`),
      setEditDeliveryId: setEditDeliveryId,
      handelRejectClick: handelRejectClick,
    };
  });

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  if (
    loc.includes('/market/deliveries/upcoming') ||
    loc.includes('/market/deliveries/ongoing') ||
    loc.includes('/network/deliveries/upcoming') ||
    loc.includes('/network/deliveries/ongoing') ||
    loc.includes('/controltower/deliveries/upcoming') ||
    loc.includes('/controltower/deliveries/ongoing')
  ) {
    tableData = _.orderBy(tableData, ['pickup_date_time'], ['asc']);
  }
  if (
    loc.includes('/market/deliveries/completed') ||
    loc.includes('/market/deliveries/missed') ||
    loc.includes('/market/deliveries/not-confirmed') ||
    loc.includes('/network/deliveries/completed') ||
    loc.includes('/network/deliveries/missed') ||
    loc.includes('/network/deliveries/not-confirmed') ||
    loc.includes('/controltower/deliveries/completed') ||
    loc.includes('/controltower/deliveries/missed') ||
    loc.includes('/controltower/deliveries/not-confirmed')
  ) {
    tableData = _.orderBy(tableData, ['pickup_date_time'], ['desc']);
  }

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (back != undefined || back != null) {
      history.push(pathname + '?' + `back=${back}&page=1`);
    } else {
      history.push(pathname + '?' + `&page=1`);
    }
  };

  let links = [
    {
      to: `/market/deliveries/upcoming?page=1`,
      title: 'Upcoming',
    },
    {
      to: `/market/deliveries/ongoing?page=1`,
      title: 'Ongoing',
    },
    {
      to: `/market/deliveries/completed?page=1`,
      title: 'Completed',
    },
    {
      to: `/market/deliveries/missed?page=1`,
      title: 'Missed',
    },
    {
      to: `/market/deliveries/not-confirmed?page=1`,
      title: 'Not Confirmed',
    },
  ];
  if (loc.includes('network/deliveries')) {
    links = [
      {
        to: `/network/deliveries/upcoming?page=1`,
        title: 'Upcoming',
      },
      {
        to: `/network/deliveries/ongoing?page=1`,
        title: 'Ongoing',
      },
      {
        to: `/network/deliveries/completed?page=1`,
        title: 'Completed',
      },
      {
        to: `/network/deliveries/missed?page=1`,
        title: 'Missed',
      },
      {
        to: `/network/deliveries/not-confirmed?page=1`,
        title: 'Not Confirmed',
      },
    ];
  } else if (loc.includes('controltower/deliveries')) {
    links = [
      {
        to: `/controltower/deliveries/upcoming?page=1`,
        title: 'Upcoming',
      },
      {
        to: `/controltower/deliveries/ongoing?page=1`,
        title: 'Ongoing',
      },
      {
        to: `/controltower/deliveries/completed?page=1`,
        title: 'Completed',
      },
      {
        to: `/controltower/deliveries/missed?page=1`,
        title: 'Missed',
      },
      {
        to: `/controltower/deliveries/not-confirmed?page=1`,
        title: 'Not Confirmed',
      },
    ];
  } else if (
    loc.includes('controltower/delivery-contract') ||
    loc.includes('commodity/deals') ||
    loc.includes('control-tower/deals') ||
    loc.includes('network/transport-deal')
  ) {
    links = [];
  }

  let is_market = '1';
  if (loc.includes('network')) {
    is_market = '0';
  }

  const getExportReport = async () => {
    const filterParams = {
      filters: { ...filters, delivery_split_enum },
      page_size: 5000,
      offset: 0,
    };
    let success;
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    if (loc.includes('control-tower/deals')) {
      const filterParams = {
        filters: { ...filters },
        page_size: 5000,
        offset: 0,
        commodity_deal_master_id: parseInt(id),
      };
      success = await dispatch(deliveryReportRegularActions.fetchDealAllotments(filterParams));
    } else {
      //   success = await dispatch(generateDeliveryContractReportCTActions.fetchListDeliveryReport(filterParams));
      success = await dispatch(notConfirmedDeliveriesCsvDataActions.fetchUnconfirmedDeliveries(filterParams))
    }

    if (reportData) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  const getExportReportRegular = async () => {
    let filterParams = {
      filters: { ...filters, delivery_split_enum, is_market },
      page_size: 5000,
      offset: 0,
    };
    let success;
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    if (loc.includes('commodity/deals') || loc.includes('control-tower/deals')) {
      filterParams = {
        filters: { ...filters },
        page_size: 5000,
        offset: 0,
        commodity_deal_master_id: parseInt(id),
      };
      success = await dispatch(deliveryReportRegularActions.fetchDealAllotments(filterParams));
    } else {
      //   success = await dispatch(deliveryReportRegularActions.fetchListByPersonId(filterParams));
      success = await dispatch(notConfirmedDeliveriesCsvDataActions.fetchUnconfirmedDeliveries(filterParams))
    }
    if (reportDataRegular) {
      csvLinkUser.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  const onSelectChange = (selectedRowKeys, row) => {
    setSelectedRowKeys(selectedRowKeys);
    setCheckBox(selectedRowKeys);
    setSelectedRows(row);
    setSelectedDeliveries && setSelectedDeliveries(row)
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    getCheckboxProps: (record) => {
      let status = true;
      //   if (record.can_accept !== 0 && record.can_check === 1) {
      //     status = false;
      //   }
      //   return {
      //     disabled: status,
      //     name: record.name,
      //   };
    },
  };

  const ctRowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    // getCheckboxProps: (record) => {
    //   let status = true;
    //   if (record.can_accept !== 0 && record.can_check === 1) {
    //     status = false;
    //   }
    //   return {
    //     disabled: status,
    //     name: record.name,
    //   };
    // },
  };

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  useEffect(() => {
    if (unscheduleArray.length == 0) {
      setShowCloseButton(false);
    } else if (unscheduleArray.length > 0 && closeArray.length == 0) {
      setShowCloseButton(true);
    } else {
      setShowCloseButton(false);
    }
    if (closeArray.length == 0) {
      if (isControlTowerUser) {
        setShowCTCloseButton(false);
      } else {
        setShowAcceptButton(false);
      }
    } else {
      if (isControlTowerUser) {
        if (
          loc.includes('controltower/deliveries/upcoming') ||
          loc.includes('controltower/deliveries/ongoing') ||
          loc.includes('controltower/deliveries/missed') ||
          loc.includes('controltower/deliveries/not-confirmed')
        ) {
          if (closeArray.length > 0) {
            setShowCTCloseButton(true);
          } else {
            setShowCTCloseButton(false);
          }
        }
        if (loc.includes('controltower/deliveries/completed')) {
          if (closeArray.length > 0) {
            setShowCTRejectCloseButton(true);
          } else {
            setShowCTRejectCloseButton(false);
          }
        }
      } else {
        if (closeArray.length > 0 && unscheduleArray.length == 0) {
          setShowAcceptButton(true);
        } else {
          setShowAcceptButton(false);
        }
      }
    }
  }, [unscheduleArray, closeArray]);

  useEffect(() => {
    setUnscheduleArray([]);
    setCloseArray([]);
    checkBoxArray.map((item, index) => {
      let record = _.get(deliveries, `entities.${item}`) || {};
      if (isControlTowerUser) {
        // record = _.get(deliveriesCT, `entities.${item}`) || {};
        setCloseArray((prevItems) => [...prevItems, record.transport_delivery_schedule_id]);
      } else {
        if (record.delivery_allotment_status_enum_code == 'Confirmed') {
          setUnscheduleArray((prevItems) => [...prevItems, record.transport_delivery_schedule_id]);
        } else if (record.delivery_allotment_status_enum_code == 'Close Out') {
          setCloseArray((prevItems) => [...prevItems, record.transport_delivery_schedule_id]);
        }
      }
    });
  }, [checkBoxArray]);

  useEffect(() => {
    dispatch(filterOptionsActions.getRegisteredUserFilterData('RejectionReasonTypeEnum'));
  }, []);

  const setEmptyData = () => {
    setTimeStamp(Date.now());
    setModal(false);
    setCheckBox([]);
    setUnscheduleArray([]);
    setCloseArray([]);
    setSelectedRowKeys([]);
    setShowCTCloseButton(false);
    setShowCTRejectCloseButton(false);
  };
  const handleCloseSubmit = async (values, formikBag) => {
    const res = await handlers.handleCloseSubmit(values, unscheduleArray, formikBag);
    if (res) {
      setEmptyData();
    }
  };

  const handleAcceptSubmit = async (values, formikBag) => {
    const res = await handlers.handleCloseSubmit(values, closeArray, formikBag);
    if (res) {
      setEmptyData();
    }
  };

  const handleRejectSubmit = async (values, formikBag) => {
    values['unuseddeliveryenum'] = 3;
    const res = await handlers.handleCloseSubmit(values, closeArray, formikBag);
    if (res) {
      setEmptyData();
    }
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      delivery_split_enum,
      is_market,
      commodity_deal_master_id,
      consignor_id,
      consignee_id,
      vehicle_id,
      driver_id,
      base_location_code,
      delivery_location_code,
      pickup_time,
      delivery_allotment_status_enum,
      transport_delivery_schedule_alloted_id,
      transport_delivery_schedule_id,
      goodsloadedacknowledgement_timestamp,
      goodsdeliveredacknowledgement_timestamp,
      is_own_transport,
      transporter_id
    });

    if (loc.includes('controltower/deliveries') && isControlTowerUser) {
      filteredParams = FilterUtils.getFilters({
        delivery_split_enum,
        commodity_deal_master_id,
        consignor_id,
        consignee_id,
        vehicle_id,
        driver_id,
        base_location_code,
        delivery_location_code,
        pickup_time,
        delivery_allotment_status_enum,
        transport_delivery_schedule_alloted_id,
        transport_delivery_schedule_id,
        goodsloadedacknowledgement_timestamp,
        goodsdeliveredacknowledgement_timestamp,
        is_secured,
      });
    }

    if (loc.includes('controltower/delivery-contract') || loc.includes('network/transport-deal')) {
      filteredParams = FilterUtils.getFilters({
        delivery_split_enum,
        transport_deal_master_id,
        consignor_id,
        consignee_id,
        vehicle_id,
        driver_id,
        base_location_code,
        delivery_location_code,
        pickup_time,
        delivery_allotment_status_enum,
        transport_delivery_schedule_alloted_id,
        transport_delivery_schedule_id,
        goodsloadedacknowledgement_timestamp,
        goodsdeliveredacknowledgement_timestamp,
        is_secured,
      });
    }

    if (loc.includes('commodity/deals') || loc.includes('control-tower/deals')) {
      filteredParams = FilterUtils.getFilters({
        transport_deal_master_id,
        consignor_id,
        consignee_id,
        vehicle_id,
        driver_id,
        base_location_code,
        delivery_location_code,
        pickup_time,
        delivery_allotment_status_enum,
        transport_delivery_schedule_id,
        goodsloadedacknowledgement_timestamp,
        goodsdeliveredacknowledgement_timestamp,
        is_secured,
      });
    }

    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
    };
    let params = {
      filters: filterParams,
      page_size: rowsPerPage,
      offset: (parseInt(page) - 1) * rowsPerPage,
    };

    let newFilters = params['filters'];
    delete newFilters['back'];

    if (loc.includes('controltower/delivery-contract') || loc.includes('network/transport-deal')) {
      params = {
        filters: filterParams,
        page_size: rowsPerPage,
        offset: (parseInt(page) - 1) * rowsPerPage,
        transport_deal_master_id: parseInt(id),
      };
    }

    if (loc.includes('commodity/deals') || loc.includes('control-tower/deals')) {
      params = {
        filters: filterParams,
        page_size: rowsPerPage,
        offset: (parseInt(page) - 1) * rowsPerPage,
        commodity_deal_master_id: parseInt(id),
      };
    }
    if (isControlTowerUser && (loc.includes('market/deliveries') || loc.includes('network/deliveries'))) {
      // dispatch(deliveryAllotmentsActions.fetchListByPersonId(params));
      if (delivery_split_enum == 1) {
        dispatch(upcomingActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 2) {
        dispatch(completedActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 3) {
        dispatch(ongoingActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 4) {
        dispatch(missedActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 5) {
        dispatch(notConfirmedActions.fetchUnconfirmedDeliveries(params))
      }
    } else if (loc.includes('controltower/delivery-contract') || loc.includes('network/transport-deal')) {
      dispatch(deliveryContractActions.FetchDeliveryAllotmentsAgainstTransportDealMasterId(params));
    } else if (loc.includes('commodity/deals')) {
      dispatch(deliveryAllotmentsActions.fetchDealAllotments(params));
    } else if (loc.includes('control-tower/deals')) {
      dispatch(deliveryAllotmentsActions.fetchDealAllotmentsELoc(params));
    } else if (
      isControlTowerUser &&
      !loc.includes('controltower/delivery-contract') &&
      !loc.includes('control-tower/deals')
    ) {
      if (delivery_split_enum == 1) {
        dispatch(upcomingActions.fetchListCT(params))
      } else if (delivery_split_enum == 2) {
        dispatch(completedActions.fetchListCT(params))
      } else if (delivery_split_enum == 3) {
        dispatch(ongoingActions.fetchListCT(params))
      } else if (delivery_split_enum == 4) {
        dispatch(missedActions.fetchListCT(params))
      } else if (delivery_split_enum == 5) {
        dispatch(notConfirmedActions.fetchUnconfirmedDeliveries(params))
      }

      // dispatch(deliveriesCTActions.fetchList(params));
    } else {
      // dispatch(deliveryAllotmentsActions.fetchListByPersonId(params));
      if (delivery_split_enum == 1) {
        dispatch(upcomingActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 2) {
        dispatch(completedActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 3) {
        dispatch(ongoingActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 4) {
        dispatch(missedActions.fetchListByPersonId(params))
      } else if (delivery_split_enum == 5) {
        dispatch(notConfirmedActions.fetchUnconfirmedDeliveries(params))
      }
    }
  }, [
    page,
    rowsPerPage,
    commodity_deal_master_id,
    consignor_id,
    consignee_id,
    vehicle_id,
    driver_id,
    base_location_code,
    delivery_location_code,
    pickup_time,
    delivery_allotment_status_enum,
    transport_delivery_schedule_id,
    transport_delivery_schedule_alloted_id,
    goodsloadedacknowledgement_timestamp,
    goodsdeliveredacknowledgement_timestamp,
    timeStamp,
    type,
    place,
    isUpdateRequired,
    is_own_transport,
    is_secured,
    isReloadRequired,
    isReRenderRequired,
    transporter_id
  ]);
  let refreshCounter = 5;

  useEffect(() => {
    if (isWalkIn == true) {
      let tmr;
      if (loc.includes('commodity/deals')) {
        if (tableData.length == 0) {
          tmr = setInterval(async () => {
            if (refreshCounter > 0) {
              let params = {
                filters: filters,
                page_size: rowsPerPage,
                offset: (parseInt(page) - 1) * rowsPerPage,
                commodity_deal_master_id: parseInt(id),
              };
              await dispatch(deliveryAllotmentsActions.fetchDealAllotments(params));
              refreshCounter = refreshCounter - 1;
            } else {
              clearInterval(tmr);
            }
          }, 30000);
          return () => {
            clearInterval(tmr);
          };
        } else {
          clearInterval(tmr);
        }
      }
    }

  }, [refreshCounter]);

  let revertTo;
  const handelDeliveryRevert = async (reason) => {
    const params = {
      revertEnum: revertTo,
      deliveriesToRevert: checkBoxArray,
      reversalReason: reason,
    };
    setModal(false);
    await dispatch(deliveriesCTActions.revertDeliveries(params));
    setIsUpdateRequired(!isUpdateRequired);
    setDeliveryRevertReason('');
  };

  const handleMoveDeliveries = async () => {
    const params = {
      "delivery_Schedule_Id": checkBoxArray,
      // "pickup_Time": pickUpTime,
      "reason_Type_Enum": moveDeliveriesReasonEnum,
      "delivery_Schedule_Enum": Delivery_Schedule_Enum.Schedules,
      "operation_Enum": Operation_Enum.Update,
      "Autoincrementer_value": parseInt(moveDeliveriesDateByDays),
    }
    setModal(false);
    await dispatch(deliveriesCTActions.updateUnconfirmedDeliverySchedule(params));
    setIsUpdateRequired(!isUpdateRequired);
    setDeliveryRevertReason('');
  }

  const handleDeleteDeliveries = async (values) => {
    const recordIds = _.isInteger(values) ? [values] : checkBoxArray
    const params = {
      "delivery_Schedule_Id": recordIds,
      "delivery_Schedule_Enum": Delivery_Schedule_Enum.Schedules,
      "reason_Type_Enum": Reason_Type_Enum.ForceMajeure,
      "operation_Enum": Operation_Enum.Delete
    }
    setModal(false);
    await dispatch(deliveriesCTActions.updateUnconfirmedDeliverySchedule(params));
    setIsUpdateRequired(!isUpdateRequired);
    setDeliveryRevertReason('');
  }

  const resetRejectionPopupData = () => {
    setDeliveryRejectionRemark('');
    setSelectedDeliveryRejectReason({});
    setIsOpen(false)
  };

  const handelDealReject = () => {
    if (selectedDeliveryRejectReason?.value) {
      const params = {
        rejection_reason_type_enum: selectedDeliveryRejectReason?.value,
        remarks: deliveryRejectionRemark,
      };
      const success = dispatch(
        deliveriesCTActions.RejectDelivery_CT(params, deliveryData?.transport_delivery_schedule_alloted_id),
      );
      if (success) {
        setIsReloadRequired(!isReloadRequired);
        resetRejectionPopupData();
      }
    } else {
      return utils.displayMessage('Please select rejected by to proceed.', 'error')
    }
  };

  // const handelRevert = (e, formik, values) => {
  //   revertTo = e.target.innerText.split(' ').join('');
  //   setReverseToStatus(e.target.innerText.split(' ').join(''));
  //   setModalValue(values);
  //   setModalFormikBag(formik);
  //   setModalTitle('Revert To');
  //   setModal(true);
  //   setHandler(() => handelDeliveryRevert);
  // };
  const disableCloseOutBtn = selectedRows.some((item) => item.can_accept === 0 || item.can_check === 0);

  // useEffect(() => {
  //   if (isWalkIn == true) {
  //     if (loc.includes('commodity/deals')) {
  //       if (tableData.length == 0) {
  //         return <div>Delivery Scheduling is in progress ...</div>;
  //       }
  //     }
  //   }
  // }, [isWalkIn])


  useEffect(() => {
    const isGreenIcon = document?.querySelector('.green-offer-icon') ? true : false;
    const isOrangeIcon = document?.querySelector('.orange-offer-icon') ? true : false;
    const isTransportConvertIcon = document?.querySelector('.green-transport-icon') ? true : false;
    const isGrayIcon = document?.querySelector('.gray-offer-icon') ? true : false;
    setIsGreenIconAvailable(isGreenIcon)
    setIsOrangeIconAvailable(isOrangeIcon)
    setIsTransportConvertIconAvailable(isTransportConvertIcon)
    setIsGrayIconAvailable(isGrayIcon)
  })

  const steps = [
    ...(isTransportConvertIconAvailable && tableData?.length ? [
      {
        selector: '.green-transport-icon',
        content: (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-90d632)', height: '100px', width: '100px', margin: 'auto' }} />
            <div style={{ textAlign: 'justify' }}>
              <strong>Change To BiofuelCircle Transport</strong>
              <div>Get affordable BiofuelCircle Transport rates!</div>
              <div>Move your mouse over this <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-90d632)', height: '24px', width: '24px', margin: '0 6px' }} /> to see offers available for Transport. Click <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-90d632)', height: '24px', width: '24px', margin: '0 6px' }} /> to avail the scheme.</div>
            </div>
          </div>
        ),
        style: {
          maxWidth: '280px',
        },
        position: 'right',
        action: (node) => {
          document?.querySelector('.green-transport-icon')?.scrollIntoView();
        },
      },
      {
        selector: '.green-transport-icon',
        content: (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <img
              src={ConvertToBFCTransportImg}
              style={{
                boxShadow: '1px 1px 5px #0000004a',
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 15,
                textAlign: 'justify',
              }}
            />
            <div style={{ textAlign: 'justify' }}>Click <strong >‘OK’</strong> to accept terms & conditions of the scheme. This offer will be applied for this delivery only.</div>
          </div>
        ),
        style: {
          maxWidth: '468px',
        },
        position: 'right',
        action: (node) => {
          document?.querySelector('.green-transport-icon')?.scrollIntoView();
        },
      },
      {
        selector: '.green-transport-icon',
        content: (
          <div className="d-flex flex-column justify-content-center align-items-start">
            <LocalOfferIcon color='primary' style={{ color: 'var(--unnamed-color-F1B043)', height: '100px', width: '100px', margin: 'auto' }} />
            <div style={{ textAlign: 'justify' }}>Once the transport is converted to the BiofuelCircle transport, it can not be reverted.</div>
          </div>
        ),
        style: {
          maxWidth: '280px',
        },
        position: 'right',
        action: (node) => {
          document?.querySelector('.green-transport-icon')?.scrollIntoView();
        },
      }
    ] : []),
    ...(!document?.querySelector('.orange-offer-icon') && document?.querySelector('.green-offer-icon') && tableData?.length
      ? [
        {
          selector: '.green-offer-icon',
          content: (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={OfferIcon} style={{ width: '150px', height: '150px' }} />
              <div style={{ textAlign: 'justify' }}>
                <p className="font-weight-bold">Early Payment Scheme</p>
                <p className="m-0">Get paid next business day after Delivery.</p>
                <p className="m-0">
                  Only for Verified Sellers like you. Move your mouse over this{' '}
                  <img src={OfferIcon} style={{ width: '20px', height: '20px' }} /> to see offers available for early
                  payment. Click <img src={OfferIcon} style={{ width: '20px', height: '20px' }} /> to avail the
                  scheme.
                </p>
              </div>
            </div>
          ),
          action: (node) => {
            document?.querySelector('.green-offer-icon')?.scrollIntoView();
          },
        },
        {
          selector: '.green-offer-icon',
          content: (
            <div className="d-flex flex-column justify-content-center align-items-start">
              <img
                src={
                  selectedDeal?.is_early_payment_icon_clickable === false
                    ? isOrangeIconAvailable
                      ? confirmationPopupDeActivated
                      : confirmationPopupAvailed
                    : confirmationPopupImg
                }
                style={{ boxShadow: '1px 1px 5px #0000004a', borderRadius: 4, marginTop: 20, marginBottom: 15 }}
              />
              <span style={{ textAlign: 'justify' }}>
                <div>Click <strong >‘OK’</strong> to accept terms & conditions of the scheme. The Offer will be applied to all upcoming deliveries in your deal.</div>
              </span>
            </div>
          ),
          style: {
            maxWidth: '468px',
          },
          position: 'left',
          action: (node) => {
            document?.querySelector('.green-offer-icon')?.scrollIntoView();
          },
        },
      ]
      : []),
    ...(isOrangeIconAvailable && tableData?.length
      ? [
        {
          selector: '.orange-offer-icon',
          content: (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={OfferAvailedIcon} style={{ width: '150px', height: '150px' }} />
              <span style={{ textAlign: 'justify' }}>
                This shows deliveries where you have availed the <strong>Early Payment Scheme.</strong> You can turn this on or off for each delivery by clicking on this <img src={OfferAvailedIcon} style={{ width: '20px', height: '20px' }} /> icon.
              </span>
            </div>
          ),
          position: 'left',
          action: (node) => {
            document?.querySelector('.orange-offer-icon')?.scrollIntoView();
          },
        },
        {
          selector: '.orange-offer-icon',
          content: (
            <div className="d-flex flex-column justify-content-center align-items-start">
              <img
                src={isOrangeIconAvailable ? confirmationPopupDeActivated : confirmationPopupAvailed}
                style={{ boxShadow: '1px 1px 5px #0000004a', borderRadius: 4, marginTop: 20, marginBottom: 15 }}
              />
              <span style={{ textAlign: 'justify' }}>
                Clicking <strong>'OK'</strong> here will{' '}
                {isOrangeIconAvailable ? 'deactivate the offer for this delivery.' : 'activate the offer for this delivery.'}{' '}
              </span>
            </div>
          ),
          style: {
            maxWidth: '468px',
          },
          position: 'left',
          action: (node) => {
            document?.querySelector('.orange-offer-icon')?.scrollIntoView();
          },
        },
      ]
      : []),
    // ...(loc.includes('controltower/deliveries') && isGrayIconAvailable && isControlTowerUser && tableData?.length ? [
    //   {
    //     selector: '.gray-offer-icon',
    //     content: (
    //       <div className="d-flex flex-column justify-content-center align-items-start">
    //         <AllowEarlyPaymentIcon style={{ height: '100px', width: '100px', margin: 'auto' }} />
    //         <div style={{ textAlign: 'justify' }}>Allow sellers to opt <strong>Early Payment Scheme</strong> explicitly irrespective of supplier profile verification.</div>
    //       </div>
    //     ),
    //     style: {
    //       maxWidth: '280px',
    //     },
    //     position: 'right',
    //     action: (node) => {
    //       document?.querySelector('.gray-offer-icon')?.scrollIntoView();
    //     },
    //   }
    // ] : [])
  ];

  useEffect(() => {
    ((!isOrangeIconAvailable && isGreenIconAvailable) || isTransportConvertIconAvailable || isGrayIconAvailable) && steps.length && !loc.includes('/deliveries/completed') ? setIsTourOpen(true) : setIsTourOpen(false)
  }, [isGreenIconAvailable, isOrangeIconAvailable, steps.length, tableData?.length])

  useEffect(() => {
    return () => {
      dispatch(deliveryAllotmentsActions.setAll({}));
      dispatch(deliveryContractActions.setAll({}));
    }
  }, []);

  let closeOutInitialValues = getCloseInitialValues()
  let closeOutModalTitleTxt = 'Close Out'
  let closeOutHandle = handleCloseSubmit
  let closeOutBtnTxt = 'Close Out'

  if (showCloseButton) {
    closeOutInitialValues = getCloseInitialValues()
    closeOutModalTitleTxt = 'Close Out'
    closeOutHandle = handleCloseSubmit
    closeOutBtnTxt = 'Close Out'
  }

  if (showCTCloseButton) {
    closeOutInitialValues = getCTAcceptInitialValues()
    closeOutModalTitleTxt = 'Accept Close Out'
    closeOutHandle = handleAcceptSubmit
    closeOutBtnTxt = 'Close Out'
  }

  if (showCTRejectCloseButton && (loc.includes('controltower/deliveries/missed') || loc.includes('controltower/deliveries/completed'))) {
    closeOutInitialValues = getCTAcceptInitialValues()
    closeOutModalTitleTxt = 'Reject Close Out'
    closeOutHandle = handleRejectSubmit
    closeOutBtnTxt = 'Close Out'
  }
  if (showAcceptButton) {
    closeOutInitialValues = getAcceptInitialValues()
    closeOutModalTitleTxt = 'Accept Close Out'
    closeOutHandle = handleAcceptSubmit
    closeOutBtnTxt = 'Close Out'
  }

  return (
    <div>
      <div className="row2">
        {isWalkIn && loc.includes('commodity/deals') && !tableData.length ? (
          <div>Delivery Scheduling is in progress ...</div>
        ) : (
          <>
            {!loc.includes('controltower/delivery-contract') &&
              !loc.includes('commodity/deals') &&
              !loc.includes('control-tower/deals') &&
              !loc.includes('network/transport-deal') &&
              (
                <OverviewHeader
                isNonConfirmed = {true}
                  heading="Deliveries"
                  backLink={back}
                  rightPanContent={
                    (CT_parent || NetworkAccess || MarketAccess) && <div className="d-flex justify-flex-end">
                      <BulkActionsDropdown
                        checkBoxArray={checkBoxArray}
                        closeOutBtnTxt={closeOutBtnTxt}
                        disableCloseOutBtn={disableCloseOutBtn}
                        closeOutInitialValues={closeOutInitialValues}
                        closeOutHandle={closeOutHandle}
                        closeOutModalTitleTxt={closeOutModalTitleTxt}
                        handleAcceptSubmit={handleAcceptSubmit}
                        getCloseInitialValues={getCloseInitialValues}
                        handleDeleteDeliveries={handleDeleteDeliveries}
                        setHandler={setHandler}
                        setModalValue={setModalValue}
                        setModalFormikBag={setModalFormikBag}
                        setModalTitle={setModalTitle}
                        setModal={setModal}
                      />
                    </div>
                  }
                />
              )}
            <div className="bfc-body">
              <DeliveryOverviewNav
                links={links?.map((link) => ({ ...link, to: getUrlWithEncryptedQueryParams(`${link.to}${utils.getSearchParams()?.length ? `&${utils.getSearchParams()}` : ''}`) }))}
                setEmptyData={setEmptyData}
                setFilters={setFilters}
                filters={filters}
                setRowsPerPage={setRowsPerPage}
              />
              {/* {(busy || busyCT || busyDC) && (
                <div className="commodity-wrapper">
                  <div className="panel__refresh" style={{ top: 'auto', height: 'auto' }}>
                    <LogoLoader
                      minHeight={'calc(100vh - 270px)'} />
                  </div>
                </div>
              )} */}
              <div className=" delivery-table">
                <div className="ant-table-body ant-table">
                  <Table
                    ref={tableRef}
                    dataSource={!busy ? tableData1 : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    columns={busy ? DELIVERIES_TABLE_HEADERS.map((column) => {
                      return {
                        ...column,
                        render: function renderPlaceholder() {
                          return (
                            <Skeleton
                              active="true"
                              key={column.dataIndex}
                              title={true}
                              paragraph={false}
                            />
                          );
                        },
                      };
                    }) : DELIVERIES_TABLE_HEADERS}
                    pagination={false}
                    onChange={handlePageChange}
                    scroll={{ y: 550 }}
                    className="ant-table"
                    size="small"
                    rowKey={(record) => record.key}
                    rowSelection={rowSelection}
                  />
                  {/* {isMarketUpcomingDeliveries &&
                    (loc.includes('deliveries/upcoming') || loc.includes('deliveries/missed')) ? (
                    <Table
                      ref={tableRef}
                      dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                      columns={busy ? DELIVERIES_TABLE_HEADERS.map((column) => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return (
                              <Skeleton
                                active="true"
                                key={column.dataIndex}
                                title={true}
                                paragraph={false}
                              />
                            );
                          },
                        };
                      }) : DELIVERIES_TABLE_HEADERS}
                      pagination={false}
                      onChange={handlePageChange}
                      scroll={{ y: 550 }}
                      className="ant-table"
                      size="small"
                      rowKey={(record) => record.key}
                      rowSelection={rowSelection}
                    />
                  ) : isControlTowerUser && loc.includes('controltower/deliveries') ? (
                    <Table
                      ref={tableRef}
                      dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                      columns={busy ? DELIVERIES_TABLE_HEADERS.map((column) => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return (
                              <Skeleton
                                active="true"
                                key={column.dataIndex}
                                title={true}
                                paragraph={false}
                              />
                            );
                          },
                        };
                      }) : DELIVERIES_TABLE_HEADERS}
                      pagination={false}
                      onChange={handlePageChange}
                      scroll={{ y: 550 }}
                      className="ant-table"
                      size="small"
                      rowKey={(record) => record.key}
                      rowSelection={ctRowSelection}
                    />
                  ) : (
                    <Table
                      ref={tableRef}
                      dataSource={(loc.includes('/controltower/delivery-contract') ? !busyDC : !busy) ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                      columns={(loc.includes('/controltower/delivery-contract') ? busyDC : busy) ? DELIVERIES_TABLE_HEADERS.map((column) => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return (
                              <Skeleton
                                active="true"
                                key={column.dataIndex}
                                title={true}
                                paragraph={false}
                              />
                            );
                          },
                        };
                      }) : DELIVERIES_TABLE_HEADERS}
                      pagination={false}
                      onChange={handlePageChange}
                      scroll={{ y: 550 }}
                      className="ant-table"
                      size="small"
                      rowSelection={loc.includes('commodity/deals') || loc.includes('control-tower/deals') ? ctRowSelection : {}}
                    />
                  )} */}
                  {isControlTowerUser && loc.includes('controltower/deliveries') ? (
                    <AntPagination
                      total={parseInt(totalRecords)}
                      handlePageChange={handlePageChange}
                      page={page}
                      clearFilters={clearFilters}
                      reportData={loc.includes('control-tower/deal') ? reportDataRegular : reportData}
                      getExportReport={getExportReport}
                      csvLink={csvLink}
                      type={'Deliveries'}
                      rowsPerPage={rowsPerPage}
                      setScrollToFirstRow={setScrollToFirstRow}
                    />
                  ) : (
                    <AntPagination
                      total={parseInt(totalRecords)}
                      handlePageChange={handlePageChange}
                      page={page}
                      clearFilters={clearFilters}
                      reportData={reportDataRegular}
                      getExportReport={getExportReportRegular}
                      csvLink={csvLinkUser}
                      type={'Deliveries'}
                      rowsPerPage={rowsPerPage}
                      setScrollToFirstRow={setScrollToFirstRow}
                    />
                  )}
                  {tableData?.length ? (
                    <div className="position-fixed" style={{ marginRight: 20, bottom: 5, right: 0 }}>
                      <div
                        style={{
                          // display: 'grid',
                          gridTemplateColumns: (loc.includes('upcoming') || loc.includes('missed')) ? '1fr' : '1fr 1fr',
                          gap: '20px',
                        }}>
                        {loc.includes('upcoming') ? (
                          <>
                            <div style={{ textAlign: 'right' }}>
                              <MUITypography variant='body1'>Quantity</MUITypography>
                              {tableData?.[0]?.is_different_uom_available ? (
                                <Popover
                                  content={
                                    <div style={{ width: '400px' }}>
                                      <MUITypography variant='body2'>
                                        Different units of measurement in your deliveries prevent us from calculating the
                                        total quantity.
                                      </MUITypography>
                                    </div>
                                  }>
                                  <MUITypography variant="body1" className="font-weight-bold cursor-pointer" >N/A**</MUITypography>
                                </Popover>
                              ) : tableData?.[0]?.total_scheduled_qty ? (
                                <MUITypography variant='body1' className="font-weight-bold">
                                  {utils.formatNumberPrice(tableData?.[0]?.total_scheduled_qty)} {tableData?.[0]?.qty_uom_code}
                                </MUITypography>
                              ) : (
                                <MUITypography variant='body1' className="font-weight-bold">N/A</MUITypography>
                              )}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        {loc.includes('ongoing') || loc.includes('completed') ? (
                          <>
                            <div style={{ textAlign: 'right' }}>
                              <MUITypography variant='body1'>Net Qty at Loading</MUITypography>
                              {tableData?.[0]?.is_different_uom_available ? (
                                <Popover
                                  content={
                                    <div style={{ width: '400px' }}>
                                      <MUITypography variant='body2'>
                                        Different units of measurement in your deliveries prevent us from calculating the
                                        total quantity.
                                      </MUITypography>
                                    </div>
                                  }>
                                  <MUITypography variant="body1" className="font-weight-bold cursor-pointer" >N/A**</MUITypography>
                                </Popover>
                              ) : tableData?.[0]?.total_net_quantity_at_loading ? (
                                <MUITypography variant='body1' className="font-weight-bold">
                                  {utils.formatNumberPrice(tableData?.[0]?.total_net_quantity_at_loading)}{' '}
                                  {tableData?.[0]?.qty_uom_code}
                                </MUITypography>
                              ) : (
                                <MUITypography variant='body1' className="font-weight-bold">N/A</MUITypography>
                              )}
                            </div>
                            <div style={{ textAlign: 'right' }}>
                              <MUITypography variant='body1'>Net Qty at Delivery</MUITypography>
                              {tableData?.[0]?.is_different_uom_available ? (
                                <Popover
                                  content={
                                    <div style={{ width: '400px' }}>
                                      <MUITypography variant='body2'>
                                        Different units of measurement in your deliveries prevent us from calculating the
                                        total quantity.
                                      </MUITypography>
                                    </div>
                                  }>
                                  <MUITypography variant="body1" className="font-weight-bold cursor-pointer" >N/A**</MUITypography>
                                </Popover>
                              ) : tableData?.[0]?.total_net_quantity_at_delivery ? (
                                <MUITypography variant='body1' className="font-weight-bold">
                                  {utils.formatNumberPrice(tableData?.[0]?.total_net_quantity_at_delivery)}{' '}
                                  {tableData?.[0]?.qty_uom_code}
                                </MUITypography>
                              ) : (
                                <MUITypography variant='body1' className="font-weight-bold">N/A</MUITypography>
                              )}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        {loc.includes('missed') ? (
                          <>
                            <div style={{ textAlign: 'right' }}>
                              <MUITypography variant="body1">Missed Qty</MUITypography>
                              {tableData?.[0]?.is_different_uom_available ? (
                                <Popover
                                  content={
                                    <div style={{ width: '400px' }}>
                                      <MUITypography variant='body2'>
                                        Different units of measurement in your deliveries prevent us from calculating the
                                        total quantity.
                                      </MUITypography>
                                    </div>
                                  }>
                                  <MUITypography variant="body1" className="font-weight-bold cursor-pointer" >N/A**</MUITypography>
                                </Popover>
                              ) : tableData?.[0]?.total_missed_qty ? (
                                <MUITypography variant="body1" className="font-weight-bold">
                                  {utils.formatNumberPrice(tableData?.[0]?.total_missed_qty)} {tableData?.[0]?.qty_uom_code}
                                </MUITypography>
                              ) : (
                                <MUITypography className="font-weight-bold" variant="body1">
                                  N/A
                                </MUITypography>
                              )}
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        {loc.includes('not-confirmed') ? (
                          <>
                            <div style={{ textAlign: 'right' }}>
                              <MUITypography className="muigraphy-text" variant="body1">Total Qty</MUITypography>
                              <MUITypography className="muigraphy-text font-weight-bold" variant="body1">
                                {totalNotConfirmedDeliveryCount}
                              </MUITypography>
                              {/* {tableData?.[0]?.is_different_uom_available ? (
                                <Popover
                                  content={
                                    <div style={{ width: '400px' }}>
                                      <MUITypography variant='body2'>
                                        Different units of measurement in your deliveries prevent us from calculating the
                                        total quantity.
                                      </MUITypography>
                                    </div>
                                  }>
                                  <MUITypography variant="body1" className="font-weight-bold cursor-pointer" >N/A**</MUITypography>
                                </Popover>
                              ) : tableData?.[0]?.total_missed_qty ? (
                                <MUITypography variant="body1" className="font-weight-bold">
                                  {utils.formatNumberPrice(tableData?.[0]?.total_missed_qty)} {tableData?.[0]?.qty_uom_code}
                                </MUITypography>
                              ) : (
                                <MUITypography className="font-weight-bold" variant="body1">
                                  N/A
                                </MUITypography>
                              )} */}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <ConfirmModal
              entityId={showModal}
              handleConfirm={modalTitle === 'Revert Status To' ? handelDeliveryRevert : modalTitle === 'Move Date' ? handleMoveDeliveries : handler}
              handleValues={modalValue}
              handleFormik={modalFormikBag}
              closeModal={() => setModal(false)}
              color="primary"
              title={modalTitle}
              header
              btn="Primary"
              isDeliverityRevertReason={modalTitle === 'Revert Status To' ? true : false}
              setMoveDeliveriesDateByDays={setMoveDeliveriesDateByDays}
              setPickUpTime={setPickUpTime}
              pickUpTime={pickUpTime}
              setMoveDeliveriesReasonEnum={setMoveDeliveriesReasonEnum}
              moveDeliveriesReasonEnum={moveDeliveriesReasonEnum}
              isMoveDeliveryDate={modalTitle === 'Move Date' ? true : false}
              message={
                modalTitle == 'Reject Close Out'
                  ? 'Are you sure you want to reject close out this delivery?'
                  : modalTitle === 'Revert Status To'
                    ? `Are you sure you want to revert ${checkBoxArray.length > 1 ? 'these' : 'this'
                    } delivery  to ${reverseToStatus}`
                    : modalTitle === 'Move Date' ? '' : (modalTitle === 'Delete Schedule(s)') ? `The selected schedules will be deleted permanently. Are you sure you want to continue?` : 'Are you sure you want to close this delivery?'
              }
            />
            <DialogPopup
              type="warning"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              heading={'Delivery Rejection'}
              message={`Are you sure you want to reject this delivery with id #${deliveryData?.transport_delivery_schedule_alloted_id}?`}
              reset={resetRejectionPopupData}
              onClick={handelDealReject}>
              <span className="d-block font-weight-bold">Rejection Reason</span>
              <Autocomplete
                size="small"
                variant="outlined"
                className="mb-2"
                options={deliveryRejectionReasonData}
                getOptionLabel={(option) => option?.text}
                renderInput={(params) => <TextField variant="outlined" {...params} />}
                value={selectedDeliveryRejectReason}
                onChange={(e, newValue) => setSelectedDeliveryRejectReason(newValue)}
              />
              <span className="d-block font-weight-bold">Remark</span>
              <TextField
                multiline
                maxRows={8}
                className="w-100"
                size="small"
                variant="outlined"
                placeholder="Remark"
                value={deliveryRejectionRemark}
                onChange={(e) => e.target.value.length <= 270 && setDeliveryRejectionRemark(e.target.value)}
              />
              <span style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__four)', textAlign: 'right', display: 'block' }}>
                {deliveryRejectionRemark.length} characters out of 270
              </span>
              <div className='d-flex delivery-reject-warning'>
                {deliveryData.custom_invoice_number ? <WarningFilled /> : ''}
                {
                  deliveryData.custom_invoice_number ?
                    <b> Invoice No.
                        <Link to={getUrlWithEncryptedQueryParams(`/invoices/${deliveryData.invoice_master_id}?back=${encodeBackUrl}`)} target="_blank" style={{ fontSize: 'var(--fs-base__four)' }}>
                        {` ${deliveryData.custom_invoice_number} `}
                      </Link>
                      associated with this Delivery will automatically be Credited. This action cannot be reversed.
                    </b>
                    :
                    ''
                }
              </div>
              <div className='d-flex delivery-reject-warning'>
                {deliveryData.linked_delivery_id ? <WarningFilled /> : ''}
                {
                  deliveryData.linked_delivery_id ?
                    <b> Delivery No.
                        <Link to={getUrlWithEncryptedQueryParams(`/deliveries/${deliveryData.linked_delivery_id}/overview?back=${encodeBackUrl}`)} target="_blank" style={{ fontSize: 'var(--fs-base__four)' }}>
                        {` ${deliveryData.linked_delivery_id} `}
                      </Link>
                      linked to this Delivery will also be marked as Rejected. Any invoices for goods associated with either deliveries will automatically be credited.
                      <strong className="d-block">Proceed with Rejecting all linked Deliveries?</strong>
                    </b>
                    :
                    ''
                }
              </div>
            </DialogPopup>
            {editDeliveryId ? (
              <div className="sidebar-form">
                <PopupForm title={'Delivery Details'} onClose={() => setEditDeliveryId(false)}>
                  <DeliveryFormNotConfirmed
                    close={() => {
                      setEditDeliveryId(false);
                    }}
                    timestamp={timeStamp}
                    setTimestamp={setTimeStamp}
                    deliveryId={editDeliveryId}
                  />
                </PopupForm>
              </div>
            ) : null}
            <DialogPopup
              type='info'
              setIsOpen={setEarlyPayment}
              isOpen={earlyPayment}
              heading={'Payment on Delivery* Scheme'}
              message={
                selectedDeal?.is_early_payment_icon_clickable === false ? <div>
                  {!selectedDeal?.early_payment_metadata_id ? <div className="mb-2">You {<span style={{ fontWeight: 'bold' }}>Deactivated</span>} this scheme for this delivery, though the Payment on Delivery is active for the Deal.</div> : ''}
                  <div>Do you wish to {selectedDeal?.early_payment_metadata_id ? <span><span style={{ fontWeight: 'bold' }}>Deactivate</span> this scheme</span> : 're-activate'} only for this delivery{selectedDeal?.early_payment_metadata_id ? '? Though the Payment on Delivery is active for the Deal.' : <span> at<span style={{ color: '#32a836', fontSize: 'var(--fs-base__two)', fontWeight: 'bold', letterSpacing: 0.5 }}> ₹{selectedDeal?.early_payment_discount}/{selectedDeal?.price_uom_code}</span></span>}</div>
                </div> : <div>
                  <div style={{ marginBottom: 10, marginTop: 10 }}>
                    <p>Get paid next business day after completion of Delivery, when you select this scheme (subject to Buyer accepting goods delivered) <span style={{ display: 'block' }}>Early Payment deduction of <span style={{ fontWeight: 'bold' }}>{earlyPaymentDiscount} </span>₹/{selectedDeal?.price_uom_code}** will apply​​.</span></p>
                    <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__four)' }}>(** subject to change from time to time)</p>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <p style={{ color: '#32a836' }}>Early Payment Discount will be applied to all future Deliveries under this Deal.​</p>
                  </div>
                  <div>
                    <p>By agreeing to proceed, you accept all applicable <a href=' https://my.biofuelcircle.com/terms-and-conditions.html' target={'_blank'}>Terms & Conditions.</a></p>
                    <p>To know more, contact <a href="mailto:support@biofuelcircle.com?subject=Early Payment Scheme!&body=I wish to know more about the Early Payment Scheme!">BiofuelCircle Support</a>/ +91 (020) 48522522.​</p>
                  </div>
                </div>}
              onClick={selectedDeal?.is_early_payment_icon_clickable === false ? enableDisableEarlyPaymentOnDelivery : handelApplyEarlyPayment}
            >
            </DialogPopup>
            <DialogPopup
              type={'info'}
              setIsOpen={setIsConvertOwnToBFCTransport}
              isOpen={isConvertOwnToBFCTransport}
              heading={`Change To BiofuelCircle Transport : Delivery #${selectedDealForTransportConversion?.isowntobiofuel_delivery_id}`}
              message={
                <div>
                  <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 2fr', marginBottom: 8, fontSize: 'var(--fs-base__three)' }}>
                    <div>{selectedDealForTransportConversion?.pickup_location}</div>
                    <div style={{ margin: '0 8px' }}>
                      <DeliveryTruck style={{ height: 40, width: 50, marginLeft: 20 }} />
                      <div style={{ backgroundColor: 'var(--unnamed-color-1b62ab)', height: 10, clipPath: 'polygon(0 25%, 75% 25%, 75% 0, 88% 50%, 75% 100%, 75% 75%, 0 75%)' }}></div>
                      <span style={{ fontSize: 'var(--fs-base__four)', fontWeight: 'bold', marginLeft: 13 }}>{selectedDealForTransportConversion?.isowntobiofuel_number_of_deliveries} * {selectedDealForTransportConversion?.isowntobiofuel_vehicle_size}</span>
                    </div>
                    <div>{selectedDealForTransportConversion?.delivery_location}</div>
                  </div>
                  <p>Get BiofuelCircle Transport at <strong style={{ color: 'var(--unnamed-color-00ae11)' }}>₹{utils.formatNumberPrice(selectedDealForTransportConversion?.isowntobiofuel_transport_rate)}/{selectedDealForTransportConversion?.price_uom_code}</strong></p>
                  <p>Delivery #{selectedDealForTransportConversion?.isowntobiofuel_delivery_id} under deal #{selectedDealForTransportConversion?.isowntobiofuel_deal_id} will be changed.</p>
                  <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__one)' }}>This change cannot be undone.</p>
                  <div>
                    <p>By agreeing to proceed, you accept all applicable <a href=' https://my.biofuelcircle.com/terms-and-conditions.html' target={'_blank'}>Terms & Conditions.</a></p>
                    <span>To know more, contact <a href="mailto:support@biofuelcircle.com?subject=Early Payment Scheme!&body=I wish to know more about the Early Payment Scheme!">BiofuelCircle Support</a>/ +91 (020) 48522522.​</span>
                  </div>
                </div>
              }
              onClick={handelConvertToBFCTransport}
            >
            </DialogPopup>
            <DialogPopup
              type={'info'}
              setIsOpen={setIsConvertMultipleOwnToBFCTransport}
              isOpen={isConvertMultipleOwnToBFCTransport}
              heading={`Change To BiofuelCircle Transport`}
              message={
                <div>
                  <p>Are you sure you want to convert selected deliveries to BiofuelCircle Transport.Please note all deliveries should belong to same deal.</p>
                  <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__one)' }}>This change cannot be undone.</p>
                  <div>
                    <p>By agreeing to proceed, you accept all applicable <a href=' https://my.biofuelcircle.com/terms-and-conditions.html' target={'_blank'}>Terms & Conditions.</a></p>
                    <span>To know more, contact <a href="mailto:support@biofuelcircle.com?subject=Early Payment Scheme!&body=I wish to know more about the Early Payment Scheme!">BiofuelCircle Support</a>/ +91 (020) 48522522.​</span>
                  </div>
                </div>
              }
              onClick={handelConvertToBFCTransport}
            >
            </DialogPopup>
            <DialogPopup
              type={'info'}
              setIsOpen={setAllowEarlyPayment}
              isOpen={allowEarlyPayment}
              heading={'Allow Payment on Delivery* Scheme'}
              message={
                <div>
                  <div style={{ marginBottom: 10 }}>
                    <p>Allow Early payment scheme for this deal/all future deliveries of this deal. Are you sure you want to proceed?</p>
                    <p style={{ fontWeight: 'bold', fontSize: 'var(--fs-base__four)' }}>(** Seller's supplier profile is KYC Verified)</p>
                  </div>
                </div>
              }
              onClick={handelAllowEarlyPayment}
            >
            </DialogPopup>
            <Tour
              steps={steps}
              isOpen={isTourOpen}
              onRequestClose={() => setIsTourOpen(false)}
              className="helper"
              rounded={5}
            />
          </>
        )}
      </div>
    </div >
  );
}

export default NotConfirmedDeliveries;
