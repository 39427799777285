import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Drivers
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/DieselDispense/GetDatumConfigDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

export const SaveAndUpdateDatumIdAcrossWarehouse = async (params = {}) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/DieselDispense/SaveAndUpdateDatumIdAcrossWarehouse`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

// export const deleteStorageRecordFuel = async (params = {}) => {
//   try {
//     const bodyParams = reqTransform.create(params);
//     const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/DeleteStorageRecordFuel`;
//     const res = await utils.fetch(url, {
//       method: 'PUT',
//       body: JSON.stringify(bodyParams),
//     }).ready;
//     const resData = await utils.processApiRes(res);
//     return resTransform.create(resData);
//   } catch (err) {
//     console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
//     const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
//     throw errors;
//   }
// };

export const deleteDatumIdAcrossWarehouse = async (params = {}) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/DieselDispense/SaveAndUpdateDatumIdAcrossWarehouse`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};
