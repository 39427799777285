import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import utils from 'lib/utils';

const FormikMaterialTextField = (props) => {
  // console.log('MaterialFormikTextField--->props', props)

  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    placeholder,
    type,
    showSpan,
    showsqurefoot,
    showkw,
    showMT,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  // if (field.name === 'formData.basicInfo.name_of_farm') {
  //   console.log('\n---------FormikMaterialTextField----------');
  //   console.log('field.name-->', field.name);
  //   console.log('field-->', field);
  // console.log('props span->', props);
  //   console.log('errors->', errors);
  //   console.log('touched->', touched);
  //   console.log('hasError-->', hasError);
  //   console.log('errText-->', errText);
  // }

  return (
    <div className={`mui-formik-text-field ${hasError ? 'has-error' : ''}`}>
      {!showSpan ? (
        <TextField
          fullWidth
          variant={variant || 'outlined'}
          size="small"
          disabled={disabled}
          error={hasError}
          helperText={errText}
          placeholder={placeholder}
          type={type}
          onWheel={(event) => {
            // event.preventDefault();
            event.target.blur();
          }}
          {...field}
        />
      ) : field.value ? (
        <span className="text">
          {typeof field.value === 'number' ? utils.formatQty(field.value) : field.value}{' '}
          {showsqurefoot ? 'Sq.ft' : null}
          {showkw ? 'KW' : null}
          {showMT ? 'MT' : null}
        </span>
      ) : (
        <span>---</span>
      )}
    </div>
  );
};

export default FormikMaterialTextField;
