import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { NavLink, useHistory, useRouteMatch, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as farmActions } from 'redux/farms';
import LogoLoader from 'common/LogoLoader';
import utils, {  decryptionInHex, getUrlWithEncryptedQueryParams } from 'lib/utils';
import FarmCropsForm from './FarmCropsForm';
import PopupForm from 'common/Form/PopupForm';
import useQuery from 'lib/hooks/useQuery';
import { Formik, Field } from 'formik';
import { Table, Menu, Dropdown, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { locationElocService } from 'services';
import AntTableActions from 'common/AntTableActions';
import {  Skeleton } from 'antd';
import MUIButton from 'common/MUIComponents/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LocationModalComponent from '../Organisations/components/locationModal';

const Farms = (props) => {
  //optimize this
  const user = useSelector((state) => state.user);
  const id = props.id;
  const { farmDetails, setFarmDetails, isFacilitator, setIsReRenderRequired, isReRenderRequired } = props;
  const { back } = useQuery();
  const userFarms = useSelector((state) => state.farms);
  const acl = utils.getACL(user);

  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(false)
  const [closePopup, setClosePopup] = useState(false)
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [addressLattitude, setAddressLattitude] = useState(null);
  const [addressLongitude, setAddressLongitude] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [locationCode, setLocationCode] = useState(null);

  const farms = _.get(userFarms, 'entities') || {};

  const busy = _.get(userFarms, 'busy') || false;

  let tableData = busy ? [] : isFacilitator ? farmDetails || [] : Object.values(farms || {});
  const dispatch = useDispatch();
  const history = useHistory();

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  tableData = isFacilitator ? farmDetails || [] : tableData.map((d) => {
    return {
      ...d,

      editPageLink: getUrlWithEncryptedQueryParams(`/account/${id}/farms/edit/${d['person_address_id']}`),
    };
  });

  const createNewArray = (data) => {
    let newArray = [];
    data?.map((dataItem, index) => {
      const crops = _.get(dataItem, 'crops') || [];
      const cropLength = crops.length;
      if (cropLength === 0) {
        let newObject = {
          ...dataItem,
          key: dataItem.farm_detail_id,
          id: dataItem.farm_detail_id,
        };
        newArray.push(newObject);
      }
      crops?.map((item, index) => {
        let rowSpan = 0;
        if (index === 0) {
          rowSpan = cropLength;
        } else {
          rowSpan = 0;
        }
        let newObject = {
          ...dataItem,
          ...item,
          key: item.farm_detail_id,
          id: item.farm_detail_id,
          rowSpan: rowSpan,
        };
        newArray.push(newObject);
      });
    });
    return newArray;
  };
  const handleDelete = async (id, isRecordSaved) => {
    try {
      if (isFacilitator && !isRecordSaved) {
        setFarmDetails(farmDetails?.filter(item => item.tempId !== id));
        setIsReRenderRequired(!isReRenderRequired)
      } else {
        let res = await handlers.handleDestroy(id);
        if (!_.isEmpty(res) && setIsReRenderRequired) {
          setFarmDetails(farmDetails?.filter(item => item.person_address_id !== id))
          setIsReRenderRequired(!isReRenderRequired)
        }
      }
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handleLocationClicked = (row) => {
    setAddressLattitude(row?.address_lattitude)
    setAddressLongitude(row?.address_longitude)
    setCanEdit(row?.can_edit)
    setIsEditorOpen(true)
  }
  const FARM_TABLE_HEADER = [
    {
      title: 'Farm',
      dataIndex: 'name_of_farm',
      width: '150px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.rowSpan = row.rowSpan;
        return obj;
      },
    },
    {
      title: 'Farm Size (acres)',
      dataIndex: 'size_of_farm',
      width: '100px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        obj.props.rowSpan = row.rowSpan;
        return obj;
      },
    },
    {
      title: 'Address',
      dataIndex: 'address_text',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: <a onClick={()=>handleLocationClicked(row)}  style={{
            wordBreak: 'break-word',
            maxWidth: '200px',
            display: 'inline-block',
            whiteSpace: 'normal'
          }}>{utils.getFarmAddress(row).addStr}</a>,
          // children: <a onClick={()=>handleLocationClicked(row)} style={{ wordBreak: 'break-word' }}>{locationCode ? locationCode : utils.getFarmAddress(row).addStr}</a>,
          // children: utils.getFarmAddress(row).addStr,
          props: {},
        };
        obj.props.rowSpan = row.rowSpan;
        return obj;
      },
    },
    {
      title: 'Circle',
      dataIndex: 'circle_code',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Crops',
      dataIndex: 'crops',
      align: 'center',
      width: '300px',
      children: [
        // {
        //   title: 'Farm Size',
        //   dataIndex: 'crop_area',
        //   align: 'center',
        //   width: '100px',
        // },
        {
          title: 'Crop',
          dataIndex: 'crop_code',
          align: 'center',
          width: '100px',
          render: (text, record) => (
            <>
              {record.crop_code || record.commodity_code || record?.number_of_trees ? <span>
                {record.crop_area} acres <br /> {record.crop_code || record.commodity_code} <br /> {record?.number_of_trees && record?.number_of_trees +` `+ `trees`}{' '}
              </span> : <span>-</span>}
            </>
          ),
        },
        {
          title: 'Harvest Date',
          dataIndex: 'harvest_date',
          align: 'center',
          width: '100px',
          render: (text) => (
            <>
              {text ? <span>{utils.formatDate(text)}</span> : <span>-</span>}
            </>),
        },
      ],
    },
    {
      title: 'Actions',
      dataIndex: 'address_text_',
      width: '100px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            <AntTableActions
              row={row}
              handleDelete={handleDelete}
              id={row.person_address_id || row?.tempId || 0}
              isRecordSaved={row.person_address_id ? true : false}
              name={row.name_of_farm}
              cancel={cancel}
              showFlag={!isAdmin}
              setIsOpen={setIsOpen}
              setSelectedRowData={setSelectedRowData}
              setClosePopup={setClosePopup}
              setIsEdit={setIsEdit}
            />
          ),
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
  ];

  let prevLink = `/account/${id}/myaccount`;
  let nextLink = `/account/${id}/transport`;
  if (isAdmin) {
    prevLink = `/account/${id}/myaccount?back=${back}`;
    nextLink = `/account/${id}/transport?back=${back}`;
  }

  useEffect(() => {
    if(!isFacilitator) {
      let params = {
        person_id: id,
      };

      dispatch(farmActions.fetchList(params));
    }
  }, [userFarms.dirty]);

  let farmId = null;
  if (!_.isEmpty(selectedRowData)) {
    farmId = _.get(selectedRowData, 'person_address_id') || 0;
  }

  const showFarmForm = isFacilitator ? true : _.isEmpty(farms) ? false : true;

  if (busy) {
    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }
  const followToggle = () => {
    setIsEditorOpen(!isEditorOpen);
  };
  return (
    <div className="bfc-body bfc-form profile-height-body" style={{marginBottom: isFacilitator && 0}}>
      <React.Fragment>
        <div className="form-container">
          <div className="farm-wrapper">
            <div className="farm-table">
              <div className="table-farms-wrapper">
                {true && (
                  <div className="addnew-row add-new-btn-box">
                    <MUIButton
                      // onClick={() => history.push(`/account/${id}/farms/create`)}
                      onClick={() => setIsOpen(true)}
                      // type="button"
                      // className="add-new-btn"
                      startIcon={<AddCircleIcon/>}
                      // size='large'
                    >
                      {showFarmForm && (
                        <>
                          {/* <span className="icon">+</span> */}
                          <span className="text">Add Farm</span>
                        </>
                      )}
                      {!showFarmForm && (
                        <>
                          <span className="text">Add Farm</span>
                        </>
                      )}
                    </MUIButton>
                  </div>
                )}

                <Table
                  // columns={FARM_TABLE_HEADER}
                  // dataSource={createNewArray(tableData)}
                  dataSource={!busy ? createNewArray(tableData) : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                  columns={busy ? FARM_TABLE_HEADER.map((column) => {
                    return {
                      ...column,
                      render: function renderPlaceholder() {
                        return (
                          <Skeleton
                            active
                            key={column.dataIndex}
                            title={true}
                            paragraph={false}
                          />
                        );
                      },
                    };
                  }) : FARM_TABLE_HEADER}
                  bordered
                  size="small"
                  pagination={false}
                  style={{ whiteSpace: 'pre' }}
                />
              </div>
            </div>

            {isFacilitator ? '' :<div className="btn-wrapper justify-content-space-between">
              <div>
                <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} color="link">
                  Prev
                </NavLink>
              </div>
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} color="link">
                  Next
                </NavLink>
              </div>
            </div>}
          </div>
        </div>
        {
  isEditorOpen && (
    <Formik
    initialValues={{
      address_lattitude: addressLattitude || 18.5313,
      address_longitude: addressLongitude || 73.8498,
    }}
      onSubmit={(values) => {
        console.log('Form values:', values);
      }}
    >
      {({ setFieldValue }) => (
        <Field
          name="latitude"
          component={LocationModalComponent}
          entity="RoadTypeEnum"
          isOpen={isEditorOpen}
          toggle={followToggle}
          isSubscribed={true}
          // isSubscribed={canEdit ? false : true}
          cb={async (locationValues) => {
            let locationDetails = locationValues || {};
            let params = {
              Latitude: locationDetails?.lat,
              Longitude: locationDetails?.lng,
            };
            const resData = await locationElocService.getReverseLocationDetails(params);
            setFieldValue('pin_code', _.get(resData?.results?.[0], 'pincode') || '');
            setFieldValue('city_code', _.get(resData?.results?.[0], 'city') || '');
            let addressLine1 =
            (_.get(resData?.results?.[0], 'houseNumber', '')
              ? _.get(resData?.results?.[0], 'houseNumber', '') + ', '
              : '') +
            (_.get(resData?.results?.[0], 'houseName', '')
              ? _.get(resData?.results?.[0], 'houseName', '')
              : '');

          let addressLine2 =
            (_.get(resData?.results?.[0], 'street', '')
              ? _.get(resData?.results?.[0], 'street', '') + ', '
              : '') +
            (_.get(resData?.results?.[0], 'sub_sub_locality', '')
              ? _.get(resData?.results?.[0], 'sub_sub_locality', '')
              : '');

          let addressLine3 =
            (_.get(resData?.results?.[0], 'sub_locality', '')
              ? _.get(resData?.results?.[0], 'sub_locality', '') + ', '
              : '') +
            (_.get(resData?.results?.[0], 'locality', '')
              ? _.get(resData?.results?.[0], 'locality', '')
              : '');

          setFieldValue('address_line1', addressLine1);
          setFieldValue('address_line2', addressLine2);
          setFieldValue('address_line3', addressLine3);
            setFieldValue(
              'district_code',
              _.get(resData?.results?.[0], 'district') || '',
            );
            setFieldValue(
              'village_code',
              _.get(resData?.results?.[0], 'village') ||
                _.get(resData?.results?.[0], 'city') ||
                _.get(resData?.results?.[0], 'subDistrict') ||
                '',
            );
            setFieldValue('state_code', _.get(resData?.results?.[0], 'state') || '');
            setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');
            setFieldValue('address_lattitude', `${locationDetails?.lat}`);
            setFieldValue('address_longitude', `${locationDetails?.lng}`);
            setFieldValue(
              'location_code',
              _.get(locationDetails, 'formatted_address', ''),
            );
            setLocationCode(_.get(locationDetails, 'formatted_address', ''))
            setFieldValue('location_code', _.get(locationDetails, 'formatted_address', ''));
          }}
        />
      )}
    </Formik>
  )
}
        {isOpen ? (
          <div>
            <PopupForm
              title={'Farm'}
              // onClose={() => history.push(`/account/${id}/farms?back=${back}`)}
              onClose={() => {
                setIsOpen(false)
                setClosePopup(true)
                setIsEdit(false)
              }}
            >
              <FarmCropsForm farmId={farmId} id={id} setFarmDetails={setFarmDetails} farmDetails={closePopup ? [] : farmDetails} isFacilitator={isFacilitator} setIsOpen={setIsOpen} isEdit={isEdit} setIsEdit={setIsEdit} setClosePopup={setClosePopup} closePopup={closePopup} selectedRowData={selectedRowData} />
            </PopupForm>
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
};

export default Farms;
