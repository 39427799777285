import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';
import store from 'redux/store';
import history from 'lib/history';
import { dealInvoicesService } from 'services';

//Update Invoice
export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating invoice ...',
      key: 'req-form',
    });
    const id = _.get(values, 'invoice_master_id') || 0;
    const res = await dealInvoicesService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Generate Invoice PDF to view changes',
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleCreateRecordPayment = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Payment Details ...',
      key: 'req-form',
    });
    // const id = _.get(values, 'invoice_master_id') || 0;
    const res = await dealInvoicesService.createRecordPayment(values);
    notifications.show({
      type: 'success',
      message: 'Payment Details saved.',
      key: 'req-form',
    });
    let paymentId = _.get(res, 'bf_payment_master_response.payment_master_id') || 0;
    if (paymentId !== 0) {
      const backLink = utils.encodeCustomUrl('/market/payments');
      history.push(getUrlWithEncryptedQueryParams(`/payment/${paymentId}/overview?back=${backLink}`));
    }
    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
    throw err;
  }
};
export const handleCreateRecordPaymentFinance = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Payment Details ...',
      key: 'req-form',
    });
    // const id = _.get(values, 'invoice_master_id') || 0;
    const res = await dealInvoicesService.createRecordPaymentFinance(values);
    notifications.show({
      type: 'success',
      message: 'Payment Details saved.',
      key: 'req-form',
    });
    let paymentId = _.get(res, 'bf_payment_master_response.payment_master_id') || 0;
    if (paymentId !== 0) {
      const backLink = utils.encodeCustomUrl('/market/payments');
      history.push(getUrlWithEncryptedQueryParams(`/payment/${paymentId}/overview?back=${backLink}`));
    }
    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
    throw err;
  }
};

export const handleCreateCRorDRNote = async (values, formikBag, backLink) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating CR/DR Note',
      key: 'req-form',
    });
    const values = formikBag.values;

    const res = await dealInvoicesService.createCRorDrNote(values);
    notifications.show({
      type: 'success',
      message: 'CR/DR note created',
      key: 'req-form',
    });
    const encodeBack = utils.encodeCustomUrl(backLink);
    history.push(getUrlWithEncryptedQueryParams(`/invoices/${res}?back=${encodeBack}`));

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleCreateESign = async (values) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Redirecting...',
      key: 'req-form',
    });

    const res = await dealInvoicesService.createESign(values);
    // notifications.show({
    //   type: 'success',
    //   message: 'CR/DR note created',
    //   key: 'req-form',
    // });

    return res;
  } catch (err) {
    console.log('createESign--->error---->', err);
    const errors = _.get(err, 'errors') || {};
    const personId = _.get(errors, 'person_id') || 'Something went wrong.';
    notifications.show({
      type: 'error',
      message: `${personId}`,
      key: 'req-form',
    });
  }
};

export const fetchEInvoiceQrCode = async (values) => {
  let toastId;
  try {
    toastId = notifications.show({
      type: 'info',
      message: 'Fetching e-Invoice details',
      key: 'req-form',
    });
    const res = await dealInvoicesService.fetchEInvoiceQrCode(values);
    notifications.update({
      type: 'success',
      message: `IRN details & QR code successfully fetched. Regenerating invoice.`,
      key: 'req-form',
    });
    return res;
  } catch (err) {
    const errors = _.get(err, 'errors') || {};
    notifications.update({
      type: 'error',
      message: `${errors?.invoice}`,
      key: 'req-form',
    });
  }
};

export const handleSignPdfUsingDigitalSignature = async (values) => {
  let toastId;
  try {
    toastId = notifications.show({
      type: 'info',
      message: 'Digitally Signing PDF',
      key: 'req-form',
    });
    const res = await dealInvoicesService.handleSignPdfUsingDigitalSignature(values);
    notifications.update({
      type: 'success',
      message: `PDF Signed`,
      key: 'req-form',
    });
    return res;
  } catch (err) {
    const errors = _.get(err, 'errors') || {};
    notifications.update({
      type: 'error',
      message: `${errors?.invoice}`,
      key: 'req-form',
    });
  }
};

export const UpdateConsignmentDetails = async (values) => {
  try {
    const res = await dealInvoicesService.UpdateConsignmentDetails(values);

    return res;
  } catch (err) {
    const errors = _.get(err, 'errors') || {};
    notifications.update({
      type: 'error',
      message: `${errors?.invoice}`,
      key: 'req-form',
    });
  }
};

export const fetchRelatedInvoices = async (values) => {
  try {
    const res = await dealInvoicesService.fetchRelatedInvoices(values);

    return res;
  } catch (err) {
    const errors = _.get(err, 'errors') || {};
    notifications.update({
      type: 'error',
      message: `${errors?.invoice}`,
      key: 'req-form',
    });
  }
};
