import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import utils from 'lib/utils';

export const invoiceSchema = yup.object().shape({
  payment_made_date: yup.string().required('payment Date is required'),
  // ref_utr_number: yup.string().required('Payment Ref./ UTR No is required'),
  // from_bank_id: yup.number().positive('Please Select Bank').required('Please Select Bank'),
  bank_entity_id: yup.number().positive('Please Select Bank Account').required('Please Select Bank Account'),
  // paid_to_bank: yup
  //   .number()
  //   .positive('Last 4 Digits of "Paid to Bank" Account is required')
  //   .required('Last 4 Digits of "Paid to Bank" Account is required'),
  amount_to_pay: yup
    .number()
    .required('Please add amount to pay')
    .test('amount_to_pay', `Invalid Amount!`, function (item) {
      let val =
        parseFloat(this.parent.amount_to_pay) >= parseFloat(this.parent.summary_amount_to_pay) &&
        parseFloat(this.parent.amount_to_pay) <= parseFloat(this.parent.payable);
      return val;
    }),
});

const validationSchemaforPopup = yup.object().shape({
  // invoice_Verification_Enum: Yup.number().required('Field is required'),
  amount_To_Pay: yup
    .number()
    .required('Amount is required')
    .positive('Amount should be positive')
    .moreThan(0, 'Amount should be more than 0'),

  pay_On_Date: yup.date().required('pay on date is required'),
});

export const CRDRSchema = yup.object().shape({
  noteType: yup.string().required('Type of note is required'),
});

export const invoiceUpdate = yup.object().shape({
  custom_invoice_number: yup
    .string()
    .required('Invoice Number is required')
    .test('len', 'Maximum length is 16 characters', (val) => val.length <= 16),
});

export const createAdvanceSchema = yup.object().shape({
  paid_by_person_company_affiliate_id: yup.number().required('Payer is required'),
  paid_by_person_company_affiliate_enum: yup.number().required('Payer is required'),
  paid_to_person_company_affiliate_id: yup.number().required('Paid to is required'),
  paid_to_person_company_affiliate_enum: yup.number().required('Paid to is required'),
  purposes: yup.array().of(
    yup.object().shape({
      advance_purpose_enum: yup.number().required('Purpose is required'),
      advance_purpose_entity_id: yup.number().required('Purpose is required'),
    }),
  ),
  amount: yup.number().required('Amount is required'),
  payment_due_date: yup.date().required('Pay on Date is required'),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListAgainstPersonId = async (params = {}) => {
  try {
    let res = await api.fetchListAgainstPersonId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListAgainstPersonIdReport = async (params = {}) => {
  try {
    let res = await api.fetchListAgainstPersonIdReport(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchInvoiceDetails = async (params = {}) => {
  try {
    let res = await api.fetchInvoiceDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(invoiceUpdate, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updatePDF = async (params) => {
  try {
    const res = await api.updatePDF(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchInvoicePDF = async (pdfLink) => {
  try {
    let res = await api.fetchInvoicePDF(pdfLink);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchConsigmentPDF = async (pdfLink) => {
  try {
    let res = await api.fetchConsigmentPDF(pdfLink);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createRecordPayment = async (params) => {
  try {
    await helpers.validateSchema(invoiceSchema, params);
    const res = await api.createRecordPayment(params);
    return res;
  } catch (err) {
    const allErrors = _.get(err, 'errors') || '';
    const amountToPay = _.get(allErrors, 'amount_to_pay') || '';
    let error = {
      ...err,
    };
    if (amountToPay == `Invalid Amount!`) {
      let errors = _.get(error, 'errors') || {};
      errors = {
        ...errors,
        amount_to_pay: `Invalid Amount. Minimum Amount to pay: ${params['summary_amount_to_pay']}`,
      };
      error = {
        ...err,
        errors: errors,
      };
    }

    throw error;
  }
};
export const createRecordPaymentFinance = async (params) => {
  try {
    await helpers.validateSchema(invoiceSchema, params);
    const res = await api.createRecordPaymentFinance(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCustomerList = async (params) => {
  try {
    let res = await api.fetchCustomerList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPayers = async (params) => {
  try {
    let res = await api.getPayers(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createCRorDrNote = async (params) => {
  try {
    // await helpers.validateSchema(CRDRSchema, params);
    let res = await api.createCRorDRNote(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createESign = async (params) => {
  try {
    let res = await api.createESign(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchSellerBankList = async (params) => {
  try {
    let res = await api.fetchSellerBankList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDealBalancePosition = async (params) => {
  try {
    let res = await api.fetchDealBalancePosition(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateInvoiceActionStatus = async (params) => {
  try {
    let res = await api.updateInvoiceActionStatus(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getInvoiceVerificationStatusHistory = async (params) => {
  try {
    let res = await api.getInvoiceVerificationStatusHistory(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const SaveUpdateInvoiceVerificationStatus_Bulk = async (params) => {
  try {
    let res = await api.SaveUpdateInvoiceVerificationStatus_Bulk(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdateInvoiceVerificationStatus = async (params) => {
  try {
    await utils.validateSchema(validationSchemaforPopup, params);
    let res = await api.saveUpdateInvoiceVerificationStatus(params);
    return res;
  } catch (err) {
    console.log('VALIDATION ERR:', err);
    throw err;
  }
};

export const fetchEInvoiceQrCode = async (params) => {
  try {
    let res = await api.fetchEInvoiceQrCode(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const handleSignPdfUsingDigitalSignature = async (params) => {
  try {
    let res = await api.handleSignPdfUsingDigitalSignature(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateConsignmentDetails = async (params) => {
  try {
    let res = await api.UpdateConsignmentDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const downloadInvoices = async (params) => {
  try {
    let res = await api.downloadInvoices(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchRelatedInvoices = async (params) => {
  try {
    let res = await api.fetchRelatedInvoices(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createAdvance = async (params) => {
  try {
    await utils.validateSchema(createAdvanceSchema, params);
    let res = await api.createAdvance(params);
    return res;
  } catch (err) {
    throw err;
  }
};
